
<template>
  <div style="text-align: left;">
    <div class="pubfileheader-container" style="float: left; width: 620px;">
      <div style="margin-top:10px;">
        <h4 style="display: inline-block;">&nbsp;&nbsp;表单列表</h4>
        <button style="float: right; height:30px; width: 60px;margin-right: 5px" @click="showPubFileMenu('all')">发起+</button>
        <button style="float: right; height:30px; width: 60px;margin-right: 10px; position:relative;" @click="showPubFileApproving" >
          待审批
          <label for="" class="badget" :style="{backgroundColor: showCheckingNum() > 0 ? 'rgb(64, 158, 255)' : ''}">{{ showCheckingNum() }}</label>
        </button>
        <button style="float: right; height:30px; width: 60px;margin-right: 10px" @click="showPubFileApproved">已审批</button>
        <button style="float: right; height:30px; width: 60px;margin-right: 10px" @click="showPubFileOnly">已发布</button>
        <button style="float: right; height:30px; width: 60px;margin-right: 10px" @click="showAllPubFile">全部</button>
        <!-- 弹出菜单的代码 -->
        <PubFileMenu  v-if="isShowPubFileMenu" @closeFilePubMenu="closeFilePubMenu" @pubNewFile="pubNewFile" :myProjectlist="myProjectlist"
          :allMyPubFileFolders="allMyPubFileFolders" :selectedParentFolder="selectedParentFolder">
        </PubFileMenu>
        <!-- 弹出菜单的代码 -->
      </div>
      <div>
        <table style="margin-left: 10px; margin-right: 10px;">
          <tr class="tableheader">
            <td style="width:25px;"></td>
            <td style="width: 330px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">表单名称</td>
            <td style="min-width: 120px;">发起时间</td>
            <td style="width:50px;">状态</td>
            <td colspan="2">操作</td>
          </tr>
        </table>
      </div>
      <div style="height: calc(100vh - 200px;); overflow: auto;">
        <ApprFileMenu v-if="ifShowApprFileMenu" :approvingFile="approvingFile" :pubProjectParters="pubProjectParters"
          @closeFileAprovelMenu="closeFileAprovelMenu" @handleFileProcessMsg="handleFileProcessMsg" 
          @approveLocalPubfileByIdff="approveLocalPubfileByIdff">

        </ApprFileMenu>
        <PubFileToUsers v-if="ifShowPubFileToUsers" :approvingFile="approvingFile" :pubProjectParters="pubProjectParters"
          @closePubFileToUsers="closePubFileToUsers">

        </PubFileToUsers>
        <div v-if="(viewOption=='approved')||(viewOption=='checking')||(viewOption=='published')">

        </div>
        <div>
          <div v-for="(file1,index) in myPubFiles1()" :key="file1.idff" >
            <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
              <tr>
                <td style="width:25px;">
                   <i class="el-icon-folder-opened" v-if="file1.isfolder&&(file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                   <i class="el-icon-folder" v-if="file1.isfolder&&(!file1.file_expanded)" @click="changeFileExpanded(file1)"></i>
                   <i class="el-icon-document"  v-if="!file1.isfolder"></i>
                </td>
                <td @click="gotoFileDetail(file1,index)"  style="width: 330px;" class="filenametitle"
                  :class="{ pubfileclicked: selectedPubfileIdff==file1.idff }">
                  <div style="display:inline-block;width:330px;">
                    {{ file1.filename }}
                  </div>
                </td>
                <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file1.pubtime) }}</td> 
                <td  class="filestatusstyle">
                  <label for="" v-if="!file1.isfolder">{{ file1.filestatus }}</label>
                </td>
                <td style="width:20px;">
                  <button style="width: 20px; background: none; border: none;padding: 0;" 
                    v-if="file1.filestatus=='已发布'&&!file1.isfolder">
                    <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                  </button>
                  <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file1.isfolder" @click="showPubFileMenu(file1.idff,file1)">
                    <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                    </i>
                  </button>
                  <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file1)"  
                    @click="approvelFile(file1)">
                    <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                  </button>
                </td>
                <td>
                  <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" @click.stop="downloadPubfile(file1)" >
                    <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                  </button>
                </td>
              </tr>          
            </table>
            <div v-for="(file2,index) in myPubFilesOfLevelN(2,file1)" :key="file2.idff"  v-show="file1.file_expanded">
              <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable" v-if="file1.file_expanded">
                <tr>
                  <!-- <td style="width:50px;">{{ index+1 }}</td> -->
                  <td style="width:25px;position:relative;">
                    <label for="" style="position:absolute;left:6px;top:-5px;">
                      <i class="el-icon-folder-opened" v-if="file2.isfolder&&file2.file_expanded" @click="changeFileExpanded(file2)"></i>
                      <i class="el-icon-folder" v-if="file2.isfolder&&!file2.file_expanded" @click="changeFileExpanded(file2)"></i>
                      <i class="el-icon-document"  v-if="!file2.isfolder"></i>
                    </label>
                  </td>
                  <td @click="gotoFileDetail(file2,index)"  style="width: 330px;" class="filenametitle"
                    :class="{ pubfileclicked: selectedPubfileIdff==file2.idff }">
                    <!-- <label for="" @click="changeFileExpanded(file1)">
                      <i class="el-icon-folder-opened" v-if="file2.isfolder&&file1.file_expanded"></i>
                    <i class="el-icon-folder" v-if="file2.isfolder&&!file2.file_expanded"></i>
                    <i class="el-icon-document"  v-if="!file2.isfolder"></i>
                    </label> -->
                    <div style="padding-left:6px;">
                      {{ file2.filename }}
                    </div>
                  </td>
                  <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file2.pubtime) }}</td> 
                  <td  class="filestatusstyle">
                    <label for="" v-if="!file2.isfolder">{{ file2.filestatus }}</label>
                  </td>
                  <td style="width:20px;">
                    <button style="width: 20px; background: none; border: none;padding: 0;" 
                      v-if="file2.filestatus=='已发布'&&!file2.isfolder">
                      <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                    </button>
                    <button style="width: 20px; background: none; border: none;padding: 0;" 
                      v-if="file2.isfolder" @click="showPubFileMenu(file2.idff,file2)">
                      <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                      </i>
                    </button>
                    <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file2)"  
                      @click="approvelFile(file2)">
                      <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                    </button>
                  </td>
                  <td>
                    <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" @click.stop="downloadPubfile(file2)" >
                      <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                    </button>
                  </td>
                </tr>          
              </table>
              <div v-for="(file3,index) in myPubFilesOfLevelN(3,file2)" :key="file3.idff"  v-show="file2.file_expanded">
                <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
                  <tr>
                    <!-- <td style="width:50px;">{{ index+1 }}</td> -->
                    <td style="width:25px;position:relative;">
                      <label for="" style="position:absolute;left:12px;top:-5px;">
                        <i class="el-icon-folder-opened" v-if="file3.isfolder&&file3.file_expanded" @click="changeFileExpanded(file3)"></i>
                        <i class="el-icon-folder" v-if="file3.isfolder&&!file3.file_expanded" @click="changeFileExpanded(file3)"></i>
                        <i class="el-icon-document"  v-if="!file3.isfolder"></i>
                      </label>
                    </td>
                    <td @click="gotoFileDetail(file3,index)"  style="width: 330px;" class="filenametitle"
                      :class="{ pubfileclicked: selectedPubfileIdff==file3.idff }">
                      <!-- <label for="" @click="changeFileExpanded(file1)">
                        <i class="el-icon-folder-opened" v-if="file2.isfolder&&file1.file_expanded"></i>
                      <i class="el-icon-folder" v-if="file2.isfolder&&!file2.file_expanded"></i>
                      <i class="el-icon-document"  v-if="!file2.isfolder"></i>
                      </label> -->
                      <div style="padding-left:12px;">
                        {{ file3.filename }}
                      </div>
                    </td>
                    <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file3.pubtime) }}</td> 
                    <td  class="filestatusstyle">
                      <label for="" v-if="!file3.isfolder">{{ file3.filestatus }}</label>
                    </td>
                    <td style="width:20px;">
                      <button style="width: 20px; background: none; border: none;padding: 0;" 
                        v-if="file3.filestatus=='已发布'&&!file3.isfolder">
                        <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file3.isfolder"
                         @click="showPubFileMenu(file3.idff,file3)">
                        <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                        </i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file3)"  
                        @click="approvelFile(file3)">
                        <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    <td>
                      <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;" @click.stop="downloadPubfile(file3)" >
                        <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                  </tr>          
                </table>
                <div v-for="(file4,index) in myPubFilesOfLevelN(4,file3)" :key="file4.idff"  v-show="file3.file_expanded">
                  <table style="margin-left: 10px;margin-right: 10px; font-size:14px;" class="filecontenttable">
                    <tr>
                    <!-- <td style="width:50px;">{{ index+1 }}</td> -->
                    <td style="width:25px;position:relative;">
                      <label for="" style="position:absolute;left:12px;top:-5px;">
                        <i class="el-icon-folder-opened" v-if="file4.isfolder&&file4.file_expanded" @click="changeFileExpanded(file4)"></i>
                        <i class="el-icon-folder" v-if="file3.isfolder&&!file4.file_expanded" @click="changeFileExpanded(file4)"></i>
                        <i class="el-icon-document"  v-if="!file4.isfolder"></i>
                      </label>  
                    </td>
                    <td @click="gotoFileDetail(file4,index)"  style="width: 330px;" class="filenametitle"
                      :class="{ pubfileclicked: selectedPubfileIdff==file4.idff }">
                    
                      <!-- <label for="" @click="changeFileExpanded(file1)">
                        <i class="el-icon-folder-opened" v-if="file2.isfolder&&file1.file_expanded"></i>
                      <i class="el-icon-folder" v-if="file2.isfolder&&!file2.file_expanded"></i>
                      <i class="el-icon-document"  v-if="!file2.isfolder"></i>
                      </label> -->
                      <div style="padding-left:12px;">
                        {{ file4.filename }}
                      </div>
                    </td>
                    <td style="min-width: 120px;font-size: 13px;">{{ datetimeConvert(file4.pubtime) }}</td> 
                    <td  class="filestatusstyle">
                      <label for="" v-if="!file4.isfolder">{{ file4.filestatus }}</label>
                    </td>
                    <td style="width:20px;">
                      <button style="width: 20px; background: none; border: none;padding: 0;" 
                        v-if="file4.filestatus=='已发布'&&!file4.isfolder">
                        <i class="el-icon-finished" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);"></i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="file4.isfolder"
                         @click="showPubFileMenu(file4.idff,file4)">
                        <i class="el-icon-circle-plus-outline" style="width: 15px;height: 15px;margin: 0; padding: 0; color:rgb(111, 122, 211);">
                        </i>
                      </button>
                      <button style="width: 20px; background: none; border: none;padding: 0;" v-if="ifshowApproveButton(file4)"  
                        @click="approvelFile(file4)">
                        <i class="el-icon-video-play" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    <td>
                      <button style="width: 20px; margin-left:10px; background: none; border: none;padding: 0;"
                        @click.stop="downloadPubfile(file4)" >
                        <i class="el-icon-download" style="width: 15px;height: 15px;margin: 0; padding: 0; color:blue;"></i>
                      </button>
                    </td>
                    </tr>          
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pubfiledetail-container" style="width: calc(100% - 630px);">
      <router-view :selectedFile="selectedFile" :fileProcessMsgs="fileProcessMsgs"  :fileChatMsgs="fileChatMsgs"
        :selectedProjectAcls="selectedProjectAcls" @sendFileMessage="sendFileMessage">
      </router-view>
    </div>
  </div>
</template>

<script>
import PubFileMenu from '../menu/file/PubFileMenu.vue'
import ApprFileMenu from '../menu/file/ApprFileMenu.vue'
import PubFileToUsers from '../menu/file/PubFileToUsers.vue'
import { mapState , mapMutations,mapActions} from 'vuex'
import  axios from 'axios'
import io from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'FormPublish',
  components: {
    PubFileMenu,
    ApprFileMenu,
    PubFileToUsers
  },
  props: ['myProjectlist1'],
  data() {
    return {
      viewOption:'all',
      publisher: '',
      fileDetails: '',
      pubfiles: [],
      myPubFiles:[],
      allMyPubFiles:[],
      allMyPubFileFolders:[],
      filename: '',
      filetype: '',
      filesize: '',
      filedownloadtimes:0,
      filesummary:'',
      filepublisher:'',
      filepubtime: '',
      fileProject: '',
      fileFolder: '',
      checkingNum:1,
      pubfile:{idff:'', project:'',fold:'',filename:'',publisher:'王洪文的',approver:'',pubtime:'', size: 0, downloadtimes:0,type:'',
               summary:'',downloadtimes:0, },
      isShowPubFileMenu: false,

      //与选中的文件相关的数据
      selectedFile:null,
      selectedParentFolder:null,
      selectedProjectIdfp:'',

      ifShowApprFileMenu:false,
      ifShowPubFileToUsers:false,
      approvingFile:null,
      pubProjectParters:[],
      myProjectlist:[],

      //有关文件审批流程的信息记录
      fileProcessMsgs:[],
      selectedPubfileIndex:[-1,-1,-1,-1,-1,-1],
      selectedPubfileIdff:'',


      //有关文件聊天记录的数据
      fileChatMsgs:[],
      selectedProjectAcls:[],
      showIcon:true,

      //测试一组文件夹数组
      testArray:[
        {idff:1,name:'enen1',parent:'rootfile'},
        {idff:2,name:'enen2',parent:9},
        {idff:3,name:'enen3',parent:1},
        {idff:4,name:'enen4',parent:3},
        {idff:5,name:'enen5',parent:'rootfile'},
        {idff:6,name:'enen6',parent:4},
        {idff:7,name:'enen7',parent:1},
        {idff:8,name:'enen8',parent:6},
        {idff:9,name:'enen9',parent:'rootfile'},
      ],

    }
  },
  computed:{
    ...mapState({
      mySqlProjects: 'mySqlProjects',
    }),

    ifshowApproveButton(){
      return(file)=>{
        const username=localStorage.getItem('username')
        const checkers=file.checker.split(';')
        const checkeder=file.checked.split(';')

        const approver=file.approver
        const approveder=file.approved
        if(checkers.includes(username)&&(!checkeder.includes(username))){
          return true
        } else if(approver==username){
          const allCheckersInCheckeder = checkers.every(checker => checkeder.includes(checker));
          if(allCheckersInCheckeder&&file.approver!==file.approved){
            return true
          } else {
            return false
          }
        } else if(file.filestatus=='已审批'&&file.publisher==username){
          return true
        }
        else {
          return false
        }
        // if(file.filestatus=='已发布'||approvers.includes(username)||file.approved==username){
        //   return false
        // } else {
        //   return true
        // }
      }
    },


    myPubFiles1(){
      return()=>{
        var myPubFiles1=this.myPubFiles.filter(file=>file.file_level==1)
        myPubFiles1=myPubFiles1.sort((a, b)=>new Date(b.pubtime) - new Date(a.pubtime))

        return myPubFiles1
      }
    },

    myPubFilesOfLevelN(){
      return (n,file)=>{
        var filesData=this.myPubFiles.filter(item=>item.file_level==n&&item.file_parent==file.idff)
        filesData=filesData.sort((a, b)=>new Date(b.pubtime) - new Date(a.pubtime))
        return filesData
      }
    },







    datetimeConvert(){
      return(datetime)=> {
        const date = new Date(datetime);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2,'0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours() + 8).padStart(2, '0'); // 加上8小时，转换为中国标准时间
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;
        return formattedTime
      }
    },

    showCheckingNum(){
      return()=>{
        var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)
        var files=this.allMyPubFiles.filter(file=>(file.filestatus=='待审批'||file.filestatus=='待审核')&&file.isfolder==0)
        const username=localStorage.getItem('username')
        var myApprovingFiles=files.filter((file)=>{
          var checkers=file.checker.split(';')
          var checkeder=file.checked.split(';')
          console.log('checkeder',checkeder)
          var approver=file.approver
          var approved=file.approved
          if(checkers.includes(username)&&!checkeder.includes(username)){
            return file
          } else if(approver==username&&approved==''){
            const allCheckersInCheckeder = checkers.every(checker => checkeder.includes(checker));
            if(allCheckersInCheckeder){
              return file
            }
          } else {
          }
        })

        const num=myApprovingFiles.length

        // var checkingNum=0
        // var checkinger=[]
        // var checkeder=[]
        
        // const username=localStorage.getItem('username')
        // const myPubFiles=this.allMyPubFiles.filter(file=>file.isfolder==0)
        // myPubFiles.forEach((file)=>{
        //   const approver=file.approver
        //   const approveder=file.approved
        //   if(!(file.checker=='')){
        //     checkinger=file.checker.split(';')
        //   }
        //   // if(!(file.approver=='')){
        //   //   checkinger.push(file.approver)
        //   // }
        //   if(!(file.checked=='')){
        //     checkeder=file.checked.split(';')
        //   }
        //   // if(!(file.approved=='')){
        //   //   checkeder.push(file.approved)
        //   // }
        //   if(checkinger.includes(username)&&!(checkeder.includes(username))) {
        //     checkingNum++
        //   } else if(approver==username){
        //     const allCheckersInCheckeder=checkinger.every(checker=>checkeder.includes(checker))
        //     if(allCheckersInCheckeder){
        //       checkingNum++
        //     }
        //   } else {
            
        //   }
        // })
        if(num>99){
          return '99+'
        } else if(num>0) {
          return num
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
      updateMySqlProjects:'updateMySqlProjects',
    }),

    ...mapActions({
      downloadFileFromServer:'downloadFileFromServer',
      getFileinfoByIdff:'getFileinfoByIdff',
      addFileUserAclSql: 'addFileUserAclSql',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
      showPic:'showPic',
      getPubfilesByProject:'getPubfilesByProject',
      getMyProjectAclList:'getMyProjectAclList',
      getMyProjectListByIdfp:'getMyProjectListByIdfp',
      addChatmsg:'addChatmsg',
      // getChatmsgsByReceiverAndAboutwhat:'getChatmsgsByReceiverAndAboutwhat',
    }),    

    showCheckingNum1(){
  
        var checkingNum=0
        var checkinger=[]
        var checkeder=[]
        const username=localStorage.getItem('username')
        const myPubFiles=this.allMyPubFiles.filter(file=>file.isfolder==0)
        myPubFiles.forEach((file)=>{
          if(this.ifshowApproveButton(file)){
            checkingNum++
          }
        })
        console.log('checkingNum',checkingNum)

        myPubFiles.forEach((file)=>{
          if(!(file.checker=='')){
            checkinger=file.checker.split(';')
          }
          if(!(file.approver=='')){
            checkinger.push(file.approver)
          }
          if(!(file.checked=='')){
            checkeder=file.checked.split(';')
          }
          if(!(file.approved=='')){
            checkeder.push(file.approved)
          }
          // console.log('xx',checkinger,checkeder,username)
          if(checkinger.includes(username)&&!(checkeder.includes(username))) {
            checkingNum++
          }
        })
        if(checkingNum>99){
          // console.log('checkingNum',checkingNum)
          return '99+'
        } else if(checkingNum>0) {
          // console.log('checkingNum',checkingNum)
          return checkingNum
        } else {
          return ''
        }
    
    },


    approveLocalPubfileByIdff(newFileMsg,newPubFile){
      this.allMyPubFiles.find((file)=>{
        if(file.idff==newPubFile.idff){
          file=newPubFile
        }
      })
      this.myPubFiles.find((file)=>{
        if(file.idff==newPubFile.idff){
          file=newPubFile
        }
      })
      this.fileProcessMsgs.unshift(newFileMsg)
      this.ifShowApprFileMenu=false
    },


    changeFileExpanded(file){
      file.file_expanded=file.file_expanded?0:1
    },


    getFoldersOfFile(file,folderArray){
      const filefolders=folderArray.filter(item=>item.idff==file.file_parent)
      if(filefolders.length>0){

        if(!this.myPubFiles.includes(filefolders[0])){
          this.myPubFiles.push(filefolders[0])
        }
        
        this.getFoldersOfFile(filefolders[0],folderArray)
      }
    },

    startAnimation() {
      setInterval(() => {
        this.showIcon = !this.showIcon;
      }, 800); // 切换图标的显示状态，实现时隐时现的效果
    },

    downloadPubfile(file){
      const urlObject = new URL(file.url);
      const fileName = decodeURIComponent(urlObject.pathname.split('/').pop());
      this.downloadPubfileFromServer(fileName,'downloadpubfile')
    },
    approvelFile(file){
      const username=localStorage.getItem('username')
      if((file.filestatus==='待审批'||file.filestatus==='待审核')){
        this.approvingFile=file
        this.getProjectAclListByIdfp(file.project)
          .then((result)=>{
            this.pubProjectParters=result
          })

        this.ifShowApprFileMenu=true
        this.switchMenuMask()
      }else if(file.filestatus==='已审批'&&file.publisher==username) {
        this.approvingFile=file
        this.getProjectAclListByIdfp(file.project)
          .then((result)=>{
            this.pubProjectParters=result
          })
        this.ifShowPubFileToUsers=true
        this.switchMenuMask()
      } else {
        alert('文件由发起人发布！')
      }

    },

    handleFileProcessMsg(fileMsg){
      this.fileProcessMsgs.push(fileMsg)
    },

    closePubFileToUsers(){
      this.ifShowPubFileToUsers=!this.ifShowPubFileToUsers
    },

    //写一个把所有平行任务改变成children数据格式
    findChildrenArrayOfFile(item, targetArray) {
      
      var itemChildren = targetArray.filter(child => child.file_parent === item.idff);  //找到所有的子文件
      if (itemChildren.length > 0) {
        // 递归调用findChildrenArrayOfFile，传递剩余的部分数组
        itemChildren.forEach((child) => {
          this.findChildrenArrayOfFile(child, targetArray);
        });
        item.children = itemChildren;
      } else {
        item.children = [];
      }
    },



    //写一个把所有平行任务改变成children数据格式
    findChildrenArrayOfFile1(targetArray) {
      var result = [];
      targetArray.forEach(item => {
        if (item.file_parent === 'rootfile') {
          this.findChildrenArrayOfFile(item, targetArray);
          console.log('item',item)
          result.push(item);
        }
      });
      return result;
      // if(targetArray.length>0){
      //   var n=targetArray.length
      //   for(var i=0; i<n;i++){
      //     this.findChildrenArrayOfFile[i]
      //   }

      //   const lefttargetArray=targetArray.filter(item=>item.parent=='rootfile')
      //   return lefttargetArray
      // }
    },

    gotoFileDetail(file,index){
      console.log('form')
      this.selectedPubfileIdff=file.idff
      this.fileProcessMsgs=[]
      this.fileChatMsgs=[]
      this.selectedPubfileIndex=[-1,-1,-1,-1,-1,-1]
      this.selectedPubfileIndex[file.file_level-1]=index
      // this.getProjectPartersFromTask(task)
      this.getChatmsgsByReceiverAndAboutwhat(file.idff,'fileprocess')
        .then((result)=>{
          this.fileProcessMsgs=result
          console.log('this.fileProcessMsgs',this.fileProcessMsgs)
        })
      this.getChatmsgsByReceiverAndAboutwhat(file.idff,'filechat')
        .then((result)=>{
          this.fileChatMsgs=(result==undefined)?[]:result
        })
      
      this.getProjectPartersFromTask(file.project)
        .then((result)=>{
          this.selectedProjectAcls=result
          
        })
      this.selectedFile=file
      this.$router.push({
        name: 'formdetail',
        params: {
          idff:file.idff,
          name: file.filename,
          idfp:this.selectedProjectIdfp
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    },

    async getProjectPartersFromTask(projectIdfp) {
      try {
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        const selectedProjectAcls = await this.getProjectAclListByIdfp(projectIdfp);
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar);
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
        });
        await Promise.all(promises);
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },

    //根据文件idff获取相应的文件程序信息记录
    async getChatmsgsByReceiverAndAboutwhat(receiver, aboutwhat) {
      try {
        const token = localStorage.getItem('token');
        const data = { receiver: receiver, aboutwhat: aboutwhat };
        const response = await axios.get('https://api2.itaskid.com' + '/my/getchatmsgsbyreceiverandaboutwhat', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });

        // this.fileChatMsgs=result.data
        // return this.fileChatMsgs
        if (Array.isArray(response.data)) {
          // this.fileChatMsgs=result.data
          return response.data;
        } else {
          // alert('账号未认证，无法获取数据sdf！');
          consloe.log('无相应聊天数据！')
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    showPubFileMenu(fileIdff,folder){
      if(fileIdff=='all'){
        this.selectedParentFolder=null
      } else {
        
        this.selectedParentFolder=folder
        console.log('this.selectedParentFolder',this.selectedParentFolder)
      }
      this.isShowPubFileMenu=!this.isShowPubFileMenu
      this.switchMenuMask()
    },
    showPubFileOnly(){
      var publishedFiles=this.allMyPubFiles.filter(file=>file.isfolder==0&&file.filestatus=='已发布')
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)

      this.myPubFiles=publishedFiles
      
      publishedFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })
    },

    showAllPubFile(){
      this.myPubFiles=this.allMyPubFiles
    },
    showPubFileApproved(){
      var files=this.allMyPubFiles.filter(file=>file.isfolder==0)
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)


      const username=localStorage.getItem('username')
      var myApprovedFiles=files.filter((file)=>{
        var checkers=[]
        if(!(file.checked=='')){
          checkers=file.checked.split(';')
        }
        if(!(file.approved=='')){
          checkers.push(file.approved)
        }
        if(checkers.includes(username)){
          return file
        }
      })

      this.myPubFiles=myApprovedFiles

      myApprovedFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })

    },



    
    // showCheckingNum(){
    //     var checkingNum=0
    //     var checkinger=[]
    //     var checkeder=[]
    //     const username=localStorage.getItem('username')
    //     this.allMyPubFiles.forEach((file)=>{
    //       if(!(file.checker=='')){
    //         checkinger=file.checker.split(';')
    //       }
    //       if(!(file.approver=='')){
    //         checkinger.push(file.approver)
    //       }
    //       if(!(file.checked=='')){
    //         checkeder=file.checked.split(';')
    //       }
    //       if(!(file.approved=='')){
    //         checkeder.push(file.approved)
    //       }
    //       if(checkinger.includes(username)&&!(checkeder.includes(username))) {
    //         checkingNum++
    //       }
    //     })
    //     this.checkingNum=checkingNum
    //     if(checkingNum>99){
    //       return '99+'
    //     } else if(checkingNum>0) {
    //       return checkingNum
    //     } else {
    //       return ''
    //   }
    // },
    showPubFileApproving(){
      var folders=this.allMyPubFiles.filter(file=>file.isfolder==1)
      var files=this.allMyPubFiles.filter(file=>(file.filestatus=='待审批'||file.filestatus=='待审核')&&file.isfolder==0)



      const username=localStorage.getItem('username')
      var myApprovingFiles=files.filter((file)=>{
        var checkers=file.checker.split(';')
        var checkeder=file.checked.split(';')
        var approver=file.approver
        var approved=file.approved
        if(checkers.includes(username)&&!checkeder.includes(username)){
          return file
        } else if(approver==username&&approved==''){
          const allCheckersInCheckeder = checkers.every(checker => checkeder.includes(checker));
            if(allCheckersInCheckeder){
              return file
            }
        } else {

        }
      })

      this.myPubFiles=myApprovingFiles
      console.log('this.myPubFiles',this.myPubFiles)

      myApprovingFiles.forEach((file)=>{
        this.getFoldersOfFile(file,folders)
      })
    },
    closeFilePubMenu(){
      this.isShowPubFileMenu=!this.isShowPubFileMenu
    },
    closeFileAprovelMenu(){
      this.ifShowApprFileMenu=!this.ifShowApprFileMenu
      
    },
    pubNewFile(newPubFile,newMsg){
      this.pubfiles.push(newPubFile)
      this.addPubfile(newPubFile)
      const filemsg1=`文件‘${newPubFile.filename}’需要您的审批！`
      const newAproverAcl={
        idffuacl:uuidv4(),
        file_idff:newPubFile.idff,
        parter_user:newPubFile.approver,
        roler:'approver',
        updatetime:new Date(),
        filemsg:filemsg1,
        filerstatus:'approving'
      }
      this.addFileUserAclSql(newAproverAcl)
      const fileCheckers=newPubFile.checker.split(';')
      fileCheckers.forEach((item)=>{
        const filemsg=`文件‘${newPubFile.filename}’需要您的审核！`
        const newCheckerAcl={
        idffuacl:uuidv4(),
        file_idff:newPubFile.idff,
        parter_user:item,
        roler:'checker',
        updatetime:new Date(),
        filemsg:filemsg,
        filerstatus:'checking'
      }
      this.addFileUserAclSql(newCheckerAcl)
      })

      const username=localStorage.getItem('username')

      const fileMsg={
        id:uuidv4(),
        receiver:newPubFile.idff,
        sender: `publisher|${username}`,
        timestamp: new Date(),
        file_name: newPubFile.filename,
        message:newMsg,
        type:'text',
        img_name:'',
        file_size:0,
        aboutwhat:'fileprocess'
      }


      this.addChatmsg(fileMsg)


      
    },

    addPubfile(newPubfile){
      const token = localStorage.getItem('token')
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/pubfiles',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newPubfile
        })
        .then((response)=>{
          
          // console.log('数据库新增的发布文件信息',response.data)
          console.log('新增发布文件成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    },

    getPubfilesByUser(username){
      const token = localStorage.getItem('token')
      const data={publisher:username}
      axios({
          method:'get',
          url: 'https://api2.itaskid.com' + '/my/pubfilesbyuser',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
          })
        .then((response)=>{
          if (Array.isArray(response.data)){
            this.pubfiles=response.data
          }else
          alert('账号未认证，无法获取数据！')
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
      },

      //获取我参与项目的已发布文件、我发起的文件和待我审批的文件
      getMyPubFiles(username){
        var myPubFiles=[]

        if (Array.isArray(this.pubfiles)){

          this.pubfiles.forEach((file)=>{
            var checkers=[]
            if(!(file.checker=='')){
              checkers=file.checker.split(';')
            }
            if(!(file.approver=='')){
              checkers.push(file.approver)
            }
            if(file.filestatus=='已发布'||checkers.includes(username)||file.publisher==username){
              myPubFiles.push(file)
            }
          })

        }

        this.myPubFiles=myPubFiles
        return myPubFiles
      },



    downloadPubfileFromServer(url,downloadUrl) {
      const token = localStorage.getItem('token')
      const data = { url: url }
      axios({
        method: 'get',
        url: 'https://api1.itaskid.com' + `/my/${downloadUrl}`,
        headers: {
          Authorization: token,
        },
        params: data,
        responseType: 'blob', // 添加 responseType: 'blob' 来指定响应类型为二进制数据
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data])) // 创建一个临时的下载链接
          const link = document.createElement('a') // 创建一个 <a> 元素
          link.href = downloadUrl // 设置下载链接
          link.setAttribute('download', url) // 设置下载文件的文件名
          document.body.appendChild(link) // 将 <a> 元素添加到页面中
          link.click() // 模拟点击下载
          document.body.removeChild(link) // 下载完成后移除 <a> 元素
          window.URL.revokeObjectURL(downloadUrl) // 释放临时的下载链接资源
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
    },

    //关于文件聊天记录的函数
    sendFileMessage(fileMsg){
      const username=localStorage.getItem('username')
      const now=new Date()
      // 发送消息给socket.io服务器
      const newFileMessage={
        id:uuidv4(),
        message: fileMsg,
        receiver: this.selectedFile.idff,
        sender: username,
        file_name:this.selectedFile.filename,
        img_name:'',
        type: 'text',
        timestamp: now,
        file_size: 0,
        aboutwhat:'filechat'
      }
      
      this.addChatmsg(newFileMessage)
      newFileMessage.sockettype='objchat'
      this.socket.emit('message',newFileMessage,(response)=>{
        console.log('Server response:',response)
      })
    }
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(params) {
        const idfp = params.idfp;
        this.selectedProjectIdfp=idfp
        const idff = params.idff
        // this.getFileinfoByIdff(idff)
        //   .then((result)=>{
        //     console.log('result',result)
        //     this.selectedFile=result
        //     this.$router.push({
        //       name: 'filedetail',
        //       params: {
        //         idff: idff,
        //         name: result.filename
        //       }
        //     }).catch(err => {
        //       if (err.name !== 'NavigationDuplicated') {
        //         throw err
        //       }
        //     })
        //   })
        const username=localStorage.getItem('username')
        if(idfp=='all'){
          
          // this.getPubfilesByUser(username)
          // var pubfiles=[]
          // this.myProjectlist1.forEach((project)=>{
          //   this.getPubfilesByProject(project.idfp)
          //     .then((result)=>{
          //       pubfiles.push(result)
          //     })
          // })
          // this.pubfiles=pubfiles

          var pubfiles = [];
          var promises = this.myProjectlist1.map(async (project) => {
            const result=await this.getPubfilesByProject(project.idfp)
            if(result.length>0){
              pubfiles=pubfiles.concat(result)
            }
          });
          
          Promise.all(promises)
            .then(() => {
              this.pubfiles = pubfiles;
              this.myPubFiles=this.getMyPubFiles(username)
            })
            .catch((error) => {
              console.error('An error occurred while fetching pubfiles:', error);
            });
          
        }else {
          this.getPubfilesByProject(idfp)
            .then((result)=>{
              this.pubfiles=result
              this.getMyPubFiles(username)

            })
        }
      }
    }
  },

  beforeDestroy() {
    // 断开socket.io连接
    if (this.socket) {
      this.socket.disconnect();
    }
  },

  mounted(){
    // this.startAnimation()

    // const array=this.findChildrenArrayOfFile1(this.testArray)
    // console.log('array',array)
    const username=localStorage.getItem('username')
    const usernogroup=`${username}nogroup`
    let projectAcllist=[]
    this.getMyProjectAclList(username)
      .then((result)=>{
        projectAcllist=result
        if(projectAcllist&&projectAcllist.length>0) {
          const promises=projectAcllist.map(async (projectacl)=>{
            const project=await this.getMyProjectListByIdfp(projectacl.project_idfp)
            projectacl.name=project[0].name
            projectacl.groupname=project[0].groupname
            projectacl.status=project[0].status
            projectacl.is_delete=project[0].is_delete
            projectacl.createdTime=project[0].createdTime
            projectacl.creater=project[0].creater
            projectacl.permit_type=project[0].permit_type
            projectacl.idfp=project[0].idfp
          })
          return Promise.all(promises)
        }
      })
        .then(()=>{
          this.myProjectlist=projectAcllist.filter(item=>(!(item.idfp==usernogroup)))
          var pubfiles = [];
          var promises = this.myProjectlist.map(async (project) => {
            const result=await this.getPubfilesByProject(project.idfp)
            if(result.length>0){
              pubfiles=pubfiles.concat(result)
            }
          });
          Promise.all(promises)
            .then(() => {
              this.pubfiles = pubfiles;
              this.allMyPubFiles=this.getMyPubFiles(username)
              this.showCheckingNum1()
              const MyPubFileFolders=this.allMyPubFiles.filter(file=>file.isfolder==1)

              this.allMyPubFileFolders=MyPubFileFolders
            })
            .catch((error) => {
              console.error('An error occurred while fetching pubfiles:', error);
            });
        })

    
    // 创建socket.io客户端实例并连接服务器
    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
    });
    
    // 监听服务器发送的消息
    this.socket.on('message', (message) => {
      this.fileChatMsgs.push(message)
    });
    
  },
}
</script>

<style lang="less" scoped>
  h4 {
    height: 40px;
    margin: 0;
  }
  .tableheader {
    height: 30px;
    td {
      input {
        width: 95%;
      }
    }
    
  }

  .pubfileheader-container {
    display: inline-block;
    text-align: left;
    height: calc(95ch - 250px);
    border-right: 1px solid gray;
  }

  .pubfiledetail-container {
    display: inline-block;
    text-align: left;
  }

  .filenametitle {
    word-wrap: break-word; 
  }

  .filecontenttable {
    border-collapse: collapse;
    td {
      border-bottom: 0.5px solid rgb(205, 205, 205);
    }
  }

  .item {
    padding:0;
    float:right;
    height:30px;
    margin-right:20px;
    border:1px solid gray;
    
    .el-button{
      padding:0;
      height:30px;
      background-color: rgb(239, 239, 239);
    }

    .el-button:hover {
      background-color: rgb(229, 229, 229);
    }
  }

  .badget {
    position:absolute;
    font-size: 12px;
    padding-top:1px;
    width:22px;
    height:14px;
    border-radius: 6px;
    left:40px;
    top:-8px;
    // background-color: rgb(245, 108, 108);
  }

  .filestatusstyle {
    width:50px;
  }

  .waitcheck:hover {
    color:rgb(82, 167, 254);
  }

  // .waitcheck:hover {
  //   color:red;
  // }

  .pubfileclicked{
    color: rgb(71, 114, 250);
  }

  @keyframes blinkAnimation {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
  }
  
  
  .blink {
    animation: blinkAnimation 1.5s infinite;
  }


</style>