<template>
    <div style="height: 100%; display: flex; width: 100%; position:relative;margin:0px;padding:0;min-width: 800px;">

      <Detail @upDateStarttime="upDateStarttime" :selectedTask="selectedTask" :selectedTaskResPlans="selectedTaskResPlans"
        :taskContents="taskContents"  :selectedProjectAcls="selectedProjectAcls" :selectedTaskFiles="selectedTaskFiles"
        :taskProcessMsgs="taskProcessMsgs" @deleteLocalTaskFile="deleteLocalTaskFile" @addLocalTaskSummitMsg="addLocalTaskSummitMsg"
        @sendTaskMessage="sendTaskMessage" @upDateEndtime="upDateEndtime" @updateTaskOfSql="updateTaskOfSql"
         @addLocalTaskFileData=" addLocalTaskFileData" @updateTaskRecord="updateTaskRecord" v-if="showTaskEdit"  v-draggable>
      </Detail>

      <!-- <div class="menu-container1" v-if="showTaskEdit"  v-draggable>
        <div class="menu" style="position:relative;">
          <Detail @upDateStarttime="upDateStarttime" :selectedTask="selectedTask" :selectedTaskResPlans="selectedTaskResPlans"
            :taskContents="taskContents"  :selectedProjectAcls="selectedProjectAcls" :selectedTaskFiles="selectedTaskFiles"
            :taskProcessMsgs="taskProcessMsgs" @deleteLocalTaskFile="deleteLocalTaskFile" @addLocalTaskSummitMsg="addLocalTaskSummitMsg"
            @sendTaskMessage="sendTaskMessage" @upDateEndtime="upDateEndtime" @updateTaskOfSql="updateTaskOfSql"
             @addLocalTaskFileData=" addLocalTaskFileData" @updateTaskRecord="updateTaskRecord" v-if="showTaskEdit"  v-draggable>
          </Detail>
        </div>
      </div> -->
      
      <div style="height: 100%; position:relative;" class="el-calendar">
        <div class="calendar">
        <div style="height: 40px; margin: 0px; position:relative;">
          <div class="header">
            <h4>{{ currentMonth }}</h4>
            <button style="border: none; background: none; margin-right:10px;" @click="pickYearMonth">
              <i class="el-icon-arrow-down"></i>
            </button>
            <div class="MonthMenuContainer" v-if="showYearMonthMenu">
              <div>
                <label for="">{{ currentYear }}</label>
                <button @click="preYear" class="month-button">
                  <i class="el-icon-arrow-left"></i>
                </button>
                <button @click="thisYear" class="todaybutton">
                  今年
                </button>
                <button @click="nextYear" class="month-button">
                  <i class="el-icon-arrow-right"></i>
                </button>
              </div>
              <ul class="months">
                <li v-for="(month,index) in months" :key="month" @click="setMonthFromMenu(index)"
                :class="{ blueBackground: isSelected(index + 1) }">
                    {{ month }}
                </li>
              </ul>
            </div>
            <button @click="previousMonth" class="month-button">
              <i class="el-icon-arrow-left"></i>
            </button>
            <button @click="thisMonth" class="todaybutton">
              今天
            </button>
            <button @click="nextMonth" class="month-button">
              <i class="el-icon-arrow-right"></i>
            </button>
          </div>
          <div style="float: right;" class="calendarOption">
            <button class="day" @click="goToDayCalendar" :class="{calendarOptionBtn: calendarOption==1}">日</button>
            <button class="week" @click="goToWeekCalendar" :class="{calendarOptionBtn: calendarOption==2}">周</button>
            <button class="month" @click="goToMonthCalendar" :class="{calendarOptionBtn: calendarOption==3}">月</button>
          </div>
        </div>
        <AddTaskMenu v-if="ifShowAddTaskMenu" @closeMenu="closeAddTaskMenu" :newStartDate="newStartDate"
          @addLocalTask="addLocalTask" :selectedDateTime="selectedDateTime" :newTime="newTime">
        </AddTaskMenu>
        <div  v-show="showMonthCalendar" class="monthCalendarContainer">
          <ul class="weeks">
            <li v-for="day in weeks" :key="day">
                {{ day }}
            </li>
          </ul>
          <ul class="days">
            <li v-for="(day,index) in days" :key="index" @mouseup="selectDate(day)" class="dayTasksContainer"  
            :style="{ backgroundColor: computedBgcOfContainer(day.date),height: computedHeight()}"
              ref="dayscontainer">  
              <label for="" :style="computedCSSofDay(day.date)" @mouseup.stop="goToDayCalendar1(day.date)" class="dayLabel">
                {{ day.day }}
              </label>
              <div style="width: 100%; max-height: calc(100% - 30px); overflow-y: auto;" class="onlydaytaskscontainer">
                <ul v-for="task in currentDayTasks2(day.date)" :key="task.id">
                  <li class="dayTaskContainer" @mouseup.stop="editDayTask(task)" @mousedown.stop="handleMousedownTask($event,task)">
                    <div class="taskName">{{ task.name }}</div>
                    <div class="taskTime">{{ sliceTimeFrimeDatetime(task.starttime) }}</div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div  v-show="showWeekCalendar" class="weekCalendarContainer">
          <div style="width: calc(100vw - 400px);height: 100%;min-width: 500px;">
            <ul class="weeks" style="margin-left: 20px;width: calc(100vw - 415px);min-width: 500px;">
              <li v-for="day in weeks" :key="day">
                  {{ day }}
              </li>
            </ul>

            <!-- 周任务的获取应该是和月任务是一样的 -->
            <ul class="days">
              <li v-for="(day,index) in thisWeekDays" :key="index" @mouseup="selectDate(day)" class="dayTasksContainer"
                ref="dayscontainer" :style="{ backgroundColor: computedBgcOfContainer(day.date) }">
                <label for="" :style="computedCSSofDay(day.date)" @mouseup.stop="goToDayCalendar1(day.date)" class="dayLabel">
                  {{ day.day }}
                </label>
                <!-- position:relative; -->
                <!-- position: absolute; top: 0; left: 0;  -->
                <div style="width: 100%;" class="onlydaytaskscontainer">
                    <!-- 时间网格 -->
                  <ul v-for="task in currentDayTasks2(day.date)" :key="task.idft">
                    <li class="dayTaskContainer" @mouseup.stop="editDayTask(task)" @mousedown.stop="handleMousedownTask($event,task)">
                      <div class="taskName">{{ task.name }}</div>
                      <div class="taskTime">{{ sliceTimeFrimeDatetime(task.starttime) }}</div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <div  v-show="showDayCalendar" class="dayCalendarContainer">
          <div style="width: calc(100vw - 400px);height: 100%; min-width: 500px;">
            <!-- <ul class="weeks" style="margin-left: 20px;width: calc(100vw - 415px);min-width: 500px;">
              <li>
                  {{ getDayOfWeek() }}
              </li>
            </ul> -->
            <div style="text-align: left;margin-bottom: 10px;">
              <label style="margin-right:10px;">{{ getDayOfWeek() }}</label>
              <label>{{ this.currentDay.getDate() }}</label>
              <button @click="showAfterNoonTasks()" class="month" style="margin-right:4px;" :class="{timeArrayOptionBtn: !showBeforeNoon}">12:00~23:59</button>
              <button @click="showBeforeNoonTasks()" class="day" :class="{timeArrayOptionBtn: showBeforeNoon}">00:00~11:59</button>
            </div>
            <!-- 周任务的获取应该是和月任务是一样的 -->
            <div style="width: 100%;height: calc(100% - 30px);">
              <ul style="height: 100%;" class="days" v-if="showBeforeNoon">
                <li  v-for="(time,index) in dayTimes1" :key="index" class="dayTasksContainer" @mouseup="selectDate1(time)"
                style="font-size: 12px; ">
                  {{ time }}
                  <!-- <ul v-for="task in currentTimeTasks(index)" :key="task.idft" style="display: flex;"> -->
                  <div style="display:inline-block;max-width:calc(100vw - 450px);display: flex;  width:calc(100vw - 450px);
                    margin-left:5px;" class="taskcontainerdiv">
                    <ul v-for="task in currentTimeTasks(index)" :key="task.idft" style="margin-left:2px;margin-top:5px;">
                      <li @mouseup.stop="editDayTask(task)"  class="dayTaskContainer" 
                        @mousedown.stop="handleMousedownTask($event,task)">
                        <div class="taskName" style="margin-left:3px;">{{ task.name }}</div>
                        <div class="taskTime">{{ sliceTimeFrimeDatetime(task.starttime) }}</div>
                      </li>
                    </ul>
                  </div>

                </li>
              </ul>
              <ul style="height: 100%; " class="days" v-if="!showBeforeNoon">
                <li  v-for="(time,index) in dayTimes2" :key="index" class="dayTasksContainer" style="font-size: 12px;" @mouseup="selectDate1(time)">
                  {{ time }}
                  <div style="display:inline-block;max-width:calc(100vw - 450px);display: flex; width:calc(100vw - 450px);
                    margin-left:5px;" class="taskcontainerdiv">
                    <ul v-for="task in currentTimeTasks(index+12)" :key="task.idft" style="margin-left:2px;">
                      <li class="dayTaskContainer" @mouseup.stop="editDayTask(task)"
                        @mousedown.stop="handleMousedownTask($event,task)">
                        <div class="taskName" style="margin-left:3px;">{{ task.name }}</div>
                        <div class="taskTime">{{ sliceTimeFrimeDatetime(task.starttime) }}</div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>
        </div>
      </div>
      <div  class="el-project" style="width: 250px;">
        <ProjectGroup @selectAllProject="selectAllProject" @selectProjectTasks="selectProjectTasks" @selectGroupTasks="selectGroupTasks"
        @selectNoGroupTasks="selectNoGroupTasks">
        </ProjectGroup>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState,mapMutations,mapActions} from 'vuex'
import { eventBus } from '../../main.js'
import ProjectGroup from '../pubcomponets/ProjectGroup.vue'
import EditTaskMenu from '../menu/calendar/EditTaskMenu.vue'
import AddTaskMenu from '../menu/task/AddTaskMenu.vue'
// import Detail from '../task/Detail.vue'
import Detail from '../menu/task/Detail.vue'
import io from 'socket.io-client'

// 自定义draggable指令
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {

        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset
        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})


export default {
  components: {
    ProjectGroup,
    EditTaskMenu,
    Detail,
    AddTaskMenu
  },
  data() {
    return {
      currentMonth: '',
      days: [],
      showBeforeNoon:true,
      thisWeekDays:[],
      weeks:['周日','周一','周二','周三','周四','周五','周六'],
      months:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月',],
      dayTimes1:['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00'],
      dayTimes2:['12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
      selectedDate: null,
      currentDay: new Date(),
      selectedTasks:[
        {name:'明天上午九点开会',starttime:'2023-10-08'},
        {name:'嘻嘻哈哈吼吼悦悦',starttime:'2023-10-08'},
      ],
      allTasks:[],
      tasklistall:[],
      showYearMonthMenu:false,
      currentYear:'',
      selectedIndex: 0,
      selectTasks:[],
      username:'',
      showMonthCalendar:true,
      showWeekCalendar: false,
      showDayCalendar: false,
      calendarOption:3,
      weekDates:[],

      //日、周、月的选项
      selectedOption:'',
      hours:['00','01','02'],
      showTaskEdit:false,

      //与点击任务编辑相关的信息currentDay
      selectedTask:{},
      // taskMessages:[],
      selectedProjectAcls:[],
      selectedProject:{},
      myTaskAcllist:[],
      taskContents:[],
      socket: null,
      ifShowAddTaskMenu:false,
      newStartDate:'',
      selectedDateTime:'',
      newTime:'',

      //与任务资源相关的数据
      selectedTaskResPlans:[],

      myChargeProjectTasks:[],

      //以下是与任务提交相关的数据
      taskProcessMsgs:[],
      selectedTaskFiles:[],
    };
  },
  mounted() {
    const weekDates=this.getWeekDates(new Date())
    this.weekDates= weekDates
    const username=localStorage.getItem('username')
    this.username=username
    this.setCurrentMonth();
    this.setDays(this.currentDay);
    const today=new Date()
    const dd=this.setDays(today)
    this.setWeekDays(this.currentDay)
    let tasklistAll=[]

    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
      
    });

    this.fetchTasks()

    
    
    // 监听服务器发送的消息
    this.socket.on('message', (message) => {
      if(message.receiver==this.selectedTask.idft){
        this.taskContents.push(message)
      }
    }); 
    // this.getAllTasks()
    //   .then((result)=>{
    //     tasklistAll=result
    //     const promises=tasklistAll.map(async (task)=>{
    //       const project=await this.getProjectinfoByIdfp(task.project)
    //       task.group=project[0].groupname
    //     })
    //     return Promise.all(promises)
    //   })
    //     .then(()=>{
    //       this.tasklistall=tasklistAll
    //       this.selectTasks=tasklistAll

    //     })
    // let projectAcllist=[]
    // const usernogroup=`${username}nogroup`
    // this.getMyProjectAclList1(username)
    //   .then((result)=>{
    //     projectAcllist=result
        
    //     const promises=projectAcllist.map(async (projectacl)=>{
    //       const project=await this.getMyProjectListByIdfp(projectacl.project_idfp)
          
    //       projectacl.name=project[0].name
    //       projectacl.groupname=project[0].groupname
    //       projectacl.status=project[0].status
    //       projectacl.is_delete=project[0].is_delete
    //       projectacl.createdTime=project[0].createdTime
    //       projectacl.creater=project[0].creater
    //       projectacl.permit_type=project[0].permit_type
    //       projectacl.idfp=project[0].idfp
    //     })
    //     return Promise.all(promises)
    //   })
    //     .then(()=>{
    //       this.myProjects=projectAcllist
    //       this.items=this.myProjects.filter(item=>(!(item.idfp==usernogroup)))
    //     })

    //暂时停止这个函数，如果不可以再调整hui
    // this.getTasks()
    //   .then((result)=>{
    //     tasklistAll=result
    //     console.log('tasklistAll',tasklistAll)
    //     tasklistAll.sort((a, b) => {
    //       // 将 starttime 转换为 Date 对象
    //       let dateA = new Date(a.starttime);
    //       let dateB = new Date(b.starttime);
      
    //       // 比较日期时间
    //       return dateA - dateB;
    //     });
    //     const promises=tasklistAll.map(async (task)=>{
    //       const project=await this.getProjectinfoByIdfp(task.project)
    //       console.log('project',project)
    //       task.group=project[0].groupname
    //     })
    //     return Promise.all(promises)
    //   })
    //     .then(()=>{
    //       this.tasklistall=tasklistAll
    //       this.selectTasks=tasklistAll
    //       this.currentDayTasks1()
    //     })
      
  },

  computed :{
    dayTasks() {
      return(date)=>{
        const dayTasks=this.selectTasks.filter((task)=>{
          const startday = new Date(task.starttime);
          const year = startday.getFullYear();
          const month = startday.getMonth() + 1;
          const day = startday.getDate();
          const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          if(formattedDate===date){
            return task
          }
        })
        return dayTasks
      }
    },

    computedHeight() {
      return()=>{
        return `calc((100vh - 200px) / ${this.days.length/7})`;
      }
    },

    currentDayTasks2(){
      return (date)=>{
        const currentDate = new Date(date);
        const nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate());
      
        // 检查是否是当前月的最后一天
        if (nextDay.getMonth() !== currentDate.getMonth()) {
          // 如果是当前月的最后一天，则转到下个月的第一天
          nextDay.setMonth(currentDate.getMonth() + 1, 1);
        }

        const year = nextDay.getFullYear();
        const month = nextDay.getMonth() + 1;
        const day = nextDay.getDate();
        const formattedDate1 = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        // const selectTasks=this.selectTasks.sort((a, b) => {
        //   // 将 starttime 转换为 Date 对象
        //   let dateA = new Date(a.starttime);
        //   let dateB = new Date(b.starttime);
      
        //   // 比较日期时间
        //   return dateA - dateB;
        // });

        const dayTasks=this.selectTasks.filter((task)=>{
          const startday = new Date(task.starttime);
          const year = startday.getFullYear();
          const month = startday.getMonth() + 1;
          const day = startday.getDate();
          const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          if(formattedDate===formattedDate1){

            return {...task}
          }
        })

        return dayTasks

      }

    },

    currentDayTasks(){
      return ()=>{
        const year = this.currentDay.getFullYear();
        const month = this.currentDay.getMonth() + 1;
        const day = this.currentDay.getDate();
        const formattedDate1 = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        // const selectTasks=this.selectTasks.sort((a, b) => {
        //   // 将 starttime 转换为 Date 对象
        //   let dateA = new Date(a.starttime);
        //   let dateB = new Date(b.starttime);
      
        //   // 比较日期时间
        //   return dateA - dateB;
        // });
        const dayTasks=this.selectTasks.filter((task)=>{
          const startday = new Date(task.starttime);
          const year = startday.getFullYear();
          const month = startday.getMonth() + 1;
          const day = startday.getDate();
          const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          if(formattedDate===formattedDate1){
            return {...task}
          }
        })
 
        return dayTasks

      }

    },

    currentTimeTasks(){
      return (index)=>{
        const year = this.currentDay.getFullYear();
        const month = this.currentDay.getMonth() + 1;
        const day = this.currentDay.getDate();
        const formattedDate1 = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        // const selectTasks=this.selectTasks.sort((a, b) => {
        //   // 将 starttime 转换为 Date 对象
        //   let dateA = new Date(a.starttime);
        //   let dateB = new Date(b.starttime);
      
        //   // 比较日期时间
        //   return dateA - dateB;
        // });
        const dayTasks=this.selectTasks.filter((task)=>{
          const startday = new Date(task.starttime);
          const year = startday.getFullYear();
          const month = startday.getMonth() + 1;
          const day = startday.getDate();
          const startHour = startday.getHours(); // 获取任务开始时间的小时部分
          const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          if(formattedDate===formattedDate1&&startHour===index){
            
            return task
          }
        })
        return dayTasks
      }

    },


    computedBgcOfContainer(){
      return(date)=>{
        const today=new Date()
        const dayOfToday=today.toLocaleString().split(' ')[0]
        if(date==dayOfToday) {
          return 'rgb(236, 240, 254)'
        }
      }
    },

    computedBgcOfDay(){
      return(day)=>{
        const today=new Date()
        const dayOfToday=today.getDate()
        if(day==dayOfToday) {
          return 'rgb(71, 114, 250)'
        }
      }
    },

    computedCSSofDay(){
      return(date)=>{
        const today=new Date()
        const dayOfToday=today.toLocaleDateString().split(' ')[0]
        let cssOfDay={}
        if(date==dayOfToday) {
          cssOfDay={backgroundColor: 'rgb(71, 114, 250)',color: 'rgb(255, 255, 255)'}
          return cssOfDay
        } else {
          cssOfDay={backgroundColor: 'rgb(255, 255, 255)',color: 'rgb(51, 51, 51)'}
          return cssOfDay
        }
      }
    },

    
    //根据日期得到日
    getDayOfWeek() {
      return ()=>{
        let date = this.currentDay;
        // 获取日期
        const daysOfWeek = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
        const dayOfWeek = daysOfWeek[date.getDay()];
        return dayOfWeek
      }
    },

    sliceTimeFrimeDatetime(){
      return (dateTime)=>{
        const chinaDateTime=this.convertToChinaTime(dateTime)
        return chinaDateTime
      }

    },
    sliceTimeFrimeDatetime1(){
      return (dateTime)=>{
        const chinaDateTime=this.convertToChinaTime(dateTime)
        const [hours, minutes] = chinaDateTime.split(':').map(Number);
        const currentTimeInMinutes = hours * 60 + minutes
        if(currentTimeInMinutes<60) {
          return 0
        } else {
          const quotient = Math.floor(currentTimeInMinutes / 6)
          return quotient
        }
      }

    },     
  },
  methods: {

    showBeforeNoonTasks(){
      this.showBeforeNoon=true
    },

    showAfterNoonTasks(){
      this.showBeforeNoon=false
    },


    deleteLocalTaskFile(file){
      this.selectedTaskFiles=this.selectedTaskFiles.filter(item=>item.idftf!==file.idftf)
    },

    closeSummitTaskMenu(){
      this.showSummitTask=false
    },

    addLocalTaskSummitMsg(msg){
      this.taskProcessMsgs.push(msg)
    },

    addLocalTaskFileData(file){
      this.selectedTaskFiles.push(file)
    },

    addLocalTask(newTask){

      // this.tasklistAll.push(newTask)
      // this.tasklistAll.sort((a, b) => {
      //     let dateA = new Date(a.starttime);
      //     let dateB = new Date(b.starttime);
      //     return dateA - dateB;
      //   });
      // this.selectTasks=tasklistAll
      // this.currentDayTasks1()
      this.selectTasks.unshift(newTask)
      this.ifShowAddTaskMenu=false
    },

    currentDayTasks1(){
        const year = this.currentDay.getFullYear();
        const month = this.currentDay.getMonth() + 1;
        const day = this.currentDay.getDate();
        const formattedDate1 = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        // const selectTasks=this.selectTasks.sort((a, b) => {
        //   // 将 starttime 转换为 Date 对象
        //   let dateA = new Date(a.starttime);
        //   let dateB = new Date(b.starttime);
      
        //   // 比较日期时间
        //   return dateA - dateB;
        // });
        const dayTasks=this.selectTasks.filter((task)=>{
          const startday = new Date(task.starttime);
          const year = startday.getFullYear();
          const month = startday.getMonth() + 1;
          const day = startday.getDate();
          const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          if(formattedDate===formattedDate1){
            return {...task}
          }
        })


        return dayTasks
    },


    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
    }),

    ...mapActions({
      addTaskToSql:'addTaskToSql',
      getMyTaskAclList:'getMyTaskAclList',
      getTaskinfoByIdft: 'getTaskinfoByIdft',
      getMyProjectAclList:'getMyProjectAclList',
      getMyProjectListByIdfp:'getMyProjectListByIdfp',
      convertUTCtoCST:'convertUTCtoCST',
      getTaskContents:'getTaskContents',
      getTasksByIdfp:'getTasksByIdfp',
      // convertDate:'convertDate',
      // updateTaskOfSql:'updateTaskOfSql',
      getTaskAclsByTaskIdft:'getTaskAclsByTaskIdft',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
      showPic:'showPic',
      getMsgsByReceiver:'getMsgsByReceiver',
      getTaskMsgsByReceiver:'getTaskMsgsByReceiver',
      getResPlanlistByTask:'getResPlanlistByTask',
      getTaskFilesByIdft:'getTaskFilesByIdft',
      getTaskSummitMsgs:'getTaskSummitMsgs',
    }),

    //切换日、周、月日历
    goToDayCalendar(event){
      this.calendarOption=1
      this.showMonthCalendar=false
      this.showWeekCalendar=false
      this.showDayCalendar=true
      
      
    },
    goToDayCalendar1(date){
      const date1=new Date(date)
      this.calendarOption=1
      this.showMonthCalendar=false
      this.showWeekCalendar=false
      this.showDayCalendar=true
      this.currentDay=date1
    },
    goToWeekCalendar(){
      this.calendarOption=2
      this.showMonthCalendar=false
      this.showDayCalendar=false
      this.showWeekCalendar=true
      

    },
    goToMonthCalendar(){
      this.calendarOption=3
      this.showMonthCalendar=true
      this.showDayCalendar=false
      this.showWeekCalendar=false
      
    
    }, 
    nextYear(){
        let date=this.currentDay
        const currentYear=date.getFullYear()
        date.setFullYear(currentYear+1)
        this.currentDay=date
        this.setDays(this.currentDay)
        this.setCurrentMonth()
    },

    thisYear(){
      this.currentDay=new Date()
      this.setDays(this.currentDay)
      this.setCurrentMonth()
    },

    preYear(){
      let date=this.currentDay
      const currentYear=date.getFullYear()
      date.setFullYear(currentYear-1)
      this.currentDay=date
      this.setDays(this.currentDay)
      this.setCurrentMonth()
    },

    setMonthFromMenu(index){
      const date=this.currentDay
      const year =date.getFullYear();
      const month=index
      const day=date.getDate()
      this.currentDay=new Date(year,month,day)
      this.setDays(this.currentDay)
      this.setCurrentMonth()
    },

    isSelected(index){
        return index === this.selectedIndex;
    },

    pickYearMonth(){
      this.showYearMonthMenu=true
      this.switchMenuMask()
      eventBus.$on('closeContexMenu', () => {
        this.showYearMonthMenu=false
      })
    },

    setCurrentMonth() {
      const date = this.currentDay;
      const year = date.getFullYear();
      const month = date.getMonth()+1;
      this.currentMonth = `${year}年${month}月`;
    },

    setDays(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  this.selectedIndex = month + 1;
  const firstDayOfMonth = new Date(year, month, 1).getDay(); // 获取本月第一天是星期几
  
  const daysInMonth = new Date(year, month + 1, 0).getDate(); // 获取本月总天数

  
  const daysInWeek = 7; // 一周有7天

  // 计算本月第一天所在的周的起始日期
  const startDay = 1 - firstDayOfMonth;  //startDay是本月所在周的起始日期
 
  // 计算本月最后一天所在的周的结束日期
  const endDay = daysInMonth + (daysInWeek - ((firstDayOfMonth + daysInMonth - 1) % daysInWeek));



  this.days = Array.from({ length: endDay - startDay }, (_, i) => {
    const day = startDay + i;
    let dayDate;
    dayDate = new Date(year, month, day)

    return {

      date: dayDate.toLocaleString().split(' ')[0],
      day: dayDate.getDate()
    };
  });
},
 

setWeekDays(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const dayOfWeek = date.getDay(); // 获取本周的第几天，0表示星期日，1表示星期一，以此类推
  const daysInWeek = 7; // 一周有7天

  // 计算本周的起始日期
  const startDay = day - dayOfWeek;
  const startDate = new Date(year, month, startDay);

  // 计算本周的结束日期
  const endDay = startDay + daysInWeek - 1;
  const endDate = new Date(year, month, endDay);

  // 生成包含本周日期的数组
  this.thisWeekDays = Array.from({ length: daysInWeek }, (_, i) => {
    const currentDate = new Date(year, month, startDay + i);
    return {
      date: currentDate.toLocaleString().split(' ')[0],
      day: currentDate.getDate()
    };
  });

 
},


  


    previousMonth() {
      if(this.calendarOption==3){
        let date = this.currentDay;
        const currentMonth = date.getMonth();
        date=date.setMonth(currentMonth - 1);
        this.currentDay=new Date(date)
        this.setDays(this.currentDay)
        this.setWeekDays(this.currentDay)
        this.setCurrentMonth()
      } else if (this.calendarOption==2) {
        let date = this.currentDay;
        const dayOfWeek = date.getDay(); // 获取本周的第几天，0表示星期日，1表示星期一，以此类推
        // 计算上周的同一天
        date.setDate(date.getDate() - 7);
        this.currentDay = date;

        this.setDays(this.currentDay)
        this.setWeekDays(this.currentDay)
        this.setCurrentMonth()
        
      } else if (this.calendarOption==1) {
        let date = this.currentDay;
        const currentDay = date.getDate(); // 获取当前日期的日份
        date.setDate(currentDay - 1); // 将日期设置为前一天
        this.currentDay = date; // 更新this.currentDay
        this.currentDay=new Date(date)
        this.setDays(this.currentDay)
        this.setWeekDays(this.currentDay)
        this.setCurrentMonth()
      } else {

      }
    },
    nextMonth() {
      if(this.calendarOption==3){
        let date = this.currentDay;
        const currentMonth = date.getMonth();
        date=date.setMonth(currentMonth + 1);
        this.currentDay=new Date(date)
        this.setWeekDays(this.currentDay)
        this.setDays(this.currentDay)
        this.setCurrentMonth()
      } else if (this.calendarOption==2) {
        let date = this.currentDay;
        const dayOfWeek = date.getDay(); // 获取本周的第几天，0表示星期日，1表示星期一，以此类推
        // 计算上周的同一天
        date.setDate(date.getDate() + 7);
        this.currentDay = date;

        this.setDays(this.currentDay)
        this.setWeekDays(this.currentDay)
        this.setCurrentMonth()
      } else if (this.calendarOption==1){
        let date = this.currentDay;
        const currentDay = date.getDate(); // 获取当前日期的日份
        date.setDate(currentDay + 1); // 将日期设置为前一天
        this.currentDay = date; // 更新this.currentDay
        this.currentDay=new Date(date)
        this.setDays(this.currentDay)
        this.setWeekDays(this.currentDay)
        this.setCurrentMonth()

      }else {

      }

    },
    thisMonth() {
      this.currentDay=new Date()
      this.setDays(this.currentDay)
      this.setCurrentMonth()
    },
    selectDate(day) {
      this.newStartDate=day.date
     
      this.ifShowAddTaskMenu=true
    },
    selectDate1(time) {
      let year = this.currentDay.getFullYear(); // 获取年份
      let month = this.currentDay.getMonth() + 1; // 获取月份，月份从0开始计数，所以加1
      let day = this.currentDay.getDate(); // 获取日期
      this.newStartDate = `${year}/${month}/${day}`;
      this.newTime=time
      this.ifShowAddTaskMenu=true
    },

    closeAddTaskMenu(){
      this.ifShowAddTaskMenu=false
      this.switchMenuMask();
    },

    handleMousedownTask(event,task){
      event.stopPropagation()
      this.selectedTask=task
      this.getSelectedTaskAcls()
      this.getProjectPartersFromTask(task)


      this.getMsgsByReceiver(task.idft)
        .then((result)=>{
          this.taskContents=result
          
        })
      this.getTaskMsgsByReceiver(task.idft)
        .then(async (result)=>{
          const processedResult = await this.processTaskMsgs(result)
          this.taskContents=processedResult
        })

      this.getResPlanlistByTask(task)
        .then((result)=>{
          this.selectedTaskResPlans=result
        })

      this.getTaskSummitMsgs(task)
        .then((result)=>{
          result.sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
          });
          this.taskProcessMsgs = result;
        })

      this.getTaskFilesByIdft(task.idft)
        .then((result)=>{
          this.selectedTaskFiles=result
        })
    },

    handleMousedownTask1(){
      this.selectedTask=null
      // task.idft=task.task_idft
      // this.getSelectedTaskAcls()
      // this.getProjectPartersFromTask(task)

      this.taskContents=[]

      this.showTaskEdit=true
      this.switchMenuMask()
      eventBus.$on('closeContexMenu', () => {
        this.showTaskEdit=false
      })


      // this.getMsgsByReceiver(task.idft)
      //   .then((result)=>{
      //     this.taskContents=result
          
      //   })
      // this.getTaskMsgsByReceiver(task.idft)
      //   .then(async (result)=>{
      //     const processedResult = await this.processTaskMsgs(result)
      //     this.taskContents=processedResult
      //   })
    },

    async processTaskMsgs(result) {
      for (const msg of result) {
        if (msg.type === 'img') {
          const imgUrl = await this.showPic1(msg.file_name, 'showmsgimg');
          msg.imgUrl = imgUrl;
        }
      }
      return result;  // Optionally return the modified result
    },

    async showPic1(imgname,url) {
      if(imgname==null||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/${url}`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    }, 
    
    editDayTask(task){
      this.clickedInputIdft=task.idft
      // this.selectedTask=task
      // if(this.selectedProject.permit_level=='charger') {
      //       this.selectedTask.permit_level='charger'
      // } else {
      //   const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)
      //   this.selectedTask.permit_level=taskAcl.length==0?'viewer':(taskAcl[0].permit_level=='charger'?'charger':'viewer')
      // }
      // e.stopPropagation()
      localStorage.setItem('selectedTaskLocal',task.name)
      const oldtask=localStorage.getItem('selectedTaskLocal')
      this.showTaskEdit=true
      this.switchMenuMask()
      eventBus.$on('closeContexMenu', () => {
        this.showTaskEdit=false
      })
    },

    //根据选择不同的项目，在日历呈现不同的任务
    selectAllProject(){
      this.selectTasks=this.tasklistall
    },

    selectProjectTasks(idfp){
      this.selectTasks=this.tasklistall.filter(task=>task.project==idfp)
    },

    selectGroupTasks(group){
      this.selectTasks=this.tasklistall.filter(task=>task.group==group)
    },

    selectNoGroupTasks(proName){
      this.selectTasks=this.tasklistall.filter(task=>task.project==proName)
    },
    

    //以下是关于周日历的操作函数
    getWeekDates(date) {
      const weekDates = [];
      const currentDate = new Date(date);
      const currentDay = currentDate.getDay(); // 获取当前日期是星期几
      const startDate = new Date(currentDate); // 克隆当前日期对象
      startDate.setDate(currentDate.getDate() - currentDay); // 获取本周的起始日期（星期日）
      
      for (let i = 0; i < 7; i++) {
        const day = new Date(startDate);
        day.setDate(startDate.getDate() + i); // 获取本周的每一天日期
        weekDates.push(day);
      }
      
      return weekDates;
    },

    //以下是与任务操作任务相关的函数
    shutTaskEdit(){
      this.showTaskEdit=false
    },

    async getProjectPartersFromTask(task) {
      try {
        
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        const selectedProjectAcls = await this.getProjectAclListByIdfp(task.project);
        
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar);
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
        });
        await Promise.all(promises);
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },


    async getSelectedTaskAcls() {
      let selectedTaskAcls = [];
    
      // 构建任务关系数组
      this.selectedProjectAcls.forEach((item) => {
        const taskAcl = {
          username: item.username,
          nickname: item.nickname,
          permit_level: item.permit_level,
          project_idfp: item.project_idfp,
          avatarUrl: item.avatarUrl,
          task_idft: this.selectedTask.idft
        };
        selectedTaskAcls.push(taskAcl);
      });

    
      try {
        // 获取指定任务ID的任务关系
        const results = await this.getTaskAclsByTaskIdft(this.selectedTask.idft);
    

    
        // 根据结果更新任务关系数组
        results.forEach((result) => {
          // 查找是否存在相同的 username
          const existingAclIndex = selectedTaskAcls.findIndex((acl) => acl.username === result.username);
          if (existingAclIndex !== -1) {
            // 如果存在相同的 username，则用 result 替换该元素
            result.avatarUrl = selectedTaskAcls[existingAclIndex].avatarUrl;
            selectedTaskAcls[existingAclIndex] = result;
          }
        });
    
        this.selectedTaskAcls = selectedTaskAcls;

      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },


   //以下
   updateTaskOfSql(task){
      task.starttime=this.convertDate(task.starttime)
      task.endtime=this.convertDate(task.endtime)
      task.starttimeatl=this.convertDate(task.starttimeatl)
      task.endtimeatl=this.convertDate(task.endtimeatl)
      const token = localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: task
          })
          .then((response)=>{
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },

    updateTaskRecord(task){
      const token = localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatetaskrecord',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: task
          })
          .then((response)=>{
    
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },


    // updateTaskOfSql(task) {
    //   task.starttime=task.starttime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
    //   task.endtime=task.endtime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
    //   task.starttimeatl=new Date(task.starttimeatl).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
    //   task.endtimeatl=new Date(task.endtimeatl).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
    //   if(task.qtyotsk<=task.qtyotsked){
    //     task.done=1
    //   } else {
    //     task.done=0
    //   }
    //   console.log('MyCalendar',task)
    //   const token = localStorage.getItem('token')
    //   axios({
    //     method: 'put',
    //     url: 'https://api2.itaskid.com' + '/my/tasklist',
    //     headers: {
    //       Authorization: token,
    //       'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     data: task
    //   })
    //     .then((response) => {
    //       console.log('MyCalendar1',response)
    //     })
    //     .catch(error => {
    //       alert(error?.response?.data?.message || 'An error occurredupdate.')
    //     })
    // },

    //把标准时间转换成普通的字符串时间
    // convertDate(targetDate){
    //   const date = new Date(targetDate);
    //   const year = date.getFullYear();
    //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //   const day = date.getDate().toString().padStart(2, '0');
      
    //   const formattedDate = `${year}/${month}/${day}`;
    //   return formattedDate
      
    // },

    convertDate(targetDate) {
      if (targetDate == '') {
        targetDate = '2999/12/31 23:59:59';  // 设置默认时间为23:59:59
      }
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
    
      const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },

   updateTask(newTask){
    this.selectedTask=newTask
    for (let i = 0; i < this.selectTasks.length; i++) {
        if (this.selectTasks[i].idft === newTask.idft) {
          this.selectTasks[i] = newTask;
            break; // 如果只需替换第一个匹配的元素，可以在替换后跳出循环
        }
    }

    // this.updateTaskOfSql(newTask)
   },


    sendTaskMessage(newTaskMessage){
      newTaskMessage.sockettype='taskchat'
      this.socket.emit('message', newTaskMessage, (response) => {

        })
    },

    upDateStarttime(value){
      const starttime=this.convertDate(value)
      this.selectedTask.starttime=starttime
      this.updateTaskOfSql(this.selectedTask)
    },

    upDateEndtime(value){
      const endtime=this.convertDate(value)
      this.selectedTask.endtime=endtime
      this.updateTaskOfSql(this.selectedTask)
    },

    //以下是任务数据库操作相关的方法
    async getMyProjects() {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
    
      try {
        const response = await axios.get('https://api2.itaskid.com' + '/my/taskgroup', {
          headers: {
            Authorization: token
          },
        });
    
        const usernogroup = `${username}nogroup`;
        if (Array.isArray(response.data)) {
          this.itemsAll = response.data;
          this.myProjectList = response.data.filter(item => item.charger == username);
          this.items = this.myProjectList.filter(item => (!(item.idfp == usernogroup)));
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAllTasks() {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
      const user = { charger: username };
      try {
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: user
        });
        if (Array.isArray(response.data)) {
          console.log('获取任务列表成功！');
          return response.data
          
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },


    async getTasks() {
      const username = localStorage.getItem('username');
      const nogroup = `${username}nogroup`;
      let myTaskAcllist = [];
      let myChargeTasks=[]
    
      try {
        myTaskAcllist = await this.getMyTaskAclList(username);

    
        if (myTaskAcllist.length > 0) {
          const promises = myTaskAcllist.map(async (taskacl) => {
            const task = await this.getTaskinfoByIdft(taskacl.task_idft);
            // task.permit_level=taskacl.permit_level
            // myChargeTasks.push(task)
    
            taskacl.name = task[0].name;
            taskacl.duration = task[0].duration;
            taskacl.endtime = task[0].endtime;
            taskacl.pretask = task[0].pretask;
            taskacl.level = task[0].level;
            taskacl.parent = task[0].parent;
            taskacl.project = task[0].project;
            taskacl.haschild = task[0].haschild;
            taskacl.done = task[0].done;
            taskacl.expanded = task[0].expanded;
            taskacl.id = task[0].id;
            taskacl.delete = task[0].delete;
            taskacl.starttime = task[0].starttime;
            taskacl.record = task[0].record
            
          });
    
          await Promise.all(promises);
        }
    
        if (Array.isArray(myTaskAcllist)) {
          // this.tasklistall = myTaskAcllist;
          this.myTaskAcllist=myTaskAcllist
          return myTaskAcllist
          // return myChargeTasks
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async fetchTasks() {
      const username = localStorage.getItem('username');
      const nogroup = `${username}nogroup`;
      let myTaskAcllist = [];
      let myChargeProjectTasks = [];
    
      try {
        // 获取通过项目关系获取的任务
        let projectAcllist = await this.getMyProjectAclList(username);
        projectAcllist = projectAcllist.filter(item => item.permit_level === 'charger');
    
        if (projectAcllist.length > 0) {
          const projectPromises = projectAcllist.map(async (projectacl) => {
            const tasks = await this.getTasksByIdfp(projectacl.project_idfp);
    
            return tasks;
          });
    
          const projectTasks = await Promise.all(projectPromises);
          myChargeProjectTasks = projectTasks.flat();
        }
    
        // 获取通过任务关系获取的任务
        myTaskAcllist = await this.getMyTaskAclList(username);
    
        if (myTaskAcllist.length > 0) {
          const taskPromises = myTaskAcllist.map(async (taskacl) => {
            const task = await this.getTaskinfoByIdft(taskacl.task_idft);
   
            return {
              ...taskacl,
              ...task[0]
            };
          });
    
          myTaskAcllist = await Promise.all(taskPromises);
        }
    
        // 合并两种方式获取的任务
        const allTasks = [...myChargeProjectTasks, ...myTaskAcllist];
    
        // 去重，确保相同的任务只保留一条
        const uniqueTasks = allTasks.reduce((acc, current) => {
          const x = acc.find(item => item.idft === current.idft);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
    
        // 处理任务的其他逻辑
        this.myChargeProjectTasks = uniqueTasks;
        const allXTasks=this.myChargeProjectTasks
        allXTasks.sort((a, b) => new Date(a.starttime) - new Date(b.starttime));
        const projectPromises = allXTasks.map(async (task) => {

          const project = await this.getProjectinfoByIdfp(task.project);


          if(project.length>0){
            task.group = project[0].groupname;
            task.permit_level='charger'
          }else {
            const index=allXTasks.findIndex((t)=>t.idft===task.idft)
            if(index>-1){
              allXTasks.splice(index,1)
            }
          }

        });
    
        await Promise.all(projectPromises);
    
        this.myChargeProjectTasks = allXTasks.filter(item=>!item.done);

        this.selectTasks = allXTasks.filter(item=>!item.done);
        this.tasklistall = allXTasks.filter(item=>!item.done); 
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    },

    //根据idfp获取项目信息
    async getProjectinfoByIdfp(idfp) {

      const token = localStorage.getItem('token');
      const data={idfp: idfp}
      const username = localStorage.getItem('username');
    
      try {
        const response = await axios.get('https://api2.itaskid.com' + '/my/getprojectinfobyidfp', {
          headers: {
            Authorization: token
          },
          params:data
        });
        if (Array.isArray(response.data)) {
   
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error);
      }
    },  
    
    //国际标准时间转化成中国标准时间
    convertToChinaTime(dateTime) {
      const dateObj = new Date(dateTime);
      const chinaTime = new Date(dateObj.getTime() + (dateObj.getTimezoneOffset() * 60000) + (8 * 3600000));
      const hours = chinaTime.getHours().toString().padStart(2, '0');
      const minutes = chinaTime.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }
  },
};
</script>

<style lang="less" scoped>
.calendar {
  width: 100%;
  height: calc(100% - 10px);;
  padding: 10px;
  border: 1px solid #ccc;
}

.calendarOptionBtn {
  background: rgb(145, 170, 252) !important;
  color: rgb(255,255,255) !important;
}
.calendarOption {
  position: absolute;
  top: 5px;
  right: 5px;
  transform: translate(0%, -20%);

  button.month:hover {
    // border:0.5px rgb(91, 130, 251) solid;
    color: rgb(91, 130, 251);
    cursor: pointer;
  }
  button.day:hover {
    // border:0.5px rgb(91, 130, 251) solid;
    color: rgb(91, 130, 251);
    cursor: pointer;
  }
  button.week:hover {
    // border:0.5px rgb(91, 130, 251) solid;
    color: rgb(91, 130, 251);
    cursor: pointer;
  }
    button.week {
      height: 30px; 
      width:60px; 
      border: none;
      background: rgb(235, 235, 235);
      margin-right: 0.5px;
      color: rgb(168,168,168);
    }
    button.day {
      height: 30px; 
      width:60px; 
      border: none;
      background: rgb(235, 235, 235); 
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      margin-right: 0.5px;
      color: rgb(168,168,168);
    }
    button.month {
      height: 30px; 
      width:60px; 
      border: none;
      background: rgb(235, 235, 235);
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      color: rgb(168,168,168);
    }
  }

.header {
  position: relative;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  // width: 300px;
  width: 500px;
  height: 40px;
  line-height: 40px;



  .MonthMenuContainer {
    position: absolute;
    top: 45px;
    left: 0;
    width: 220px;
    height: 180px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    z-index: 999;
    // display: none;
    .months {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      grid-gap: 0;
      margin:10px 0 0 15px;
      text-align: center;
      li.blueBackground {
        background-color: rgb(71, 114, 250) !important;
        color: white;
      }
      li {
        border:none;
        width: 30px;
        height: 30px;
        justify-content: center;
        font-size: 12px;
        border-radius: 50%;
        margin: 5px 0 0 0px;
      }
      li:hover {
        background: rgb(239, 241, 246);
        cursor: default;
      }
    }

  }
  button.todaybutton {
    height: 30px; 
    width:60px; 
    border-radius: 10px;
    border: 0.5px rgb(210,210,210) solid; 
    background: none; 
    margin: 0 10px; 
  }
  button.todaybutton:hover {
    border:0.5px rgb(91, 130, 251) solid;
  }
  button:hover {
    border:0.5px rgb(91, 130, 251) solid;
    color: rgb(91, 130, 251);
  }
  .month-button {
    background: none;
    text-align: center;
    height: 30px;
    border-radius: 50%;;
    border: 0.5px rgb(210,210,210) solid;
  }
  i {
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin:0;
  }
}

.weeks {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  border: none;
  margin: 0;
  justify-content: center; /* 将内容水平居中对齐 */
  font-size: 14px;
  li {
    border: none;
    height: 30px;
    padding: 0;
    text-align: center; /* 将内容居中对齐 */
  }
}

.days::-webkit-scrollbar {
  height: 2px; /* 设置滚动条的高度 */
}
.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0;
  width:calc(100vw - 400px); 
  min-width: 500px;
  height: calc(100% - 70px);
  padding: 0;
  border-collapse: collapse;
  margin: 0px;
  .dayTasksContainer {
    // max-height: 100px;
    // overflow-x: auto;
    flex-grow: 1;
    flex-shrink: 1;
    border: 0.5px rgb(210,210,210) solid;
    position: relative;
    padding: 0 3px;
    // width: calc((100vw-350)/7);
    // max-width: calc(100vw - 350px);

    label {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 5px;
      font-size: 12px;
      border-radius: 50%;
      width:20px;
      height:20px;
      line-height: 20px;
    }
    .onlydaytaskscontainer {
      flex-shrink: 1;
      width: calc((100vw-350)/7);
      // max-height: 200px;
      overflow-y: auto; /* 当内容超出指定高度时显示垂直滚动条 */
      overflow-x: hidden; /* 隐藏水平滚动条 */
      overflow:auto;
      text-align: left;
      white-space: nowrap; /* 防止换行 */
      overflow: hidden; /* 隐藏溢出部分 */
      text-overflow: ellipsis; /* 使用省略号表示溢出部分 */
    }
    li.dayTaskContainer {
      position:relative;

      // width: calc((100vw - 490px)/7);
      // width:100px;
      justify-content: space-between;
      align-items: center;
      //以上为增加

      height: 14px;
      line-height: 14px;
      margin-top: 1px;
      width: 100%;
      font-size: 12px;
      border:none;
      background: rgb(145, 170, 252);
      border-radius: 3px;
      white-space: nowrap; /* 防止换行 */
      overflow: hidden; /* 隐藏溢出部分 */
      text-overflow: ellipsis; /* 使用省略号表示溢出部分 */
      .taskName {
        // max-width: 100px;
        width: calc((100vw - 500px)/7 - 30px);
        // width: 50px;
        min-width:50px;
        // white-space: nowrap ; /* 防止换行 */
        overflow: hidden !important; /* 隐藏溢出部分 */
        // text-overflow: ellipsis !important; /* 使用省略号表示溢出部分 */
      }
      .taskTime {
        margin: 0 3px 0 3px;
      }
      
    }
    li.dayTaskContainer:hover {
      cursor: pointer;
    }
  }
}

.weeks {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  border: none;
  margin: 0;
  li {
    border: none;
    height: 30px;
    padding:0;
  }
}

ul {
  padding-inline-start: 0px
}

li {
  display: flex;
  justify-content: left;
  align-items: center;
  border: 1px solid #ccc;
}

.el-calendar{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px) !important;
  // width: calc(100% - 300px);
  width:calc(100vw - 400px);
  min-width: 500px;
  // overflow: auto !important;
}

.el-project{
  position:absolute;
  top:0px;
  right:0px;
  float:right;
  font-size: 14px;
  margin: 0px;
  max-height: 100% !important;
  width: 250px;
  min-width: 200px;
  // overflow: auto !important;

  flex: 0 0 250px;
}

.monthCalendarContainer {
  width:calc(100vw - 400px); 
  min-width: 700px;
  height: calc(100% - 10px);
  margin:0;
}
.weekCalendarContainer {
  width:calc(100vw - 470px); 
  min-width: 500px;
  height: calc(100% - 10px);
  margin:0;
  display: flex;
  .timeRuler {
    width: 50px;
    height: calc(100vh - 180px);
    overflow: auto;

    .dayTimes {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(12, 1fr);
      font-size: smaller;
      // height: 100%;
      
    }
  }
}

.dayCalendarContainer {
  width:calc(100vw - 400px); 
  min-width: 500px;
  height: calc(100% - 40px);
  margin:0;
  display: flex;

  .timeArrayOptionBtn{
    background: rgb(145, 170, 252) !important;
    color: rgb(255,255,255) !important;
  }

  button.day {
      height: 30px; 
      width:90px; 
      border: none;
      background: rgb(235, 235, 235); 
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      margin-right: 0.5px;
      color: rgb(168,168,168);
      float:right;
    }
  button.month {
    height: 30px; 
    width:90px; 
    border: none;
    background: rgb(235, 235, 235);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    color: rgb(168,168,168);
    float:right;
  }

  button.month:hover {
    // border:0.5px rgb(91, 130, 251) solid;
    color: rgb(91, 130, 251);
    cursor: pointer;
  }
  button.day:hover {
    // border:0.5px rgb(91, 130, 251) solid;
    color: rgb(91, 130, 251);
    cursor: pointer;
  }

  .dayTaskContainer {
    border-radius: 25%;
    margin-left: 5px;
  }

  .taskcontainerdiv::-webkit-scrollbar {
      height: 8px; /* 控制滚动条的高度 */
  }

  .taskcontainerdiv::-webkit-scrollbar-thumb {
    // background: #888; /* 滚动条本身的颜色 */
    width: 200px; /* 设置滑块的最小宽度为200px */
  }


  .taskcontainerdiv {
    overflow-x: auto; /* 控制滚动条的高度 */
    height:30px;
    
  }

  .day {
    width:calc(100vw - 400px);
    height: calc(100% - 200px);
  }

  .days {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-gap: 0;
    width:calc(100vw - 400px); 
    min-width: 500px;
    height: calc(100% - 70px);
    padding: 0;
    border-collapse: collapse;
    margin: 0px;
  }
  .timeRuler {
    width: 50px;
    height: calc(100vh - 180px);
    overflow: auto;

    .dayTimes {
      grid-template-columns: repeat(1, 1fr);
      font-size: smaller;
      // height: 100%;
      
    }
  }
}

.taskedit-container{
  position:absolute;
  left: 50px;
  top: 50px;
  background: rgb(255, 255, 255);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  // z-index: 100;
}


// .menu-container1 {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   margin-top:10px;
//   padding-top:5px;
//   transform: translate(-62%, -53%);
//   background-color: rgba(250, 250, 250, 1);
//   width: calc(100% - 400px);
//   // height:320px;
//   height: calc(100% + 6px);
//   // width: calc(100% - 400px);
//   // width: 850px;
//   // height: 500px;
//   // height: 300px;
//   border: 1px solid gray;
//   z-index: 999;
//   cursor: move;

//   .menu {
//     position:relative;
//   }

//   form {
//     height: auto;
//   }



//   button {
//     border:none;
//     background: rgba(250,250,250,1);
//     height: 45px;
//     width: 50px;
//     line-height: 45px;
//     float:right;
//     margin-right:10px;
//     padding:0;
//   }

//   button:hover {
//     color: rgb(71, 114, 250);
//   }


// }



.dayLabel:hover {
  cursor: pointer; /* 添加这一行 */
}
</style>