<template>
  <div>
    <!-- //v-if="projectType==='tree'" -->
    <div class="project-treecontainer" >   
      <el-col style="width: 350px;  border-right: 1px solid rgba(200, 200, 200, 0.9);">
        <div style="height: 85px;">
          <h3 class="selected-proj-name" style="text-align: left;">{{ projectTitle }}</h3>
          <div style="text-align: left;margin-left:25px;" v-show="notCheckingTasks">
            <input type="text" class="input-add-proj" v-if="!ifShowSearchInput" 
              @keydown.enter="addTaskFromInput"
              :placeholder="placeholderStr"
              v-model="newTaskNameLv1">
            <input type="text"  class="input-add-proj" v-if="ifShowSearchInput"  placeholder="搜索任务"
              v-model="searchTaskText" @change="getSearchTasks()">
            <button style="width:40px;padding:0;" @click="showSearchInput()" class="search-button" v-if="!ifShowSearchInput">
              <i class="el-icon-search" style="line-height: 30px;"></i>
            </button>
            <button class="search-button" v-if="ifShowSearchInput" @click="cancelSearchTask()">取消</button>
          </div>
        </div>
        <!-- //style="height: calc(100vh - 180px);" -->
        <div class="alltasks-container" v-if="ifShowSearchInput">
          <div class="project-container-leftcol">
            <div style="text-align:left;">
              <button style="border: none; background: none;height: 42px;" @click=showUndoneTaskToggle()>
                <i class="el-icon-arrow-right" v-show="!showUndoneProject"></i>
                <i class="el-icon-arrow-down" v-show="showUndoneProject"></i>
              </button>
              <label for="" style="padding-left: 0;height: 42px;">未完成</label>
            </div>     
            <EditTaskMenu
              v-if="showTaskEdit" 
              ref="edittaskmenu"
              :selectedTask="selectedTask" :usernogroup="usernogroup" :selectedTaskAcls="selectedTaskAcls"
              @cancelEditTask="cancelEditTask" :TaskeditMenuitems="TaskeditMenuitems" @updateTaskAcl="updateTaskAcl"
              @closeEditTask="closeEditTask" :parentNode="parentNode" @deleteTask="deleteTask()"
              @taskeditMenuitemClick="taskeditMenuitemClicked">
            </EditTaskMenu>
            <ul v-for="task1 in searchTasks" :key="task1.idft" ref="task-ul" v-show="showUndoneProject"
              @click="goToTaskDetail($event,task1)" class="ul-task1" 
              @contextmenu.prevent="taskEditMenu($event,task1)" >  
              <div class="task-container" style="position: relative;" v-if="ifShowTask(task1)">
                <button v-show="hasNoDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasNoDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasNoDoneChild(task1)">
                </button>
                <!-- <i class="el-icon-view" v-if="!permitLevel(task1)" style="position:absolute;
                  font-size:13px; left: 5px;top:-5px;"></i> -->
                <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task1)" :disabled="!permitLevel(task1)">
                <input type="text" class="input-taskname" :class="{ taskinputclicked: isInputClicked(task1.idft) }"
                  @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)" v-model="task1.name"  
                  :readonly="!permitLevel(task1)">
                <label for="date-picker" class="task-starttime" v-show="showTaskTime(task1)" @click="updateTaskdate(task1)">
                  {{ showTime(task1.starttime) }}
                </label>
                <el-date-picker
                  v-model="newStarttimeFromDatePicker"
                  type="datetime"
                  size="small"
                  popper-class="dateselect"
                  class="my-date-picker"
                  id="date-picker" style="width: 0px; height: 0px; overflow: hidden; background: transparent;">  
                </el-date-picker>
              </div>
            </ul>
            <ShareTaskMenu v-if="showShareTaskMenu"></ShareTaskMenu>
          </div>
          <div class="projectdone-container">
            <div>
              <button style="border: none; background: none;height: 42px;" @click=showDoneTaskToggle()>
                <i class="el-icon-arrow-right" v-show="!showDoneProject"></i>
                <i class="el-icon-arrow-down" v-show="showDoneProject"></i>
              </button>
              <label for="" style="padding-left: 0;height: 42px;">已完成</label>
            </div>
            <div class="project-container-leftcol" v-show="showDoneProject">
            <ul v-for="task1 in searchTasksDone" :key="task1.idft" ref="task-ul" 
              @click="goToTaskDetail($event,task1)" class="ul-task1" 
              @contextmenu.prevent="taskEditMenu($event,task1)">  
              <div class="task-container" v-if="ifShowTask(task1)">
                <button v-show="hasDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasDoneChild(task1)">
                </button>
                <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task1)" v-model="task1.done" 
                  :disabled="!permitLevel(task1)">
                <input type="text" class="input-taskname" v-model="task1.name"  readonly
                  @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)">
                <label for="" class="task-starttime" v-show="showTaskTime(task1)" @click="updateTaskdate(task1)">
                  {{ showTime(task1.starttime) }}
                </label>
                <el-date-picker
                  v-model="newStarttimeFromDatePicker"
                  type="datetime"
                  size="small"
                  popper-class="dateselect"
                  class="my-date-picker"
                  id="date-picker" style="width: 0px; height: 0px; overflow: hidden; background: transparent;">  
                </el-date-picker>
              </div>
            </ul>
            </div>
          </div>
          
        </div>
        <div class="alltasks-container" v-if="!ifShowSearchInput">
          <div class="project-container-leftcol">     
            <EditTaskMenu
              v-if="showTaskEdit" 
              ref="edittaskmenu"
              :selectedTask="selectedTask" :usernogroup="usernogroup" :selectedTaskAcls="selectedTaskAcls"
              @cancelEditTask="cancelEditTask" :TaskeditMenuitems="TaskeditMenuitems" @updateTaskAcl="updateTaskAcl"
              @closeEditTask="closeEditTask" :parentNode="parentNode" @deleteTask="deleteTask()"
              @taskeditMenuitemClick="taskeditMenuitemClicked">
            </EditTaskMenu>
            <SummitTaskMenu v-if="showSummitTask" ref="summitTaskMenu" :selectedTask="selectedTask" @closeSummitTaskMenu=closeSummitTaskMenu
              :selectedProjectAcls="selectedProjectAcls" @addLocalTaskSummitMsg="addLocalTaskSummitMsg" @addLocalTaskFileData="addLocalTaskFileData">

            </SummitTaskMenu>
            <ul v-for="task1 in tasklist1" :key="task1.idft" ref="task-ul" 
              @click="goToTaskDetail($event,task1)" class="ul-task1" 
              @contextmenu.prevent="taskEditMenu($event,task1)" >  
              <div class="task-container" style="position: relative;" v-if="ifShowTask(task1)">
                <button v-show="hasNoDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)" style="margin-right:3px;">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasNoDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasNoDoneChild(task1)">
                </button>
                <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task1)" :disabled="!permitLevel(task1)">
                <input type="text" class="input-taskname" :class="{ taskinputclicked: isInputClicked(task1.idft) }"
                  @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)" v-model="task1.name"  
                  :readonly="!permitLevel(task1)">
                <label for="date-picker" class="task-starttime" v-show="showTaskTime(task1)" @click="updateTaskdate(task1)">
                  {{ showTime(task1.starttime) }}
                </label>
                <el-date-picker
                  v-model="newStarttimeFromDatePicker"
                  type="datetime"
                  size="small"
                  popper-class="dateselect"
                  class="my-date-picker"
                  id="date-picker" style="width: 0px; height: 0px; overflow: hidden; background: transparent;">  
                </el-date-picker>
              </div>
              <li  v-for="task2 in taskListWithLevel(2,task1)" :key="task2.idft" ref="task-li" 
                v-show="task1.expanded" @click="goToTaskDetail($event,task2)"
                @contextmenu.prevent="taskEditMenu($event,task2)" style="padding-left: 10px;">
                <div  class="task-container" v-if="ifShowTask(task2)">
                  <button v-show="hasNoDoneChild(task2)&&!task2.expanded" @click="toggleNode($event,task2)">
                    <i class="el-icon-arrow-right"></i>
                  </button>
                  <button v-show="hasNoDoneChild(task2)&&task2.expanded" @click="toggleNode($event,task2)">
                    <i class="el-icon-arrow-down"></i>
                  </button>
                  <button  v-show="!hasNoDoneChild(task2)">
                  </button>
                  <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task2)" :disabled="!permitLevel(task2)">
                  <input type="text" class="input-taskname" v-model="task2.name"  :readonly="!permitLevel(task2)" @change="updateTaskOfSql(task2)"
                    @keyup.enter="addBroTask($event,task2)"  :class="{ taskinputclicked: isInputClicked(task2.idft) }"> 
                  <label for="" class="task-starttime" v-show="showTaskTime(task2)">
                    {{ showTime(task2.starttime) }}
                  </label>
                </div>
                <ul v-for="task3 in taskListWithLevel(3,task2)" :key="task3.idft"  
                  ref="task-ul" v-show="task2.expanded" @click="goToTaskDetail($event,task3)"
                  @contextmenu.prevent="taskEditMenu($event,task3)" style="padding: 0 0 0 10px;">
                  <div  class="task-container" v-if="ifShowTask(task3)">
                    <button v-show="hasNoDoneChild(task3)&&!task3.expanded"  @click="toggleNode($event,task3)">
                      <i class="el-icon-arrow-right"></i>
                    </button>
                    <button v-show="hasNoDoneChild(task3)&&task3.expanded"  @click="toggleNode($event,task3)">
                      <i class="el-icon-arrow-down"></i>
                    </button>
                    <button  v-show="!hasNoDoneChild(task3)">
                    </button>
                    <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task3)" :disabled="!permitLevel(task3)">
                    <input type="text" class="input-taskname" v-model="task3.name" :readonly="!permitLevel(task3)"
                    @keyup.enter="addBroTask($event,task3)" @change="updateTaskOfSql(task3)"
                    :class="{ taskinputclicked: isInputClicked(task3.idft) }">
                    <label for="" class="task-starttime" v-show="showTaskTime(task3)">
                      {{ showTime(task3.starttime) }}
                    </label>
                  </div>
                  <li v-for="task4 in taskListWithLevel(4,task3)" :key="task4.idft"  
                    ref="task-li" v-show="task3.expanded" @click="goToTaskDetail($event,task4)"
                    @contextmenu.prevent="taskEditMenu($event,task4)" style="padding: 0 0 0 10px">
                    <div  class="task-container" v-if="ifShowTask(task4)">
                      <button v-show="hasNoDoneChild(task4)&&!task4.expanded" @click="toggleNode($event,task4)">
                        <i class="el-icon-arrow-right"></i>
                      </button>
                      <button v-show="hasNoDoneChild(task4)&&task4.expanded" @click="toggleNode($event,task4)">
                        <i class="el-icon-arrow-down"></i>
                      </button>
                      <button  v-show="!hasNoDoneChild(task4)">
                      </button>
                      <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task4)" :disabled="!permitLevel(task4)">
                      <input type="text" class="input-taskname" v-model="task4.name" :readonly="!permitLevel(task4)" 
                      :class="{ taskinputclicked: isInputClicked(task4.idft) }"
                      @keyup.enter="addBroTask($event,task4)" @change="updateTaskOfSql(task4)">
                      <label for="" class="task-starttime" v-show="showTaskTime(task4)">
                        {{ showTime(task4.starttime) }}
                      </label>
                    </div>
                    <ul v-for="task5 in taskListWithLevel(5,task4)" :key="task5.idft"  
                      ref="task-ul" v-show="task4.expanded" @click="goToTaskDetail($event,task5)"
                      @contextmenu.prevent="taskEditMenu($event,task5)"  style="padding: 0 0 0 10px">
                      <div  class="task-container" v-if="ifShowTask(task5)">
                        <button v-show="hasNoDoneChild(task5)&&!task5.expanded" @click="toggleNode($event,task5)">
                          <i class="el-icon-arrow-right"></i>
                        </button>
                        <button v-show="hasNoDoneChild(task5)&&task5.expanded" @click="toggleNode($event,task5)">
                          <i class="el-icon-arrow-down"></i>
                        </button>
                        <button  v-show="!hasNoDoneChild(task5)">
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                        <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task5)" :disabled="!permitLevel(task5)">
                        <input type="text" class="input-taskname" v-model="task5.name" :readonly="!permitLevel(task5)"
                          :class="{ taskinputclicked: isInputClicked(task5.idft) }"
                          @keyup.enter="addBroTask($event,task5)" @change="updateTaskOfSql(task5)" >
                        <label for="" class="task-starttime" v-show="showTaskTime(task5)">
                          {{ showTime(task5.starttime) }}
                        </label>
                      </div>
                      <li v-for="task6 in taskListWithLevel(6,task5)" :key="task6.idft" 
                        v-show="task5.expanded" @click="goToTaskDetail($event,task6)"
                        @contextmenu.prevent="taskEditMenu($event,task6)"  style="padding: 0 0 0 10px">
                        <div  class="task-container" v-if="ifShowTask(task6)">
                          <input type="checkbox" class="task-done" style="margin-left: 26px" @change="toggleTaskDone($event,task6)" :disabled="!permitLevel(task6)">
                          <input type="text" class="input-taskname" v-model="task6.name" :readonly="!permitLevel(task6)" 
                          :class="{ taskinputclicked: isInputClicked(task6.idft) }"
                            @keyup.enter.prevent="addBroTask($event,task6)" @change="updateTaskOfSql(task6)" >
                          <label for="" class="task-starttime" v-show="showTaskTime(task6)">
                            {{ showTime(task6.starttime)}}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </li>  
                </ul>
              </li>
            </ul>
            <ShareTaskMenu v-if="showShareTaskMenu"></ShareTaskMenu>
          </div>
          <div class="projectdone-container" v-if="showTaskDoneContainer">
            <div>
              <button style="border: none; background: none;height: 42px;" @click=showDoneTaskToggle()>
                <i class="el-icon-arrow-right" v-show="!showDoneProject"></i>
                <i class="el-icon-arrow-down" v-show="showDoneProject"></i>
              </button>
              <label for="" style="padding-left: 0;height: 42px;">已完成</label>
            </div>
            <div class="project-container-leftcol" v-show="showDoneProject">
            <ul v-for="task1 in tasklistDone1" :key="task1.idft" ref="task-ul" 
              @click="goToTaskDetail($event,task1)" class="ul-task1" 
              @contextmenu.prevent="taskEditMenu($event,task1)">  
            <div class="task-container" v-if="ifShowTask(task1)">
              <button v-show="hasDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)">
                <i class="el-icon-arrow-right"></i>
              </button>
              <button v-show="hasDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                <i class="el-icon-arrow-down"></i>
              </button>
              <button  v-show="!hasDoneChild(task1)">
              </button>
              <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task1)" v-model="task1.done" 
                :disabled="!permitLevel(task1)">
              <input type="text" class="input-taskname" v-model="task1.name"  readonly
                @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)">
              <label for="" class="task-starttime" v-show="showTaskTime(task1)" @click="updateTaskdate(task1)">
                {{ showTime(task1.starttime) }}
              </label>
              <el-date-picker
                v-model="newStarttimeFromDatePicker"
                type="datetime"
                size="small"
                popper-class="dateselect"
                class="my-date-picker"
                id="date-picker" style="width: 0px; height: 0px; overflow: hidden; background: transparent;">  
              </el-date-picker>
            </div>
              <li  v-for="task2 in taskListDoneWithLevel(task1)" :key="task2.idft" ref="task-li" 
                v-show="task1.expanded" @click="goToTaskDetail($event,task2)"
                @contextmenu.prevent="taskEditMenu($event,task2)" style="padding-left: 10px;">
                <div  class="task-container" v-if="ifShowTask(task2)">
                  <button v-show="hasDoneChild(task2)&&!task2.expanded" @click="toggleNode($event,task2)">
                    <i class="el-icon-arrow-right"></i>
                  </button>
                  <button v-show="hasDoneChild(task2)&&task2.expanded" @click="toggleNode($event,task2)">
                    <i class="el-icon-arrow-down"></i>
                  </button>
                  <button  v-show="!hasDoneChild(task2)">
                  </button>
                  <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task2)" v-model="task2.done" :disabled="!permitLevel(task2)">
                  <input type="text" class="input-taskname" v-model="task2.name"  readonly
                  @keyup.enter="addBroTask($event,task2)" @change="updateTaskOfSql(task2)"> 
                  <label for="" class="task-starttime" v-show="showTaskTime(task2)">
                    {{ showTime(task2.starttime) }}
                  </label>
                </div>
                <ul v-for="task3 in taskListDoneWithLevel(task2)" :key="task3.idft"  
                  ref="task-ul" v-show="task2.expanded" @click="goToTaskDetail($event,task3)"
                  @contextmenu.prevent="taskEditMenu($event,task3)" style="padding: 0 0 0 10px;">
                  <div  class="task-container" v-if="ifShowTask(task3)">
                    <button v-show="hasDoneChild(task3)&&!task3.expanded"  @click="toggleNode($event,task3)">
                      <i class="el-icon-arrow-right"></i>
                    </button>
                    <button v-show="hasDoneChild(task3)&&task3.expanded"  @click="toggleNode($event,task3)">
                      <i class="el-icon-arrow-down"></i>
                    </button>
                    <button  v-show="!hasDoneChild(task3)">
                    </button>
                    <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task3)" v-model="task3.done" :disabled="!permitLevel(task3)">
                    <input type="text" class="input-taskname" v-model="task3.name" readonly
                    @keyup.enter="addBroTask($event,task3)" @change="updateTaskOfSql(task3)">
                    <label for="" class="task-starttime" v-show="showTaskTime(task3)">
                      {{ showTime(task3.starttime) }}
                    </label>
                  </div>
                  <li v-for="task4 in taskListDoneWithLevel(task3)" :key="task4.idft"  
                    ref="task-li" v-show="task3.expanded" @click="goToTaskDetail($event,task4)"
                    @contextmenu.prevent="taskEditMenu($event,task4)" style="padding: 0 0 0 10px">
                    <div  class="task-container" v-if="ifShowTask(task4)">
                      <button v-show="hasDoneChild(task4)&&!task4.expanded" @click="toggleNode($event,task4)">
                        <i class="el-icon-arrow-right"></i>
                      </button>
                      <button v-show="hasDoneChild(task4)&&task4.expanded" @click="toggleNode($event,task4)">
                        <i class="el-icon-arrow-down"></i>
                      </button>
                      <button  v-show="!hasDoneChild(task4)">
                      </button>
                      <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task4)" v-model="task4.done" :disabled="!permitLevel(task4)">
                      <input type="text" class="input-taskname" v-model="task4.name" readonly
                      @keyup.enter="addBroTask($event,task4)" @change="updateTaskOfSql(task4)">
                      <label for="" class="task-starttime" v-show="showTaskTime(task4)">
                        {{ showTime(task4.starttime) }}
                      </label>
                    </div>
                    <ul v-for="task5 in taskListDoneWithLevel(task4)" :key="task5.idft"  
                      ref="task-ul" v-show="task4.expanded" @click="goToTaskDetail($event,task5)"
                      @contextmenu.prevent="taskEditMenu($event,task5)"  style="padding: 0 0 0 10px">
                      <div  class="task-container" v-if="ifShowTask(task5)">
                        <button v-show="hasDoneChild(task5)&&!task5.expanded" @click="toggleNode($event,task5)">
                          <i class="el-icon-arrow-right"></i>
                        </button>
                        <button v-show="hasDoneChild(task5)&&task5.expanded" @click="toggleNode($event,task5)">
                          <i class="el-icon-arrow-down"></i>
                        </button>
                        <button  v-show="!hasDoneChild(task5)">
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                        <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task5)" v-model="task5.done" :disabled="!permitLevel(task5)">
                        <input type="text" class="input-taskname" v-model="task5.name" readonly
                        @keyup.enter="addBroTask($event,task5)" @change="updateTaskOfSql(task5)" >
                        <label for="" class="task-starttime" v-show="showTaskTime(task5)">
                          {{ showTime(task5.starttime) }}
                        </label>
                      </div>
                      <li v-for="task6 in taskListDoneWithLevel(task5)" :key="task6.idft" 
                        v-show="task5.expanded" @click="goToTaskDetail($event,task6)"
                        @contextmenu.prevent="taskEditMenu($event,task6)"  style="padding: 0 0 0 10px">
                        <div  class="task-container" v-if="ifShowTask(task6)">
                          <input type="checkbox" class="task-done" style="margin-left: 26px" @change="toggleTaskUndone($event,task6)"
                            v-model="task6.done" :disabled="!permitLevel(task6)">
                          <input type="text" class="input-taskname" v-model="task6.name" readonly
                            @keyup.enter.prevent="addBroTask($event,task5)" @change="updateTaskOfSql(task6)" >
                          <label for="" class="task-starttime" v-show="showTaskTime(task6)">
                            {{ showTime(task6.starttime)}}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </li>  
                </ul>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </el-col>
      <el-col style="width: calc(100% - 355px); height: calc(100% - 5px)">
        <router-view 
          @upDateStarttime="upDateStarttime" :selectedTask="selectedTask" @updateTaskDone="updateTaskDone" :selectedTaskFiles=selectedTaskFiles
          :taskContents="taskContents"  :selectedProjectAcls="selectedProjectAcls"  :selectedTaskResPlans="selectedTaskResPlans"
          @sendTaskMessage="sendTaskMessage" @upDateEndtime="upDateEndtime" @updateTaskOfSql="updateTaskOfSql" :altNikname="altNikname"
          @addLocalTaskFileData=addLocalTaskFileData @deleteLocalTaskFile="deleteLocalTaskFile" :isTaskCharger="isTaskCharger"
          :taskProcessMsgs="taskProcessMsgs">
        </router-view>
      </el-col>
    </div>
    <div v-if="projectType==='table'">
      xixi
    </div>
    <div v-if="projectType==='graphic'">
      xixi
    </div>
  </div>

 
</template>

<script>
import axios from 'axios'
import { mapState,mapMutations,mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import EditTaskMenu from '../menu/task/EditTaskMenu.vue'
import ShareTaskMenu from '../menu/task/ShareTaskMenu.vue'
import SummitTaskMenu from '../menu/task/SummitTaskMenu.vue'

import { eventBus } from '../../main.js'
import dayjs from 'dayjs'
import { nextTick } from 'vue'
import io from 'socket.io-client'
export default {
  components: {
    EditTaskMenu,
    ShareTaskMenu,
    SummitTaskMenu
  },
  name: 'TaskProject',
  props: ['idfp','name','selectedProjectRoute','selectedProject','placeholderStr','projectTitle','myProjectAcls','projectType',
           'tasksWithIdfp','myProjectIdfpArray','myProjects','clickedIdfp','allMyCheckingTasks'],
  data(){
    return {
      notCheckingTasks:true,
      ifShowSearchInput:false,
      searchTaskText:'',
      searchTasksDone:[],
      searchTasks:[],

      altNikname:'',

      date:'',
      aha:["yuennogroup","a82d4ec6-f6cc-4ea7-af41-b086810f3d5b","950c9ffe-391e-44c4-8bfa-058a35520bba"],

      //共同数据

      tasklistall:[],

      //tasktree数据
      newTaskNameLv1:'',
      tasklist:[],
      objTest:'',
      hisObj:'',
      nogroupTasklist:[],

      //以下是关于任务编辑右键菜单的数据
      showTaskEdit: false,
      parentNode:null,
      selectedTask:{},

      showTaskDateEdit:false,
      newStarttimeFromDatePicker:'',

      //以下是任务详情内容有关的数据
      taskContents:[],

      //以下是已完成任务相关数据
      showDoneProject: true,
      showUndoneProject:true,
      tasklistDone:[],

      testdoneTasks: [
        { idft: 10, name: 'ms2', level: 1, parent: 'roottask' },
        { idft: 11, name: 'ms3', level: 3, parent: 10 },
        { idft: 12, name: 'ms4', level: 4, parent: 11 },
        { idft: 13, name: 'mshao', level: 5, parent: 1 },
      ],

      //以下是任务权限、通过任务权限获取任务信息相关的函数
      myTasklistFromSql:[],

      //被点击的input
      clickedInputIdft:'',

      taskMessages:[],
      socket: null,
      username:'',

      tasklistFromProject:[],
      myTaskAcllist:[],

      //共享任务的数据
      showShareTaskMenu:false,
      usernogroup:'',
      selectedProjectAcls:[],
      TaskeditMenuitems:[
        { id:1,label: '添加子任务' }, 
        {id:2,label:'指派给'},
        // { id:3,label: '置顶' },
        { id:3,label: '提交确认' },
        { id:4,label: '移动到' },
        // { id:5,label: '标签' }, 
        // { id:6,label: '复制链接' }, 
        // { id:7,label: '创建副本' },
        { id:8,label: '删除' }
      ],
      selectedTaskAcls:[],


      //与任务资源计划相关的任务
      selectedTaskResPlans:[],


      //提交和确认任务的数据
      showSummitTask:false,
      summitingTask:null,
      selectedTaskFiles:[],
      isTaskCharger:false,

      //这个是任务审批相关的记录数据
      taskProcessMsgs:[],

      
      
      

    }
  },
  
  computed: {

    //计算我对一个项目的权限是什么
    // permitLevel(){
    //   return (task)=>{
    //     if(this.selectedProject.permit_level=='charger') {
    //       return true
    //     } else {
    //       const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)
    //       const isCharger=(taskAcl.length==0)?false:(taskAcl[0].permit_level=='charger'?true:false)
    //       return isCharger
    //     }
    //   }
    // },

    permitLevel(){
      return (task)=>{
        const taskAcl=this.myTaskAcllist.find((item)=>{
          if(item.task_idft==task.idft){
            return item
          }
        })
        if(task.permit_level=='charger'||(taskAcl&&taskAcl.permit_level=='charger')) {
          return true
        } else {
          return false
        }
      }
    },

    ifShowTask() {
      return (task)=>{
        const taskAcl=this.myTaskAcllist.find((item)=>{
          if(item.task_idft==task.idft){
            return item
          }
        })
        const isCharger=(taskAcl&&(taskAcl.permit_level=='viewer'||taskAcl.permit_level=='charger'))?true:false
        if(task.permit_level=='parter'&&!isCharger) {
          return false
        } else {
          return true
        }
      }
    },

    taskListWithLevel() {
      const id=this.$route.params.idfp
      if(id=='today'||id=='week'){
        return (level,parentTask) => this.tasklist.filter(task => task.parent===parentTask.idft&&task.done===0)
      }else {
        return (level,parentTask) => this.tasklist.filter(task => task.parent===parentTask.idft&&task.level==level&&task.done===0)
      }
    },
    taskListDoneWithLevel(){
      return (parentTask) => this.tasklistDone.filter(task => task.parent===parentTask.idft)
    },
    hasNoDoneChild:function() {
      return function(task) {
        const noDoneChilds = this.tasklist.filter(i => i.parent === task.idft && i.done === 0);
        if (noDoneChilds.length < 1) {
          return 0;
        } else {
          return 1;
        }
      }
    },

    showTaskDoneContainer:function(){
      if(this.tasklistDone.length>0) {
        return 1
      }else {
        return 0
      }
    },
    hasDoneChild:function() {
      return function(task) {
        const doneChilds = this.tasklistDone.filter(i => i.parent === task.idft && i.done === 1);
        if (doneChilds.length < 1) {
          return 0;
        } else {
          return 1;
        }
      }
    },
    ...mapState({
      selectProjName: 'selectProjName',
      selectProjGroup: 'selectProjGroup',
      selectProjIdfp: 'selectProjIdfp',
      user:'user'
    }),


     //以下是通过任务权限获取任务，再进行分级计算
    tasklist1: function(){
      const id=this.$route.params.idfp
      if(id=='today'){
        const today=new Date()
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > today)))
        return tasklist01
      }else if(id=='week') {
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6)
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > oneWeekLater&&i.done===0)))
        return tasklist01
      }else if(id=='checking'){

        return this.allMyCheckingTasks
      } else {
        // let tasklist01=this.tasklistFromProject.filter(

        let tasklist01 = this.tasklist.filter(
        function(task) {
          return task.level === 1&&task.done==0
        })
        return tasklist01
      }

    },
    //以上是通过任务权限获取任务，再进行分级计算

    tasklistDone1: function(){
      const doneTasks=this.tasklistDone
      const doneTasks1=[]
      for(var i=0; i<doneTasks.length; i++){
        if(doneTasks[i].level===1){
          doneTasks1.push(doneTasks[i])
        }else {
          const parentTask=doneTasks.find(task=>task.idft===doneTasks[i].parent)
          if(!parentTask) {
            doneTasks1.push(doneTasks[i])
          }
        }
      }
      return doneTasks1
    },

    showTime(){
      return (dateTime)=>{
        const today=new Date()
        const thisYear=today.getFullYear()
        const taskDate=new Date(dateTime)
        const date = new Date(dateTime);
        const year = date.getFullYear();
        var taskYear=taskDate.getFullYear()
        var taskMonth=taskDate.getMonth()+1
        var taskDay=taskDate.getDate()
        const todayOfStr=this.convertDate(new Date())
        var taskDateOfStr=this.convertDate(dateTime)
        var lastDate
        if(todayOfStr==taskDateOfStr){
          lastDate='今天'
        } else if(taskYear==thisYear){
          lastDate=`${taskMonth}月${taskDay}日`
        }else if(year=='2999'){
          lastDate=''
        }else {
          lastDate=`${taskYear}-${taskMonth}-${taskDay}`
        }
        return lastDate
      }
    },

    showTaskTime(){
      return (task)=>{
        
        const today=new Date()
        const thisYear=today.getFullYear()
        const taskDate=new Date(task.starttime)
        var taskYear=taskDate.getFullYear()
        var taskMonth=taskDate.getMonth()+1
        var taskDay=taskDate.getDate()
        const todayOfStr=this.convertDate(new Date())
        var taskDateOfStr=this.convertDate(task.starttime)
        var lastDate
        if(todayOfStr==taskDateOfStr){
          lastDate=true
        } else if(taskYear==thisYear){
          lastDate=true
        }else if(taskDateOfStr=='2999/12/31'){
          lastDate=false
        }else {
          lastDate=true
        }
        return lastDate
      }
    },

    taskList(){
      return this.tasklist
    },

    //判断input是否被点击
    isInputClicked(){
      return (idft)=>{
        if(this.clickedInputIdft==idft) {
          return true
        } else {
          return false
        }
      }
    },


  },

  created() {
    this.username=localStorage.getItem('username')


    const username=localStorage.getItem('username')
    const key=`user|${username}`
    const userinfoStr=localStorage.getItem(key)
    const userinfo=JSON.parse(userinfoStr)
    const nickname = userinfo.nickname;
    const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
    this.altNikname = matchResult ? matchResult[0] : '';

    


  },
  mounted(){



    // this.runAndMergeArrays()




    // this.runAndMergeArraysOfToday()
    this.getAllProjectTasks()





 


    //输出化任务数据为今天的任务
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    










    const username=localStorage.getItem('username')
    this.getMyTaskAclList(username)
      .then((result)=>{
        this.myTaskAcllist=result
      })
    
    
    
      // this.$refs.addProjInput.placeholder='添加"今天"的任务至"未分类"'
    this.newStarttimeFromDatePicker=new Date()


  
    // this.getTasks(this.selectedProjectRoute)


    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
      
    });

    
    
    // 监听服务器发送的消息
    this.socket.on('message', (message) => {
      if(message.receiver==this.selectedTask.idft){
        this.taskContents.push(message)
      }

      if(message.type=='updateTask'){

        // console.log('message',message)



        // const index = this.tasklistall.findIndex(item => item.idft === message.idft);  
        // if (index !== -1) {  
        //   // 直接更新属性  
        //   this.tasklistall[index] = { ...this.tasklistall[index], ...message }; // 创建新对象并替换  
        // } 

        // this.tasklistall.find((item)=>{
        //   if(item.idft==message.idft){
        //     console.log('item0916',item)
        //   }
        // })

        // const index1 = this.tasklist.findIndex(item => item.idft === message.idft);  
        // if (index !== -1) {  
        //   // 直接更新属性  
        //   this.tasklist[index] = { ...this.tasklist[index1], ...message }; // 创建新对象并替换  
        // }



        // 找到对应的任务并更新其属性  
        // const taskToUpdate = this.tasklistall.find(item => item.idft === message.idft);  
        // if (taskToUpdate) {  
        
        //   Object.assign(taskToUpdate, message); // 使用 Object.assign 更新属性  
        // }

        

        // const taskToUpdate1 = this.tasklist.find(item => item.idft === message.idft);  
        // if (taskToUpdate1) {  
 
        //   Object.assign(taskToUpdate1, message); // 使用 Object.assign 更新属性  
        // }
  
        
      }






    });   
    

  },



  watch:{
    '$route.params.idfp':function(){
      const selectedProj=localStorage.getItem('selectedProjectRouteLocal')
      const id=this.$route.params.idfp
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`

      if(id=="today"){
        this.notCheckingTasks=true
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
        this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
        this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));

        const tasklist1=this.tasklist1Ofmethod()
   
      }else if(id=="week"){
        this.notCheckingTasks=true
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
        this.tasklist = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 0);
        });
        
        this.tasklistDone = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 1);
        });

        this.nogroupTasklist = this.tasklistall.filter(task => {
          return (task.project === nogroup) && (task.done === 0);
        });
      }else if(id=="inbox"){
        this.notCheckingTasks=true
        this.tasklist=this.tasklistall.filter(task=>task.project==nogroup&&task.done===0)
        this.tasklistDone=this.tasklistall.filter(task=>task.project==nogroup&&task.done===1)
      } else if(id=='checking'){
        this.notCheckingTasks=false
      } else {
        this.notCheckingTasks=true
        this.tasklist=this.tasklistall.filter(task=>task.project==id&&task.done===0)    
        this.tasklistDone=this.tasklistall.filter(task=>task.project==id&&task.done===1)
      }
      

    },

    myProjects: function(){
      
    },
    
  },


  
  methods: {
    //与任务提交相关的函数
    closeSummitTaskMenu(){
      this.showSummitTask=false
    },

    addLocalTaskSummitMsg(msg){
      this.taskProcessMsgs.push(msg)
    },

    addLocalTaskFileData(file){
      this.selectedTaskFiles.push(file)
    },

    // addLocalTaskFileData(newTaskFileData){
    //   this.selectedTaskFiles.unshift(newTaskFileData)
    // },

    deleteLocalTaskFile(file){
      this.selectedTaskFiles=this.selectedTaskFiles.filter(item=>item.idftf!==file.idftf)
    },


    getPermitLevel(task){
        const taskAcl=this.myTaskAcllist.find((item)=>{
          if(item.task_idft==task.idft){
            return item
          }
        })
        if(task.permit_level=='charger'||(taskAcl&&taskAcl.permit_level=='charger')) {
          return true
        } else {
          return false
        }
    },

    showSearchInput(){
      this.ifShowSearchInput=true
    },
    cancelSearchTask(){
      this.searchTaskText=''
      this.searchTasks=[]
      this.searchTasksDone=[]
      this.ifShowSearchInput=false
    },

    getSearchTasks(){
      const searchTasksAll=this.tasklistall.filter(task=>task.name.includes(this.searchTaskText))
      this.searchTasks=searchTasksAll.filter(task=>task.done==0)
      this.searchTasksDone=searchTasksAll.filter(task=>task.done==1)
    },

    tasklist1Ofmethod(){
      const id=this.$route.params.idfp
      if(id=='today'){
        const today=new Date()
        // let tasklist01=this.selectedTasks.filter(item=>item.parent=='roottask'||
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > today)))
        return tasklist01
      }else if(id=='week') {
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6)
        // let tasklist01=this.selectedTasks.filter(item=>item.parent=='roottask'||
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > oneWeekLater&&i.done===0)))
        return tasklist01
      }else {
        // let tasklist01 = this.selectedTasks.filter(
          let tasklist01 = this.tasklist.filter(
        function(task) {
          return task.level === 1&&task.done==0
        })
        return tasklist01
      }

    },

    //合并两个数组的函数
    mergeArrays(A, B) {
      if (!A) {
        A = [];
      }
      if (!B) {
        B = [];
      }

      const mapA = new Map();
      A.forEach(item => {
        mapA.set(item.idft, item);
      });
    
      const result = A.slice();
    
      B.forEach(item => {
        if (!mapA.has(item.idft)) {
          result.push(item);
        }
      });
    
      return result;
    },


    testsdf(task){

      const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)

      const isCharger=taskAcl.length==0?'viewer':(taskAcl[0].permit_level=='charger'?'charger':'viewer')


    },
    ...mapActions({
      addTaskToSql:'addTaskToSql',
      getMyTaskAclList:'getMyTaskAclList',
      getTaskinfoByIdft: 'getTaskinfoByIdft',
      getUserInfoBySearchText:'getUserInfoBySearchText',
      showPic:'showPic',
      getTasksByIdfp:'getTasksByIdfp',
      getTasksByIdfpFromTasklist:'getTasksByIdfpFromTasklist',
      getTasksByPojectAcl:'getTasksByPojectAcl',
      showTimePub:'showTimePub',
      addTaskAclToSql:'addTaskAclToSql',
      getMyProjectAclList:'getMyProjectAclList',
      deleteTaskAclbyIdft:'deleteTaskAclbyIdft',
      getTaskAclsByTaskIdft:'getTaskAclsByTaskIdft',
      updateTaskAclByUsernameAndIdft:'updateTaskAclByUsernameAndIdft',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
      getMsgsByReceiver:'getMsgsByReceiver',
      getTaskMsgsByReceiver:'getTaskMsgsByReceiver',
      updateTaskOrderidOfSql:'updateTaskOrderidOfSql',
      getTasksByTaskProjects:'getTasksByTaskProjects',
      // getResPlanlistByTask:'getResPlanlistByTask',
      getTaskResorcePlanByIdft:'getTaskResorcePlanByIdft'
    }),

    async getResPlanlistByTask(task) {
      const token = localStorage.getItem('token');
      const data = { resp_idft: task.idft };
      try {
        const response = await axios.get('https://api2.itaskid.com'+'/my/getresplanlistbytask', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {resp_idft:task.idft}
        });
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          return []; // 返回一个空数组表示数据为空
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
        return []; // 返回一个空数组表示发生错误
      }
    },

    //数据初始化有关的函数
    async getTasksFromProjects(project) {
      let tasklistByProject = [];
      let promises = this.aha.map(idfp => {
        return this.getTasksByIdfp(idfp);
      });
    
      return Promise.all(promises)
        .then((results) => {
          results.forEach((result) => {
            tasklistByProject = tasklistByProject.concat(result);
          });
          // return tasklistByProject;
          // this.tasklistall=tasklistByProject
          return tasklistByProject
        });
    },





    // 调用两个异步函数并合并数组
    async runAndMergeArrays() {
      const myTaskAcllist= await this.getMyTaskAclList(this.username);
      var tasklistByProjectAcl=[]
      this.getMyProjectAclList(this.username)
        .then((result)=>{
          if (!Array.isArray(result)) {
            alert('账号未认证，请重新登录dsss！')
            window.location.href = "https://www.itaskid.com" + "/login";
            return
          }
          const n=result.length
          var i
          var promises = []
          for(i=0;i<n;i++){
            var promise=this.getTasksByTaskProjects(result[i])
            promises.push(promise)
          }
          return Promise.all(promises)
        })
        .then((results) => {
          results.forEach((result) => {
            tasklistByProjectAcl=tasklistByProjectAcl.concat(result)
          })
          // const mergedArray = this.updateTaskPermitLevelbyTaskacl(myTaskAcllist, tasklistByProjectAcl)
          // tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid)
          if (tasklistByProjectAcl && Array.isArray(tasklistByProjectAcl)) {
            tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid);
          } 
          this.tasklistall=tasklistByProjectAcl 
        })
    },

    // 调用两个异步函数并合并数组,并初始化任务数据为今天的任务
    // async runAndMergeArraysOfToday() {
    //   // const myTaskAcllist = await this.getTasksByTaskacl()
    //   const myTaskAcllist= await this.getMyTaskAclList(this.username);
      
    //   var tasklistByProjectAcl=[]
    //   this.getMyProjectAclList(this.username)
    //     .then((result)=>{


    //       if (!Array.isArray(result)) {
    //         alert('账号未认证，请重新登录dsss！')
    //         window.location.href = "https://www.itaskid.com" + "/login";
    //         return
    //       }

    //       const n=result.length
          
    //       var i
    //       var promises = []
    //       for(i=0;i<n;i++){
    //         if(!(result[i].permit_level=='parter')){
    //           var promise=this.getTasksByTaskProjects(result[i])
    //           promises.push(promise)
    //         }
    //       }
    //       return Promise.all(promises)
    //     })
    //     .then((results) => {
    //       results.forEach((result) => {
    //         tasklistByProjectAcl=tasklistByProjectAcl.concat(result)

    //       })
    //       const mergedArray = this.updateTaskPermitLevelbyTaskacl(myTaskAcllist, tasklistByProjectAcl)
    //       mergedArray.sort((a, b) => a.orderid - b.orderid)
    //       this.tasklistall=mergedArray 

    //       const today = new Date();
    //       today.setHours(0, 0, 0, 0);
    //       this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
    //       this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
    //       const username=localStorage.getItem('username')
    //       this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));
    //       this.$router.push({
    //         name: 'project',
    //         params: {
    //           idfp: 'today'
    //         }
    //       }).catch(err => {
    //         if (err.name !== 'NavigationDuplicated') {
    //           throw err
    //         }
    //       })
    //     })
    // },

    async getAllProjectTasks() {
      var tasklistByProjectAcl=[]
      this.getMyProjectAclList(this.username)
        .then((result)=>{
          if (!Array.isArray(result)) {
            alert('账号未认证，请重新登录！')
            window.location.href = "https://www.itaskid.com" + "/login";
            return
          }

          const n=result.length
          var i
          var promises = []
          for(i=0;i<n;i++){
              var promise=this.getTasksByTaskProjects(result[i])
              // var promise=this.getTasksByIdfpFromTasklist(result[i])
              promises.push(promise)
          }
          return Promise.all(promises)
        })
        .then((results) => {
          results.forEach((result) => {
            tasklistByProjectAcl=tasklistByProjectAcl.concat(result)

          })
          // const mergedArray = this.updateTaskPermitLevelbyTaskacl(myTaskAcllist, tasklistByProjectAcl)
          if (tasklistByProjectAcl && Array.isArray(tasklistByProjectAcl)) {
            tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid);
          } 
          this.tasklistall=tasklistByProjectAcl
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
          this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
          const username=localStorage.getItem('username')
          this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));
          this.$router.push({
            name: 'project',
            params: {
              idfp: 'today'
            }
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })
        })
    },

    // updateTaskPermitLevelbyTaskacl(myTaskAcls,myTasksByProjectAcl){
    //   myTasksByProjectAcl.forEach(task => {
    //     const taskAcl = myTaskAcls.find(item => item.task_idft === task.idft);
    //     if (taskAcl) {
    //         task.permit_level = taskAcl.permit_level;
    //     }
    //   });
    //   return myTasksByProjectAcl
    // },

    doneTaskFirst (doneTasks) {
      const doneTasks1 = [];
      for (var i = 0; i < doneTasks.length; i++) {
        if (doneTasks[i].level === 1) {
          doneTasks1.push(doneTasks[i]);
        } else {
          const parentTask = doneTasks.find(task => task.idft === doneTasks[i]?.parent);
          if (!parentTask) {
            doneTasks1.push(doneTasks[i]);
          }
        }
      }
      return doneTasks1;
    },


    //以下是与任务右键编辑菜单有关的方法
    taskEditMenu(event,task){
      this.usernogroup=`${this.username}nogroup`
      this.selectedTask=task
      //获取选中的任务对应的所有关
      // this.selectedProjectAcls=this.selectedProjectParter
      // if(this.selectedProject.permit_level=='charger') {
      //       this.selectedTask.permit_level='charger'
      // } else {
      //       const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)
      //       this.selectedTask.permit_level=taskAcl.length==0?'viewer':(taskAcl[0].permit_level=='charger'?'charger':'viewer')
      // }
      event.preventDefault()
      event.stopPropagation()
      this.getProjectPartersFromTask(task)
        .then((result)=>{
          this.getSelectedTaskAcls()
        .then((result)=>{
          this.showTaskEdit=true
          this.switchMenuMask()
          const top=(window.innerHeight-event.clientY)<230?(event.clientY-230):event.clientY+10
          const left=(window.innerWidth-event.clientX)<112?(event.clientX-112):event.clientX+10
          this.parentNode ={clientY:top,clientX:left}
          eventBus.$on('closeContexMenu', () => {
          this.showTaskEdit=false
          })
        })
      })
    },
    
    cancelEditTask(){

    },

    //更新任务关系记录，即我在某个任务上的记录
    updateTastAclRecord(newTask){
      this.updateTastAclRecordToSql(newTask)
    },



    closeEditTask(){
    },
    deleteTask(){
      this.tasklist=this.tasklist.filter(item=>item.idft!==this.selectedTask.idft)
      this.deleteTaskbyIdft(this.selectedTask.idft)
      this.showTaskEdit=false
    },
    taskeditMenuitemClicked(item){
      switch (item.id){
        case 1: {
          if(this.selectedTask.level<6) {
            const id=this.$route.params.idfp
            const today=this.convertDate(new Date())
            const starttime=(id=='today'||id=='week')?today:'2999/12/31'
            this.selectedTask.haschild=1
            const idft=uuidv4()
            const childTask={
              idft: idft,
              name:'',
              level: this.selectedTask.level+1,
              charger: this.username,
              parent:this.selectedTask.idft,
              starttime: starttime,
              endtime: starttime,
              starttimeatl:'2999/12/31',
              endtimeatl:'2999/12/31',
              pretask:'',
              haschild: null,
              expanded: 1,
              done: 0,
              project: this.selectedTask.project,
              permit_level:'charger',
              creater:this.username,
              sharedrange:'project',
              isshared:1
            }
            this.tasklistall.unshift(childTask)
            this.tasklist.unshift(childTask)
            this.addTaskToSql(childTask)
              .then(()=>{
                const newOrderidTasks=this.tasklistall.filter(item=>item.project==childTask.project&&item.level==childTask.level)
                for(let i=0;i<newOrderidTasks.length;i++){
                  newOrderidTasks[i].orderid=i
                  this.updateTaskOrderidOfSql(newOrderidTasks[i])
                }
              })
            this.selectedTask.starttime=this.convertDate(this.selectedTask.starttime)
            this.updateTaskOfSql(this.selectedTask)
            const newTaskAcl={permit_level:'charger',username:this.username,task_idft:idft,taclcreater:this.username}
            this.addTaskAclToSql(newTaskAcl)
              .then((res)=>{
                this.myTaskAcllist.push(res)
              })
            
          }
          break
        }
        case 2: {

          break
        }
        case 3: {
          this.showTaskEdit=false
          this.showSummitTask=true
          break
        }
        case 8: {
          this.showTaskEdit=false

          if(this.selectedTask.permit_level==='charger') {

            this.tasklist=this.tasklist.filter(item=>item.idft!==this.selectedTask.idft)
            this.tasklistall=this.tasklistall.filter(item=>item.idft!==this.selectedTask.idft)
            this.deleteTaskbyIdft(this.selectedTask.idft)
            this.delChildTask(this.tasklist,this.selectedTask)
          } else {
            alert('您不具有删除权限！')
          }
          break
        }}
        
    },

    updateTaskAcl(taskAcl){
      this.updateTaskAclByUsernameAndIdft(taskAcl)
      this.myTaskAcllist = this.myTaskAcllist.map(item => {  
        if (item.username === taskAcl.username) {
          return {
            ...item,
            permit_level: taskAcl.permit_level
          };
        }
        return item;
        });
    },

    delChildTask(tasks, parentTask) {
      let hasChildTasks = false;
      tasks.forEach((task) => {
        if (task.parent === parentTask.idft) {
          this.deleteTaskbyIdft(task.idft);
          const leftChildTasks = tasks.filter((item) => item.parent === task.idft);
          if (leftChildTasks.length > 0) {
            hasChildTasks = true;
            this.delChildTask(tasks, task);
          }
        }
      });
      if (!hasChildTasks) {
        return;
      }
    },
    //以上是与任务右键编辑菜单有关的方法

   //以下是任务增删改查相关操作的函数
   handleKeyDown(event,task) {
      if (event.target.value === '') {
        this.deleteTaskbyIdft(task.idft)
        this.delChildTask(this.tasklist,task)
      }
    },
   
    

    //以下是与任务时间编辑相关的方法


    //以下是任务详情相关的操作
    upDateStarttime(value){
      const starttime=this.convertDate(value)
      this.selectedTask.starttime=starttime
      this.updateTaskOfSql(this.selectedTask)
    },

    upDateEndtime(value){
      const endtime=this.convertDate(value)
      this.selectedTask.endtime=endtime
      this.updateTaskOfSql(this.selectedTask)
    },

    updateTaskdate(task) {
      this.$nextTick(function (task) {
      })
    },


    //以下是处理任务信息相关的函数
    sendTaskMessage(newTaskMessage){
      this.socket.emit('message', newTaskMessage, (response) => {
        })
    },

    //以下是通过任务权限获取任务信息
    getTasks(project){
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      let myTaskAcllist=[]
      this.getMyTaskAclList(username)
        .then((result)=>{
          if(result.length>0) {
            myTaskAcllist=result            
            const promises=myTaskAcllist.map(async (taskacl)=>{
              const task=await this.getTaskinfoByIdft(taskacl.task_idft)  
              taskacl.name=task[0].name
              taskacl.duration=task[0].duration
              taskacl.endtime=task[0].endtime
              taskacl.pretask=task[0].pretask
              taskacl.level=task[0].level
              taskacl.parent=task[0].parent
              taskacl.project=(taskacl.project_idfp==''||taskacl.project_idfp==null||taskacl.project_idfp==undefined)?task[0].project:taskacl.project_idfp;
              taskacl.haschild=task[0].haschild
              taskacl.done=task[0].done
              taskacl.expanded=task[0].expanded
              taskacl.id=task[0].id
              taskacl.delete=task[0].delete
              taskacl.starttime=task[0].starttime
              taskacl.idft=task[0].idft
            })
            return Promise.all(promises)
          }
        })
          .then(()=>{
            if (Array.isArray(myTaskAcllist)){
              
              this.tasklistall=myTaskAcllist.reverse()
              const today=new Date()
              today.setHours(0, 0, 0, 0)
              if(project=='today'){
              this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
              this.tasklistDone=this.tasklistall.filter(task=>(task.project==nogroup)&&task.done===1)
              }else if (project=='week') {
                today.setHours(0, 0, 0, 0)
                const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
                this.tasklist=this.tasklistall.filter(task=>(JSON.stringify(new Date(task.starttime)&&task.done===0)
                ==JSON.stringify(oneWeekLater))||((new Date(task.starttime)<oneWeekLater)&&(task.starttime)))
                this.nogroupTasklist=this.tasklistall.filter(task=>(task.project==nogroup)&&task.done===0)
                this.tasklistDone=this.tasklistall.filter(task=>(task.project==nogroup)&&task.done===1)
              }else if(project=='inbox'){
                this.tasklist=this.tasklistall.filter(task=>task.project==nogroup&&task.done===0)
                this.tasklistDone=this.tasklistall.filter(task=>task.project==nogroup&&task.done===1)
              }else {
                
                this.tasklist=this.tasklistall.filter(task=>task.project==project&&task.done===0)
                
                
                this.tasklistDone=this.tasklistall.filter(task=>task.project==project&&task.done===1)
              }
              console.log('获取任务列表成功！')
            } 


          }) 
          .catch(function (error) {
            console.log(error?.response?.data?.message || 'An error occurred.');
          }) 
    },

    //以下是通过任务权限获取任务信息
    async getTasksByTaskacl() {
      try {
        const username = localStorage.getItem('username');
        const nogroup = `${username}nogroup`;
        let myTaskAcllist = [];
        const result = await this.getMyTaskAclList(username); 
        if (result.length > 0) {
          myTaskAcllist = result;
          const promises = myTaskAcllist.map(async (taskacl) => {
            const task = await this.getTaskinfoByIdft(taskacl.task_idft);
            
            taskacl.name = task[0].name;
            taskacl.duration = task[0].duration;
            taskacl.endtime = task[0].endtime;
            taskacl.pretask = task[0].pretask;
            taskacl.level = task[0].level;
            taskacl.parent = task[0].parent;
            taskacl.project = (taskacl.project_idfp==''||taskacl.project_idfp==null||taskacl.project_idfp==undefined)?task[0].project:taskacl.project_idfp;
            taskacl.haschild = task[0].haschild;
            taskacl.done = task[0].done;
            taskacl.expanded = task[0].expanded;
            taskacl.id = task[0].id;
            taskacl.delete = task[0].delete;
            taskacl.starttime = task[0].starttime;
            taskacl.idft = task[0].idft;
            taskacl.record = task[0].record
            taskacl.orderid = task[0].orderid
            taskacl.position=task[0].position
            taskacl.unitotsk=task[0].unitotsk
            taskacl.qtyotsk=task[0].qtyotsk
            taskacl.qtyotsked=task[0].qtyotsked
            taskacl.starttimeatl=task[0].starttimeatl
            taskacl.endtimeatl=task[0].endtimeatl
            return taskacl
          });
        }
        return myTaskAcllist
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },
    
    async getTasksFromProject(project) {
      try {
        await this.getUsername();
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const user = { charger: username };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: user
        });
    
        if (Array.isArray(response.data)) {
          this.tasklistall = response.data.reverse();
          const today = new Date();
          today.setHours(0, 0, 0, 0);
    
          if (project == 'today') {
            this.tasklist = this.tasklistall.filter(task => new Date(task.starttime) <= today && task.done === 0);
            this.tasklistDone = this.tasklistall.filter(task => task.project == `${username}nogroup` && task.done === 1);
          } else if (project == 'week') {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
    
            this.tasklist = this.tasklistall.filter(task =>
              (JSON.stringify(new Date(task.starttime)) && task.done === 0) ==
              JSON.stringify(oneWeekLater) ||
              (new Date(task.starttime) < oneWeekLater && task.starttime)
            );
    
            this.nogroupTasklist = this.tasklistall.filter(task =>
              task.project == `${username}nogroup` && task.done === 0
            );
    
            this.tasklistDone = this.tasklistall.filter(task =>
              task.project == `${username}nogroup` && task.done === 1
            );
          } else {
            this.tasklist = this.tasklistall.filter(task =>
              task.project == project && task.done === 0
            );
    
            this.tasklistDone = this.tasklistall.filter(task =>
              task.project == project && task.done === 1
            );
          }
    
          console.log('获取任务列表成功！');
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    ...mapMutations({
      updateSelectProjName:'updateSelectProjName',
      updateUser: 'updateUser',
      switchMenuMask: 'switchMenuMask',
    }),

    async getSelectedTaskAcls() {
      let selectedTaskAcls = [];
    
      // 构建任务关系数组
      this.selectedProjectAcls.forEach((item) => {
        const taskAcl = {
          username: item.username,
          nickname: item.nickname,
          permit_level: item.permit_level,
          project_idfp: item.project_idfp,
          avatarUrl: item.avatarUrl,
          task_idft: this.selectedTask.idft
        };
        selectedTaskAcls.push(taskAcl);
      });
      try {
        // 获取指定任务ID的任务关系
        const results = await this.getTaskAclsByTaskIdft(this.selectedTask.idft);

        // 根据结果更新任务关系数组
        results.forEach((result) => {
          // 查找是否存在相同的 username
          const existingAclIndex = selectedTaskAcls.findIndex((acl) => acl.username === result.username);
          if (existingAclIndex !== -1) {
            // 如果存在相同的 username，则用 result 替换该元素
            result.avatarUrl = selectedTaskAcls[existingAclIndex].avatarUrl;
            selectedTaskAcls[existingAclIndex] = result;
          }
        });
    
        this.selectedTaskAcls = selectedTaskAcls;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },

    
    goToTaskDetail(e,task){
      this.isTaskCharger=this.getPermitLevel(task)
      //获取选中的任务对应的所有关系
      this.getSelectedTaskAcls()
      // this.getTaskSummitMsgs(task)
      //   .then((result)=>{
      //     console.log('result0708',result)
      //     if(result&&Array.isArray(result)){
      //       result.sort((a, b) => {
      //         return new Date(a.timestamp) - new Date(b.timestamp);
      //       });
      //     } else {
      //       result=[]
      //     }
      //     this.taskProcessMsgs = result;
      //   })



      this.getResPlanlistByTask(task)
        .then((result)=>{
          if(result&&Array.isArray(result)){
            this.selectedTaskResPlans=result
          } else {
            this.selectedTaskResPlans=[]
          }
        })

      this.getProjectPartersFromTask(task)
      this.clickedInputIdft=task.idft
      this.selectedTask=task

      e.stopPropagation()
      localStorage.setItem('selectedTaskLocal',task.name)
      const oldtask=localStorage.getItem('selectedTaskLocal')
      this.$router.push({
        name: 'detail',
        params: {
          idft:task.idft,
          name: task.name
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })

      this.getTaskMsgsByReceiver(task.idft)
        .then(async (result)=>{
          const processedResult = await this.processTaskMsgs(result);
          this.taskContents = processedResult;
        })

      this.getTaskFilesByIdft(task.idft)
        .then((result)=>{
          if(result&&Array.isArray(result)){
            this.selectedTaskFiles=result
          } else {
            this.selectedTaskFiles=[]
          }
        })
    },

    // async getTaskFilesByIdft(task_idft) {
    //   try {
    //     const token = localStorage.getItem('token');
    //     const data = { task_idft: task_idft };
    //     const response = await axios({
    //       method: 'get',
    //       url: 'https://api2.itaskid.com' + '/my/gettaskfilesbyidft',
    //       headers: {
    //         Authorization: token,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       params: data
    //     });
  
    //     if (Array.isArray(response.data)) {
    //       return response.data;
    //     } else {
    //       alert('账号未认证，无法获取数据！');
    //       window.location.href = "https://www.itaskid.com" + "/login";
    //       return
    //     }
    //   } catch (error) {
    //     console.log(error?.response?.data?.message || 'An error occurred.');
    //   }
    // },

    async getTaskFilesByIdft(task_idft) {
      try {
        const token = localStorage.getItem('token');
        const data = { task_idft: task_idft };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com/my/gettaskfilesbyidft',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
    
        if (response.data && response.data.length > 0) {
          return response.data;
        } else {
          // if (!response.data) {
          //   alert('未获取到任何数据，请检查输入的任务ID是否正确。');
          // } else {
          //   alert('账号未认证，无法获取数据！');
          //   window.location.href = "https://www.itaskid.com/login";
          // }
          return []; // 返回空数组作为默认值表示数据为空
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred');
        return []; // 返回空数组作为默认值表示发生错误
      }
    },

    // async getTaskSummitMsgs(task) {
    //   try {
    //     const token = localStorage.getItem('token');
    //     const data = { task_idft: task.idft };
    //     const response = await axios({
    //       method: 'get',
    //       url: 'https://api2.itaskid.com' + '/my/gettasksummitmsgsbyidft',
    //       headers: {
    //         Authorization: token,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       params: data
    //     });
  
    //     if (Array.isArray(response.data)) {
    //       return response.data;
    //     } else {
    //       // alert('账号未认证，无法获取数据qia！');
    //       // window.location.href = "https://www.itaskid.com" + "/login";
    //       return []
    //     }
    //   } catch (error) {
    //     console.log(error?.response?.data?.message || 'An error occurred.');
    //   }
    // },

    async getTaskSummitMsgs(task) {
      try {
        const token = localStorage.getItem('token');
        const data = { task_idft: task.idft };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com/my/gettasksummitmsgsbyidft',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async processTaskMsgs(result) {
      for (const msg of result) {
        if (msg.type === 'img') {
          const imgUrl = await this.showPic(msg.file_name, 'showmsgimg');
          msg.imgUrl = imgUrl;
        }
      }
      return result;  // Optionally return the modified result
    },

    async showPic(imgname,url) {
      if(imgname==null||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/${url}`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    }, 



    async getProjectPartersFromTask(task) {
      try {
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        var selectedProjectAcls = await this.getProjectAclListByIdfp(task.project);

        //以下部分代码合并了没有共享项目，但是直接参与了某个任务的任务关系
        var selectedTaskAcls=await this.getTaskAclsByTaskIdft(task.idft)
        selectedProjectAcls=selectedProjectAcls.concat(selectedTaskAcls)
        const uniqueAcls = []; // 用于存放去重后的数组
        const seenUsernames = {}; // 用于跟踪已经处理过的 username
        for (const acl of selectedProjectAcls) {
          if (!seenUsernames[acl.username]) {
            // 如果这个 username 还没有被处理过，则添加到结果数组中
            uniqueAcls.push(acl);
            // 标记这个 username 为已处理
            seenUsernames[acl.username] = true;
          }
        }
        // 此时，uniqueAcls 包含了去重后的元素
        selectedProjectAcls = uniqueAcls; // 用去重后的数组覆盖原数组（如果需要的话）
        //以上部分代码合并了没有共享项目，但是直接参与了某个任务的任务关系
        
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar);
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
          // result.avatarUrl=avatarUrl
          // return result
        });
        await Promise.all(promises);
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },


    addTaskFromInput(){
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      
      var newTask={}
      if(this.newTaskNameLv1.trim()!==''){
        const id=this.$route.params.idfp
        const idft=uuidv4()
        if(id=='today'||(id=='week')) {
          const today=new Date()
          const starttime=this.convertDate(today)
          newTask={
            idft:idft,
            name:this.newTaskNameLv1,
            duration: 1,
            starttime: starttime,
            endtime:starttime,
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:1,
            parent: 'roottask',
            project:nogroup,
            haschild:0,
            done:0,
            expanded:1,
            permit_level:'charger',
            creater:username,
            charger:username,
            sharedrange:'project',
            isshared:1
          }
        } else if(id=='inbox'){
          newTask={
            idft:idft,
            name:this.newTaskNameLv1,
            duration: null,
            starttime: '2999/12/31',
            endtime:'2999/12/31',
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:1,
            parent: 'roottask',
            project:nogroup,
            haschild:0,
            done:0,
            expanded:1,
            permit_level:'charger',
            creater:username,
            charger:username,
            sharedrange:'project',
            isshared:1
          }
        }
        else {
          newTask={
            idft:idft,
            name:this.newTaskNameLv1,
            duration: null,
            starttime: '2999/12/31',
            endtime:'2999/12/31',
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:1,
            parent: 'roottask',
            project:id,
            haschild:0,
            done:0,
            expanded:1,
            permit_level:'charger',
            creater:username,
            charger:username,
            sharedrange:'project',
            isshared:1
          }
        }
        this.tasklist.unshift(newTask)
        this.tasklistall.unshift(newTask)
        
        
        this.nogroupTasklist.unshift(newTask)
        this.addTaskToSql(newTask)  
          .then(()=>{
            const newOrderidTasks=this.tasklistall.filter(item=>item.project==newTask.project&&item.level==newTask.level)
            for(let i=0;i<newOrderidTasks.length;i++){
              newOrderidTasks[i].orderid=i
              this.updateTaskOrderidOfSql(newOrderidTasks[i])
            }
            // this.runAndMergeArrays()
          })
        const newTaskAcl={permit_level:'charger',username:this.username,task_idft:idft,taclcreater:this.username}
        this.addTaskAclToSql(newTaskAcl)
          .then((res)=>{
            this.myTaskAcllist.push(res)
          })
        
        
        this.newTaskNameLv1=''

        
        
      }
    },
   
  
    toggleNode(event,task){
      event.stopPropagation()
      task.expanded=!task.expanded
    },

    
    addBroTask(e,task) {
      if(task.name.trim()!==''){
        const username=localStorage.getItem('username')
        const nogroup=`${username}nogroup`
        const id=this.$route.params.idfp
        const idft=uuidv4()
        var newTask={}
        if(id=='today'||(id=='week')) {
          const today=new Date()
          const starttime=this.convertDate(today)
          newTask={
            idft:idft,
            name:'',
            duration: 1,
            starttime: starttime,
            endtime: starttime,
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:task.level,
            parent: task.parent,
            haschild:0,
            done:0,
            expanded:1,
            charger:username,
            project: task.project,
            permit_level:'charger',
            creater:username,
            sharedrange:'project',
            isshared:1
          }
        } else {
          newTask={
            idft:idft,
            name:'',
            duration: 1,
            starttime: '2999/12/31',
            endtime:'2999/12/31',
            starttimeatl:'2999/12/31',
            endtimeatl:'2999/12/31',
            pretask:'',
            level:task.level,
            parent: task.parent,
            project:task.project,
            haschild:0,
            done:0,
            expanded:1,
            permit_level:'charger',
            creater:username,
            sharedrange:'project',
            isshared:1
          }
        }
        const taskIndex=this.tasklist.findIndex(item=>item.idft===task.idft)
        this.tasklist.splice(taskIndex+1,0,newTask)
        const index = this.tasklistall.findIndex(item => item.idft === task.idft)
        this.tasklistall.splice(index+1,0,newTask)
        this.addTaskToSql(newTask)
          .then(()=>{
            const newOrderidTasks=this.tasklistall.filter(item=>item.project==newTask.project&&item.level==newTask.level)
            for(let i=0;i<newOrderidTasks.length;i++){
              newOrderidTasks[i].orderid=i
              this.updateTaskOrderidOfSql(newOrderidTasks[i])
            }
          })
        const newTaskAcl={permit_level:'charger',username:this.username,task_idft:idft,taclcreater:this.username}
        this.addTaskAclToSql(newTaskAcl)
          .then((res)=>{
            this.myTaskAcllist.push(res)
          })
      }
      
    },
    
    

    // 数据库tasklist表的增删改查
    getUsername() {
      const token = localStorage.getItem('token')
      axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
        headers: { Authorization: token} // 在 headers 中添加 Authorization Token
      })
      .then(response => {
        this.username = response.data.username;
        const user={username: this.username}
        this.updateUser(user)
      })
      .catch(error => {
        console.log(error?.response?.data?.message || 'An error occurred.');
      });
    },

    // 添加某个任务
    addTaskSql(newTask){
      const token = localStorage.getItem('token')
      const idft=uuidv4()
      newTask.idft=(newTask.idft==undefined)?idft:newTask.idft
      newTask.starttime=this.convertDate(newTask.starttime)
      newTask.endtime=this.convertDate(newTask.endtime)
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/tasklist',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newTask
        })
        .then((response)=>{
          
          console.log('添加任务成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
        

    },

    //根据idft删除数据库中的数据
    deleteTaskbyIdft(idft){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/tasklist/deletebyidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {idft:idft}
        })
        .then((response)=>{
          console.log('删除任务成功！')
          this.deleteTaskAclbyIdft(idft)
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
        this.switchMenuMask()
    },


    //删除某个任务
    deleteTaskSql(url,data,tasks){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        // url: 'https://api2.itaskid.com' + '/my/tasklist',
        url: url,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: data
        })
        .then((response)=>{

          console.log('删除任务成功！')
          for(var i=0;i<tasks.length;i++){
            this.addTaskSql(tasks[i])
          }
          // this.addTasksRecursively(tasks, 0)
        })
        .catch(error => {
          // alert(error.response.data.message)
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
    },
    

    //通过parent属性删除任务并更新
    deleteTaskSqlByParent(url,data,tasks){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: url,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
        })
        .then((response)=>{
          console.log('删除任务成功！')
          for(var i=0;i<tasks.length;i++){
            this.addTaskSql(tasks[i])
          }
        })
        .catch(error => {
          // alert(error.response.data.message)
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
    },

    addTasksRecursively(tasks, index) {
      // 递归终止条件：当索引超出任务数组的长度时，停止递归
      if (index >= tasks.length) {
        console.log("所有任务添加完成");
        return;
      }
      // 添加当前索引对应的任务
      this.addTaskSql(tasks[index]);
      // 递归调用自身，将索引加一，继续添加下一个任务
      addTasksRecursively(tasks, index + 1);
    },

    updateTaskDone(task){
      if(task.done==1){
        this.doneTask(task)
        this.updateTaskOfSql(task)
      } else {
        task.done=0
        this.undoneTask(task)
        this.updateTaskOfSql(task)
      }

    },
    

    updateTaskOfSql(task){
      task.starttime=this.convertDate(task.starttime)
      task.endtime=this.convertDate(task.endtime)
      task.starttimeatl=this.convertDate(task.starttimeatl)
      task.endtimeatl=this.convertDate(task.endtimeatl)
      const token = localStorage.getItem('token')
   
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: task
          })
          .then((response)=>{
            console.log('修改任务成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },



    //以下是任务详情相关的增删改查的函数

    async getTaskContents(task) {
      try {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const user = { charger: username };
    
        const response = await axios.get('https://api2.itaskid.com' + '/my/taskcontents', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: { task_idft: task.idft }
        });

        this.taskContents = response.data
        return this.taskContents
    
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

      
      deleteTaskContentsBytaskid(task){
        const token = localStorage.getItem('token')
        const data={taskid: task.idft}
        axios({
          method:'delete',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
          })
          .then((response)=>{
            
            console.log('删除任务内容详情成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },      
    // //以上是任务相关的数据库增删改查

    updateTasksSqlByProjectAndParent(newTasks, project,parent) {
     
      // newTasks.forEach((task)=>{
      //   task.endtime=this.convertDate(task.endtime)
      // })
      if(newTasks.length>1) {
        const id=newTasks[newTasks.length-1].id
        const data={project:project, parent: parent}
        const url='https://api2.itaskid.com' + '/my/tasklist/deltaskbyprojectandparent'
        this.deleteTaskSql(url,data,newTasks)
      }else {
        for(var i=0;i<newTasks.length;i++){
        this.addTaskSql(newTasks[i])
      }
      } 
    },

    updateTasksSqlByParent(newTasks, parent) {
      if(newTasks.length>1) {
        const id=newTasks[newTasks.length-1].id
        const data={parent:parent}
        const url='https://api2.itaskid.com' + '/my/tasklist/deltaskbyparent'
        this.deleteTaskSql(url,data,newTasks)
      }else {
        for(var i=0;i<newTasks.length;i++){
        this.addTaskSql(newTasks[i])
      }
      } 
    },


    //把标准时间转换成普通的字符串时间
    // convertDate(targetDate){
    //   if(targetDate==''){
    //     targetDate='2999/12/31'
    //   }
    //   const date = new Date(targetDate);
    //   const year = date.getFullYear();
    //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //   const day = date.getDate().toString().padStart(2, '0');
      
    //   const formattedDate = `${year}/${month}/${day}`;
    //   return formattedDate
      
    // },

    convertDate(targetDate) {
      if (targetDate == '') {
        targetDate = '2999/12/31 23:59:59';  // 设置默认时间为23:59:59
      }
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
    
      const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },

    //以下是与完成任务相关的函数
    showDoneTaskToggle(){
      this.showDoneProject=!this.showDoneProject
    },
    showUndoneTaskToggle(){
      this.showUndoneProject=!this.showUndoneProject
    },

    toggleTaskDone(e,task){
      if(e.target.checked) {
        this.doneTask(task)
      } else {
        task.done=0
        this.updateTaskOfSql(task)
      }
    },

    doneTask(task){
      task.done=1
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      const today=new Date()
      if(this.clickedIdfp=='today') {
        this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
        this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
      } else if(this.clickedIdfp=='week'){
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
        this.tasklist = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 0);
        })

        this.tasklistDone = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 1);
        })
      } else {
        this.tasklistDone=this.tasklistall.filter(item=>item.done===1&&item.project==task.project)
        this.tasklist=this.tasklistall.filter(item=>item.done===0&&item.project==task.project)
      }
      this.updateTaskOfSql(task)
      if(task.level<6){
        this.tasklist.forEach((item)=>{
          if(item.parent===task.idft){
            this.doneTask(item)
          }else {
            return
          }
        })
      }
    },
  
    toggleTaskUndone(e,task){
      if(!e.target.checked) {
        this.undoneTask(task)
  
      } else {
        task.done=1
        this.updateTaskOfSql(task)
      }      
    },
    undoneTask(task){
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      const today=new Date()
      task.done=0
      
      if(this.clickedIdfp=='today') {
        this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
        this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
      } else if(this.clickedIdfp=='week'){
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
        this.tasklist = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 0);
        })

        this.tasklistDone = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 1);
        })
      }  else {
        this.tasklistDone=this.tasklistall.filter(item=>item.done===1&&item.project==task.project)
        this.tasklist=this.tasklistall.filter(item=>item.done===0&&item.project==task.project)
      }

      // this.tasklistDone=this.tasklistDone.filter(item=>item.done==1)
      // this.tasklist=this.tasklistall.filter(item=>item.project===task.project&&item.done==0)


      this.updateTaskOfSql(task)
      if(task.level>1){
        this.tasklistDone.forEach((item)=>{
          if(item.idft===task.parent){
            this.undoneTask(item)
          }else {
            return
          }
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>

.project-treecontainer {
  .router-link:hover {
  text-decoration: none;
}

h3  {
  height: 30px;
  margin: 10px 0 10px 20px;
}

.input-add-proj { 
  margin-left:0;
  margin-bottom: 10px;
  padding-left:10px;
  width: 265px;
  height: 30px;
  border: none;
  background-color: rgba(250, 250, 250, 0.9);
  border-radius: 5px;
}

input.input-add-proj:focus {
  // border: 1px solid rgb(64, 158, 255) !important;
  outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
}
.project-container-leftcol {
  // width: 300px;
  overflow: auto;
  // height: 100vh;
  // border-right: 1px solid rgba(200, 200, 200, 0.9);

  .taskinputclicked {
    color: rgb(71, 114, 250);
  }

  .task-container {
    height: 30px;
    display: flex;
    
    .el-icon-arrow-right {
      line-height: 5px;
    }

    .el-icon-arrow-down {
      line-height: 5px;
    }    

    .tasktime-container {
      display: inline;
      height: 30px;
      line-height: 30px;
    }

    .my-date-picker {
      width: 100px;
      height: 30px;
    }

    .taskinfo-container {
      // width: 280px;
      line-height: 30px;
      height: 30px;
      text-align: justify;;
      box-sizing: border-box;
      display: inline flex;
      // border-bottom: 1px solid gray;
      // border-bottom: 1px solid rgba(200, 200, 200, 0.8);
      white-space: nowrap;
      overflow: hidden;
    }
    
    // margin-right:5px;
    .input-taskname { 
      flex: 1;
      float: left;
      line-height: 29px;
      height: 29px;
      background-color: transparent;
      border: none;
      outline:none;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0px;
      padding: 0px !important;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
    }
    .task-starttime {
      height: 29px;
      line-height: 30px;
      float: right;
      flex: 0 0 auto;
      text-align: right;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
      font-size: 12px;
      color: rgb(71, 114, 250)
      
    }
  
  }
  .task-container:hover {
    background-color: rgb(246, 248, 255);
    border-radius: 3px;
  }
  .task-done {
    margin: 3px;
  }

  button {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 3px
  }
  .task-title {
    line-height: 30px;
    display: inline-block;
    text-align: left;
    border-bottom: 0.5px solid rgba(200, 200, 200, 0.9);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .task-title:focus {
    outline: none;
  }



  ul {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
    margin: 0;
  }

  li {
    list-style: none;
    padding-inline-start: 10px;
    
  }
  // li:hover {
  //   background-color: rgb(200, 200, 200);
  // }

  button {
    border:none;
    background: none;
    color: gray;
  }
  button:hover {
    color: black;
  }
  .routerlink {
    height: 30px;
  }

  .my-date-picker {
    width: 0;
    height: 0;
    overflow: hidden;
    background: transparent;
  }
}

  
.projectdone-container {
  text-align: left;
  color: gray;
  .input-taskname {
    color: gray;
  }
  .task-done:checked input {
    background-color: gray !important;
  }
}
.alltasks-container {
  height: calc(100vh - 200px);
  overflow: auto;
}

.search-button {
  border:none;
  background-color: rgb(250, 250, 250);
  height: 30px;
  float:right;
  border-radius: 10%;
}

.search-button:hover {
  background-color: rgb(241, 243, 244);
}

}


</style>