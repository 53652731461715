<template>
  <div style="display: flex;">
    <el-col style="width: 250px; border-right: 1px solid rgba(200, 200, 200, 0.9); 
      flex-shrink: 0; text-align: left; height: calc(100vh - 100px);">
      <div style="height: 100%;">
        <div style="height: 30px;">
          <input type="text" v-model="searchText" placeholder="搜索" class="search-input">
          <button  class="search-button">+</button>
        </div>
        <br>
        <div style=" overflow:auto; height: calc(100% - 100px);">
          <ul style="text-align: left; margin: 0px; padding: 0px;">
            <li v-for="friendship in filterFriendships" :key="friendship.id" @click="selectFriendship(friendship)" style="text-align: left;"
              @contextmenu.prevent="handleFriendshipMenu(friendship,$event)">
              <table>
                <tr>
                  <td rowspan="2">
                    <img :src="friendship.avatarUrl" alt="av" :style="{ width: '40px', height: '40px', borderRadius: '5%' }">
                  </td>
                  <td>{{ friendship.friend_commentname }}</td>
                </tr>
                <tr>
                  <td class="message-container">
                    <div class ="message">
                      {{ friendship.friend_lastmsg }}
                    </div>
                  </td>
                </tr>
              </table>
            </li>
            <EditFriendshipMenu 
              v-if="showEditFriendshipMenu"
              :friendshipeditMenuitems="friendshipeditMenuitems" 
              :event="event" 
              :parentNode="parentNode"
              @friendshipEditMenuitemClick="friendshipEditMenuitemClick">
            </EditFriendshipMenu>
          </ul>
        </div>
      </div>
    </el-col>
    <el-col style="border-right: 1px solid rgba(200, 200, 200, 0.9); width: calc(100% - 300px); height: calc(100vh - 100px);">
      <router-view
      :chatto="chatto" :messages="filterMessages" :username="username" :avatarUrl_user="avatarUrl_user" :avatarUrl_friend="avatarUrl_friend"
      @sendMessage="sendMessage" :selectedFriendInfo="selectedFriendInfo" @handleDrop="handleDrop" @deleteMessage="deleteMessage">
        
      </router-view>
      <SendFile v-if="showSendFileMenu" :friendAvatarUrl="avatarUrl_friend" @cancelSendFilesToFriend="cancelSendFilesToFriend" 
        @sendFilesToFriend="sendFilesToFriend" :sendFriendName="selectedFriendInfo.friend_commentname" :sendFiles="sendFiles">
      </SendFile>
    </el-col>
  </div>
</template>

<script>
import axios from 'axios'
import SendFile from '../menu/message/SendFile.vue'
import io from 'socket.io-client'
import EditFriendshipMenu from '../menu/message/EditFriendshipMenu.vue'
import { mapState , mapMutations} from 'vuex'
import { eventBus } from '../../main.js'

export default {
  components: {
    SendFile,
    EditFriendshipMenu
  },
  
  data () {
    return {
      chats: [
        {
          id: 1,
          name: '小明',
          avatar: 'avatar1.png',
          lastMessage: '今天天气挺好的'
        },
      ],
      friendships:[],
      messages: [
      ],
      searchText: '',
      // newMessage: {},
      newText:'',
      showEmojiPicker: false,
      showFilePicker: false,
      chatto: '',
      socket: null,
      username:'',
      friendName:'',
      localFriendName:'',
      avatar_user:'',
      avatarUrl_user:'',
      avatarUrl_friend:'',
      friendUsername:'',
      selectedFriendInfo:null,
      showSendFileMenu:false,
      sendFiles:[],
      userinfo:null,
      showEditFriendshipMenu:false,
      friendshipeditMenuitems:[],
      parentNode: null,
      filterFriendships:[],
      selectedFriendshipByRight:null,
      filterMessages:[],
    }
  },
  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
      updateSelectProjName: 'updateSelectProjName',
      updateUser: 'updateUser'
    }),

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker
    },
    toggleFilePicker() {
      this.showFilePicker = !this.showFilePicker
    },
    sendMessage(newText) {     
      const now=new Date()
      if (newText.trim() !== '') {
        // 发送消息给socket.io服务器
        const newMessage={
          id:uuidv4(),
          sender: this.username, 
          receiver: this.friendUsername, 
          message: newText, 
          file_name:'', 
          type:'text',
          img_name:'', 
          timestamp: new Date(), 
          file_size: 0, 
          savebysender: this.username, 
          savebyreceiver: this.friendUsername
        }
        this.addChatmsg(newMessage)
        newMessage.sockettype='objchat'
        this.socket.emit('message', newMessage, (response) => {
          console.log('Server response:', response)
        })
        this.filterMessages.push(newMessage)
        this.selectedFriendInfo.friend_lastmsg=newText
        //以下是刷新选中的好友关系的最新聊天记录
        this.friendships = this.friendships.map(friendship => {
          if (friendship.id === this.selectedFriendInfo.id) {
            return { ...friendship, friend_lastmsg: newText }
          }
          return friendship;
        });
        this.updateFriendshipLastmsg(this.selectedFriendInfo.user_id, this.selectedFriendInfo.friend_id,newText)
      }
    },

    selectFriendship(friend){
      this.$router.push({
        name: 'msgrecord',
        params: {
          id: friend.id
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
      this.friendUsername=friend.friend_username
      this.chatto=friend.friend_commentname
      this.selectedFriendInfo=friend
      const selectedFriendInfo=JSON.stringify(friend)
      localStorage.setItem('selectedFriendInfo',selectedFriendInfo)
      this.avatarUrl_friend=friend.avatarUrl
      let messages=[]
      this.getMsgs(this.username,friend.friend_username)
        .then((result)=>{
            messages=result
            const promises=messages.map(async (message)=>{
              //问题在这里,this.showPic()的参数不能是空
              if(message.type==='img') {
                let url='showmsgimg'
                const result=await this.showPic(message.file_name,url)
                message.imgUrl=result
              } else {
                message.imgUrl=''
              }
            })
            return Promise.all(promises)
          })
        .then(()=>{
          this.filterMessages=messages.filter(message=>message.savebysender==this.username||message.savebyreceiver==this.username)
        }) 
    },


    // 添加某个任务
    addChatmsg(newMsg){
      const token = localStorage.getItem('token')
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/chatmsgs',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newMsg
        })
        .then((response)=>{
          console.log('数据库新增的聊天记录',response.data)
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    },

    // 向服务器添加发送文件的聊天记录
    async addChatmsgForFile(newMsg){
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://api2.itaskid.com' + '/my/chatmsgs', newMsg, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        return response.data
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getMsgs(sender, receiver) {
      try {
        const token = localStorage.getItem('token');
        const data = { sender: sender, receiver: receiver };
        const response = await axios.get('https://api2.itaskid.com' + '/my/chatmsgs', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        
        if (Array.isArray(response.data)) {
        
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
          // this.$router.push('/login').catch(err => {
          //   if (err.name !== 'NavigationDuplicated') {
          //     throw err;
          //   }
          // });
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

      async getFriendships(id,token) {
        try {
          const data = { user_id: id };
          const response = await axios.get('https://api2.itaskid.com' + '/my/friendships', {
            headers: {
              Authorization: token,
              // 'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: data
          });
          if (Array.isArray(response.data)) {
            return response.data
          } else {
            alert('账号未认证，无法获取数据！');
            window.location.href = "https://www.itaskid.com" + "/login";
            return
          }
          console.log(response.data);
        } catch (error) {
          console.log(error?.response?.data?.message || 'An error occurred.')
        }
      },

      async showPic(imgname,url) {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/${url}`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }, 

      async getFriendshipsByUserId(id,token) {
      try {
        const data = { user_id: id };
        const response = await axios.get('https://api2.itaskid.com' + '/my/friendshipsbyuserid', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
        console.log(response.data);
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }
    },  

    //发送文件/图片相关的函数
    isImgFile(filename) {
      if(filename==='') {
        return false
      } else {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i
        return imageExtensions.test(filename)
      }
    },

    handleDrop(files){
      this.sendFiles=files
      this.showSendFileMenu=true
    },

    cancelSendFilesToFriend(){
      this.showSendFileMenu=false
      this.sendFiles=[]
    },
    
    
    async sendFilesToFriend(fileMsg) {
      this.showSendFileMenu = false;
      try {
        const files = await this.uploadFiles(this.sendFiles);
        const promises = files.map(async (file) => {
          const isImg=this.isImgFile(file.filename)
          let newMsg={
            id:uuidv4(),
            sender: this.username,
            receiver: this.friendUsername,
            savebysender: this.username,
            savebyreceiver: this.friendUsername,            
            message: '',
            file_name: file.filename,
            type: '',
            sockettype:'manchat',
            img_name: '',
            file_size: file.size,
            imgUrl: '', // 添加 imgUrl 属性
            timestamp: new Date()            
          }
          if(isImg) {
            newMsg.type='img'
            const result1 = await this.addChatmsgForFile(newMsg);
            const result2 = await this.showPic(result1.file_name, 'showmsgimg');
            newMsg.imgUrl = result2;
            
            this.socket.emit('message', newMsg);
            this.filterMessages.push(newMsg)

            //以下是刷新选中的好友关系的最新聊天记录
            // this.friendships = this.friendships.map(friend => {
            //   if (friend.id === this.selectedFriendInfo.id) {
            //     return { ...friend, friend_lastmsg: '[图片]' }
            //   }
            //   return friend;
            // });
            this.selectedFriendInfo.friend_lastmsg='[图片]'
            this.updateFriendshipLastmsg(this.selectedFriendInfo.user_id, this.selectedFriendInfo.friend_id,'[图片]')

          }else {
            newMsg.type='file'     
            this.socket.emit('message', newMsg)
            this.addChatmsg(newMsg);
            this.filterMessages.push(newMsg)
            
            //以下是刷新选中的好友关系的最新聊天记录
            // this.friendships = this.friendships.map(friend => {
            //   if (friend.id === this.selectedFriendInfo.id) {
            //     return { ...friend, friend_lastmsg: '[文件]' }
            //   }
            //   return friend;
            // });
            this.selectedFriendInfo.friend_lastmsg='[文件]'
            this.updateFriendshipLastmsg(this.selectedFriendInfo.user_id, this.selectedFriendInfo.friend_id, '[文件]')
          }
        })
        await Promise.all(promises)
        if(fileMsg) {
          const leftMsg={
            id:uuidv4(),
            sender: this.username,
            receiver: this.friendUsername,
            savebysender: this.username,
            savebyreceiver: this.friendUsername,             
            message: fileMsg,
            file_name: '',
            type: 'text',
            sockettype:'manchat',
            img_name: '',
            timestamp: new Date()         
          }
          this.addChatmsg(leftMsg)
          this.filterMessages.push(leftMsg)
          this.socket.emit('message', leftMsg)
          //以下是刷新选中的好友关系的最新聊天记录
          this.friendships = this.friendships.map(friend => {
            if (friend.id === this.selectedFriendInfo.id) {
              return { ...friend, friend_lastmsg: fileMsg }
            }
            return friend;
          });
        }

      } catch (error) {
        console.error(error);
        // 处理上传失败的逻辑
      }
    },

    async uploadFiles(files) {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const encodedFileName = encodeURIComponent(files[i].name);
        formData.append('files', files[i], encodedFileName);
      }
    
      try {
        const response = await axios.post('https://api2.itaskid.com' + '/my/uploadmsgfiles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        });
        console.log(response.data);
        return response.data;
        // 处理上传成功的逻辑
      } catch (error) {
        console.error(error);
        throw error;
        // 处理上传失败的逻辑
      }
    },

    updateFriendshipLastmsg(userId,friendId,lastmsg){    //更新好友关系最新聊天记录的处理函数
      const newMsg={user_id:userId, friend_id:friendId,friend_lastmsg: lastmsg}
      const token=localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatefriendshiplastmsg',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newMsg
          })
          .then((response)=>{
            // console.log(response.data)
            console.log('修改好友关系最新聊天记录成功',response.data)
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },

    //聊天记录的处理函数
    //删除本地化的聊天记录
    deleteMessage(message){
      this.filterMessages=this.filterMessages.filter(item=>item.id!==message.id)
    },

    //删除
    deleteSqlMsgbyId(id){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/deletemsgbyid',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {id:id}
        })
        .then((response)=>{
          console.log(response.data)
          console.log('删除聊天记录成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除聊天记录失败！')
        })
    },


    //好友关系列表右键的处理函数
    handleFriendshipMenu(friendship,event){
      this.selectedFriendshipByRight=friendship
      this.friendshipeditMenuitems=[
        { id:1,label: '置顶' }, 
        { id:2,label: '标为未读' },
        { id:3,label: '消息免打扰' },
        { id:4,label: '不显示聊天' }, 
        { id:5,label: '删除聊天' },
      ],
      this.showEditFriendshipMenu=true
      this.switchMenuMask()
      this.event={...event}  
      const top=(window.innerHeight-event.clientY)<189?(event.clientY-189):event.clientY
      const left=(window.innerWidth-event.clientX)<76?(event.clientX-76):event.clientX
      this.parentNode ={clientY:top+20,clientX:left}
      eventBus.$on('closeContexMenu', () => {
        this.showEditFriendshipMenu=false
      })
    },

    //处理好友聊天记录的右键点击函数
    friendshipEditMenuitemClick(item){
      //在这里处理右键菜单对应的选项
      this.showEditFriendshipMenu=false
      //以下是项目编辑右键菜单的处理分支
      switch (item.id){
        case 1: {
          
          break
        }
        case 2: {
          
          break
        }
        case 3: {
          
          break
        }
        case 4: {
          
          break
        }
        case 5: {
          this.deleteAllChatMsgsOfSelectedFriend(this.selectedFriendshipByRight)
          break
        }
      }
      this.switchMenuMask()
    },

    deleteAllChatMsgsOfSelectedFriend(friendship){
      
    },


  },

  computed: {
    showTimestamp(){
      return(index)=> {
        if(index==0) {
          return true
        } else {
          return false
        }
      }
    },
    

    filteredChats() {
      // 根据搜索框的内容过滤聊天列表
      return this.chats.filter(chat => {
        return chat.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
    },

   
    
  },


  mounted(){
    const token = localStorage.getItem('token')
    this.username=localStorage.getItem('username')
    const key=`user|${this.username}`
    const userinfo=localStorage.getItem(key)
    this.userinfo=JSON.parse(userinfo)
    this.showPic(this.userinfo.avatar,'showavatar')
      .then((result)=>{
        this.avatarUrl_user=result
      })
    
    const keyOfLocalFriend=`user|${this.username}`
    const localFriendship=JSON.parse(localStorage.getItem(keyOfLocalFriend))
    this.chatto=localFriendship.friend_commentname
    
    
    const userId=localStorage.getItem('userId')
    const selectedFriendInfo=localStorage.getItem('selectedFriendInfo')
    this.selectedFriendInfo=JSON.parse(selectedFriendInfo)

    this.avatarUrl_friend=this.selectedFriendInfo.avatarUrl
    this.friendUsername=this.selectedFriendInfo.friend_username
    
    let friendships=[]
    this.getFriendshipsByUserId(userId,token)
      .then((result)=>{
        friendships=result
        if(result){
          const promises=friendships.map(async (friendship)=>{
            let url='showavatar'
            const result=await this.showPic(friendship.friend_avatar, url)
            friendship.avatarUrl=result
          })
          return Promise.all(promises)
        } else {
          return []
        }
      })
      .then(()=>{
        this.friendships=friendships
        if(friendships){
          this.filterFriendships=friendships.filter(item=>item.ifshow===1&&item.status=='accept')
        } else {
          this.filterFriendships=[]
        }
        
      })    
    

    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
    });

    
    
    // 监听服务器发送的消息
    
    this.socket.on('message', (message) => {
      if(message.receiver==this.username&&message.sender!==this.username){
        let lastmsg=''
        if(message.type=='text') {
          lastmsg=message.message
        } else if(message.type=='img') {
          lastmsg='[图片]'
        } else {
          lastmsg='[文件]'
        }
        console.log('lastmsg',lastmsg)
        this.friendships.forEach(friendship => {
          if (friendship.friend_username === message.sender) {
            friendship.friend_lastmsg = lastmsg;
          }
        });
        this.filterMessages.push(message)
      }
      
    });
    
    let messages=[]
    this.getMsgs(this.username,this.selectedFriendInfo.friend_username)
      .then((result)=>{
          messages=result
          const promises=messages.map(async (message)=>{
            //问题在这里,this.showPic()的参数不能是空
            if(message.type==='img') {
              let url='showmsgimg'
              const result=await this.showPic(message.file_name,url)
              message.imgUrl=result
            } else {
              message.imgUrl=''
            }
          })
          return Promise.all(promises)
        })
      .then(()=>{
        this.filterMessages=messages.filter(message=>message.savebysender==this.username||message.savebyreceiver==this.username)
      }) 
  },

  beforeDestroy() {
    // 断开socket.io连接
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path === '/home/friendshipgroup') {
        // 获取friendships的id
      }
    }
},
}
</script>

<style lang="less" scoped>


li {
    list-style: none;
  }

  li:hover {
    background-color: rgb(200, 200, 200);
    cursor: pointer;
  }


.el-main {
  line-height: 20px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 50px;
  background: none;
}

.info {
  display: inline;
  flex-direction: column;
}

.avatar {
  margin-right: 10px;
}

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-container {   //ok
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .message {    //ok
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    // flex: 1; /* 占据剩余空间 */
    max-width: 180px;
    
  }


  .msg-time {
    display: flex;
    justify-content: center;
    background-color: rgb(218, 218, 218);
    width: fit-content;
    margin: 0 auto;
  }

  .search-input {
    height: 25px; 
    width: 200px;
    border:none;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
  }

  .search-input:focus {
    outline: 0.5px solid rgba(64, 158, 255,0.7) !important;

  }

  .search-button {
    border:none;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    float: right; 
    margin-right: 10px; 
    height: 30px;
    width: 30px;
  }

  .search-button:hover {
    color:rgba(64, 158, 255,0.7);
    background-color: rgb(226, 226, 226);
  }
</style>