<template>
  <div style="text-align: left;">
    <Detail @upDateStarttime="upDateStarttime" :selectedTask="selectedTask" :selectedTaskResPlans="selectedTaskResPlans"
      :taskContents="taskContents"  :selectedProjectAcls="selectedProjectAcls"  v-if="showTaskDetail" 
      @addLocalTaskFileData=addLocalTaskFileData @addLocalTaskSummitMsg=addLocalTaskSummitMsg
      @sendTaskMessage="sendTaskMessage" @upDateEndtime="upDateEndtime" @updateTaskOfSql="updateTaskOfSql"
      :taskProcessMsgs="taskProcessMsgs" :selectedTaskFiles="selectedTaskFiles" @updateTaskRecord="updateTaskRecord">
    </Detail>
    <AddProjectMenu  v-if="isShowAddProjMenu" @closeMenu="shutAddProjMenu"  @updateLocalItems="updateLocalItems"
      @addProjectWithAclToSql="addProjectWithAclToSql" :projectNameFromParent="projectName">
    </AddProjectMenu>
    <LoadProjectMenu  v-if="isShowLoadProjectMenu" @closeLoadProjectMenu="closeLoadProjectMenu" @loadProject="loadProject">
    </LoadProjectMenu>
    <div style="width:100%;">
      <h4 style="margin:0px;padding:0px;text-align: left;right:300px;">

        <span style="margin-right:10px;height:25px;">
          <button class="projectbutton" @click="showAddProjMenu">
            <i class="el-icon-plus"></i>
          </button>
          <button class="projectbutton" @click="showDownloadProjMenu">
            <i class="el-icon-download"></i>
          </button>
          <button class="projectbutton"  @click="showUploadProjMenu">
            <i class="el-icon-upload"></i>
          </button>
        </span>
        <input type="text" v-model="projectName" class="projecttitle-container" placeholder="请输入项目名称。。。">
        <input type="text" class="projecttitle-container" placeholder="搜索任务..." 
          style="width:200px;margin-left:10px;font-size: 12px;font-weight: 600;" v-model="searchTaskName">
        <!-- <el-cascader v-model="value" :options="options" @change="handleChange">
        </el-cascader> -->

        <div style="display: inline; margin-right:20px;float:right;">
          <!-- <button class="savebutton" @click="switchView(1)" :style="{backgroundColor: ViewOption === 1 ? 'rgb(145, 170, 252)' : ''}">
            横道图
          </button> -->
          <!-- <button class="savebutton" @click="switchView(4)" :style="{backgroundColor: ViewOption === 4 ? 'rgb(145, 170, 252)' : ''}">
            资源分析
          </button> -->
          <!-- <button class="savebutton" @click="switchView(5)" :style="{backgroundColor: ViewOption === 5 ? 'rgb(145, 170, 252)' : ''}">
            资源配置
          </button> -->
          <!-- <button class="savebutton" @click="switchView(2)" :style="{backgroundColor: ViewOption === 2 ? 'rgb(145, 170, 252)' : ''}">
            统计情况
          </button>
          <button class="savebutton" @click="switchView(3)" :style="{backgroundColor: ViewOption === 3 ? 'rgb(145, 170, 252)' : ''}">
            工作台
          </button> -->
          <select v-model="selectedOption" style="outline:none;width:60px;">
            <option value="全部">全部</option>
            <option value="今天">今天</option>
            <option value="本周">本周</option>
            <option value="本月">本月</option>
            <option value="上周">上周</option>
            <option value="上月">上月</option>
            <option value="下周">下周</option>
            <option value="下月">下月</option>
          </select>
          <label for="" style="margin-left:10px; font-weight: normal;">未完成</label>
          <input type="checkbox" style="margin-right: 10px;" v-model="ifNotFinished">
          <!-- <label for="" style="margin-left:10px; font-weight: normal;">滞后</label> -->
          <!-- <input type="checkbox" style="margin-right: 10px;" v-model="taskIsDelay"> -->
        </div>
      </h4>
    </div>
    <div style="border:1px solid gray; height: calc(100vh - 120px); display: flex; width:100%;" ref="contentcontainer">
      <!-- <div class="menu-container" v-if="showTaskDetail"  v-draggable>
        <div class="menu" style="position:relative;">

        </div>
      </div> -->
      <div class="resize-left" ref="leftCol" :style="{ width: leftWidth + 'px' }">
        <!-- <el-col style="width:calc(100% - 3px); height:100%; border-right:1px solid gray; overflow-x: auto;overflow-y: hidden;"> -->
        <el-col style="width:calc(100% - 3px); height:100%; border-right:1px solid gray; overflow: auto;">
          <div style="width: 1310px;text-align: left;position:relative;" v-show="searchTaskName!==''">
            <div style="font-size: 12px; text-align: left; border:1px solid gray;width:1070px;" ref="tablecontainer">
              <div style="margin:0;height:51px;" class="text">
              <table>
                <tr style="text-align: center;font-weight: bold;">
                  <td style="width:25px;">更多</td>
                  <td style="width: 34px; height: 51px;position:relative;">
                    <button   style="position:absolute;left:20px;top:0px;"  @click="showSequence"  v-show="!ifShowSequence">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>ID</label>
                  </td>
                  <td style="width: 60px; height: 51px;position:relative;" v-show="ifShowSequence">
                    <button   style="position:absolute;left:2px;top:0px;"  @click="showSequence">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <button   style="position:absolute;left:46px;top:0px;" v-show="!ifShowPart" @click="showPart">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>序号</label>
                  </td>
                  <td style="width: 100px; height: 51px;position:relative;" v-show="ifShowPart">
                    <button   style="position:absolute;left:2px;top:0px;"  @click="showPart">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <label>部位</label>
                  </td>
                  <td style="width: 170px; height: 51px;">
                    <label for="">任务名称</label>
                    <i class="el-icon-more"  @click="editTaskProcessAcl()" style="margin:8px 5px 0 0;"></i>
                  </td>
                  <td style="width: 50px;height: 51px;">单位</td>
                  <td style="width: 50px;height: 51px;">工程量</td>
                  <td style="width: 50px;height: 51px;">工期(日)</td>
                  <td style="width: 75px;height: 51px;">开始时间</td>
                  <td style="width: 75px;height: 51px;position:relative;" >
                    <button   style="position:absolute;left:61px;top:0px;"  @click="showPretask"  v-show="!ifShowPretask">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>完成时间</label>
                  </td>
                  <td style="width: 60px;height: 51px;position:relative;" v-show="ifShowPretask">
                    <button   style="position:absolute;left:2px;top:0px;"  @click="showPretask">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <button   style="position:absolute;left:36px;top:0px;" v-show="!ifShowFinishQuanty" @click="showFinishQuanty">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>前置任务</label>
                  </td>
                  <!-- <td style="width: 50px;height: 51px;position:relative;" v-show="ifShowResorce">
                    <button   style="position:absolute;left:2px;top:0px;" v-show="ifShowResorce" @click="showResorce">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <button   style="position:absolute;left:86px;top:0px;" v-show="!ifShowFinishQuanty" @click="showFinishQuanty">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>资源</label>
                  </td> -->
                  <td style="width: 50px;height: 51px; position:relative;"  v-show="ifShowFinishQuanty" >
                    <button   style="position:absolute;left:2px;top:0px;font-size: 12px;" v-show="ifShowFinishQuanty" @click="showFinishQuanty">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <button   style="position:absolute;left:36px;top:0px;" v-show="!ifShowFinishRate" @click="showFinishRate">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>完成量</label>
                  </td>
                  <td colspan="2" style="width: 270px;height: 51px; position:relative;" v-show="ifShowFinishRate">
                    <button   style="position:absolute;left:2px;top:0px;font-size: 12px;" v-show="ifShowFinishRate" @click="showFinishRate">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <label>完成率</label>
                    
                  </td>
                </tr>
              </table>
              </div>
              <EditTaskMenu v-if="showTaskEdit" ref="edittaskmenu"
                :selectedTask="selectedTask"
                :parentNode="parentNode" @deleteTask="deleteTask()"
                @taskeditMenuitemClick="taskeditMenuitemClicked">
              </EditTaskMenu>
              <div  v-for="(item1,index1) in filteredTasks" :key="index1" class="text" style="border-collapse: collapse;">
              <table style="border-collapse: collapse;">
                <tr style="border-collapse: collapse;"  v-show="selectedTaskByDay(item1)" @contextmenu.prevent="showTaskEditMenu($event,item1)">
                  <td style="width:25px;height: 25px;border-collapse: collapse;">
                    <button @mouseup.stop="showTaskDetailMenu($event,item1)"  @mousedown.stop="handleMousedownTask($event,item1)">
                      <i class="el-icon-more"></i>
                    </button>
                  </td>
                  <td style="width:34px;height: 25px;border-collapse: collapse;" >{{ item1.ID }}</td>
                  <td style="width:60px;text-align: left;height: 25px;border-collapse: collapse;" v-show="ifShowSequence">{{index1+1}}</td>
                  <td style="width:100px;height: 25px;border-collapse: collapse;"  v-show="ifShowPart">
                    <input v-model="item1.position" style="width:99px;" :disabled="!permitLevel(item1)"  @change="updateTaskPosition(item1)">
                  </td>
                  <td style="width:170px;height: 25px;border-collapse: collapse;text-align: left;position:relative;" >
                    <button @click="collapseChildTask(item1)" v-show="hasChildTask(item1)&&(!item1.expanded)"
                      style="position:absolute;left:2px;top:5px;">
                      <i class="el-icon-circle-plus-outline" ></i>
                    </button>
                    <button @click="collapseChildTask(item1)" v-show="hasChildTask(item1)&&item1.expanded" style="position:absolute;left:2px;top:5px;">
                      <i class="el-icon-remove-outline" ></i>
                    </button>
                    <input v-model="item1.name" style="width:152px; text-align: left;position:absolute;left:16px;top:2px;"
                      @click="selectTask(item1)" @keydown.enter="addBroTask(item1)" @change="updateTaskName(item1)"
                       class="tasknamecontainer" :disabled="!permitLevel(item1)">
                  </td>

                  <td style="width:50px;height: 25px;border-collapse: collapse;">
                    <input v-model="item1.unitotsk" style="width:49px;height:24px;" :disabled="!permitLevel(item1)" @change="updateTaskOfSql(item1)">
                  </td>
                  <td style="width:50px;height: 25px;border-collapse: collapse;">
                    <input v-model="item1.qtyotsk" style="width:49px;" :disabled="!permitLevel(item1)" @change="updateTaskOfSql(item1)">
                  </td>
                  <td style="width:50px;height: 25px;border-collapse: collapse;">
                    <input v-model="item1.duration" style="width:49px;"  @change="updateLasttime(item1)"
                      @focus="recordLasttimeBefore($event)" :disabled="!permitLevel(item1)">
                  </td>
                  <td style="width:75px;height: 25px;border-collapse: collapse;">
                    <input  :value="computedStarttime(item1)"  style="width:74px;" @change="updateStarttime($event,item1)"
                      @focus="recordStarttimeBefore($event)" :disabled="!permitLevel(item1)">
                  </td>
                  <td style="width:75px;height: 25px;border-collapse: collapse;">
                    <input :value="computedEndtime(item1)" style="width:74px;" @change="updateEndtime($event,item1)"
                      @focus="recordEndtimeBefore($event)" :disabled="!permitLevel(item1)">
                  </td>
                  <td style="width:60px;height: 25px;border-collapse: collapse;" v-show="ifShowPretask">
                    <input style="width:49px;" @change="updateTasktimeByPretaskIDStr($event,item1)"  
                      :value="computedPretaskIDStr(item1)" :disabled="!permitLevel(item1)">
                  </td>
                  <!-- <td style="width:50px;height: 25px;border-collapse: collapse;"  v-show="ifShowResorce">
                    <input v-model="item1.resources" style="width:99px;" :disabled="!permitLevel(item1)"
                     @change="updateTaskOfSql(item1)" class="tasknamecontainer">
                     <button >
                      <i class="el-icon-more"></i>
                     </button>
                  </td> -->
                  <td style="width:50px;height: 25px;border-collapse: collapse;"  v-show="ifShowFinishQuanty">
                    <input v-model="item1.qtyotsked" style="width:49px;" :disabled="!permitLevel(item1)"  @change="updateTaskOfSql(item1)">
                  </td>
                  <td style="width:20px;border-right:none;"  v-show="ifShowFinishRate">
                    <input type="checkbox" v-model="item1.done"  style="display:inline;margin-right:2px;" 
                      @change="toggleTaskDone($event,item1)" :disabled="!permitLevel(item1)">
                  </td>
                  <td style="border-left:none;width: 250px; position:relative; text-align:left;"  v-show="ifShowFinishRate"
                    @mouseup.stop="showTaskDetailMenu($event,item1)"  @mousedown.stop="handleMousedownTask($event,item1)">
                    <label  class="tasknamelabel" style="width:180px;position:absolute; left:10px;z-index: 9;font-weight: bold; text-overflow: ellipsis;overflow: hidden;" 
                      v-show="!item1.done">
                      {{ item1.name }}
                    </label>
                    <el-progress :percentage="computedPercentage(item1)" :color="customColorMethod(item1)" style="display:inline;"></el-progress>    
                  </td>
                </tr>
              </table>
              </div>
            </div>
          </div>
          <div style="width: 1310px;text-align: left;position:relative;" v-show="searchTaskName==''">
            <div style="font-size: 12px; text-align: left; border:1px solid gray;width:1070px;" ref="tablecontainer">
              <div style="margin:0;height:51px;" class="text">
              <table>
                <tr style="text-align: center;font-weight: bold;">
                  <td style="width:25px;">更多</td>
                  <td style="width: 34px; height: 51px;position:relative;">
                    <button   style="position:absolute;left:20px;top:0px;"  @click="showSequence"  v-show="!ifShowSequence">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>ID</label>
                  </td>
                  <td style="width: 60px; height: 51px;position:relative;" v-show="ifShowSequence">
                    <button   style="position:absolute;left:2px;top:0px;"  @click="showSequence">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <button   style="position:absolute;left:46px;top:0px;" v-show="!ifShowPart" @click="showPart">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>序号</label>
                  </td>
                  <td style="width: 100px; height: 51px;position:relative;" v-show="ifShowPart">
                    <button   style="position:absolute;left:2px;top:0px;"  @click="showPart">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <label>部位</label>
                  </td>
                  <td style="width: 170px; height: 51px;">
                    <label for="">任务名称</label>
                    <i class="el-icon-more"  @click="editTaskProcessAcl()" style="margin:8px 5px 0 0;"></i>
                  </td>
                  <td style="width: 50px;height: 51px;">单位</td>
                  <td style="width: 50px;height: 51px;">工程量</td>
                  <td style="width: 50px;height: 51px;">工期(日)</td>
                  <td style="width: 75px;height: 51px;">开始时间</td>
                  <td style="width: 75px;height: 51px;position:relative;" >
                    <button   style="position:absolute;left:61px;top:0px;"  @click="showPretask"  v-show="!ifShowPretask">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>完成时间</label>
                  </td>
                  <td style="width: 60px;height: 51px;position:relative;" v-show="ifShowPretask">
                    <button   style="position:absolute;left:2px;top:0px;"  @click="showPretask">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <button   style="position:absolute;left:45px;top:0px;" v-show="!ifShowFinishQuanty" @click="showFinishQuanty">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>前置任务</label>
                  </td>
                  <!-- <td style="width: 50px;height: 51px;position:relative;" v-show="ifShowResorce">
                    <button   style="position:absolute;left:2px;top:0px;" v-show="ifShowResorce" @click="showResorce">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <button   style="position:absolute;left:86px;top:0px;" v-show="!ifShowFinishQuanty" @click="showFinishQuanty">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>资源</label>
                  </td> -->
                  <td style="width: 50px;height: 51px; position:relative;"  v-show="ifShowFinishQuanty" >
                    <button   style="position:absolute;left:2px;top:0px;font-size: 12px;" v-show="ifShowFinishQuanty" @click="showFinishQuanty">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <button   style="position:absolute;left:36px;top:0px;" v-show="!ifShowFinishRate" @click="showFinishRate">
                      <i class="el-icon-plus" ></i>
                    </button>
                    <label>完成量</label>
                  </td>
                  <td colspan="2" style="width: 270px;height: 51px; position:relative;" v-show="ifShowFinishRate">
                    <button   style="position:absolute;left:2px;top:0px;font-size: 12px;" v-show="ifShowFinishRate" @click="showFinishRate">
                      <i class="el-icon-minus" ></i>
                    </button>
                    <label>完成率</label>
                  </td>
                </tr>
              </table>
              </div>
              <EditTaskMenu v-if="showTaskEdit" ref="edittaskmenu"
                :selectedTask="selectedTask"
                :parentNode="parentNode" @deleteTask="deleteTask()"
                @taskeditMenuitemClick="taskeditMenuitemClicked">
              </EditTaskMenu>
              <div  v-for="(item1,index1) in tasksDataOfLevel1()" :key="index1" class="text" style="border-collapse: collapse;">
              <table style="border-collapse: collapse;">
                <tr style="border-collapse: collapse;"  v-show="selectedTaskByDay(item1)" @contextmenu.prevent="showTaskEditMenu($event,item1)">
                  <td style="width:25px;height: 25px;border-collapse: collapse;">
                    <button @mouseup.stop="showTaskDetailMenu($event,item1)"  @mousedown.stop="handleMousedownTask($event,item1)">
                      <i class="el-icon-more"></i>
                    </button>
                  </td>
                  <td style="width:34px;height: 25px;border-collapse: collapse;" >{{ item1.ID }}</td>
                  <td style="width:60px;text-align: left;height: 25px;border-collapse: collapse;" v-show="ifShowSequence">{{index1+1}}</td>
                  <td style="width:100px;height: 25px;border-collapse: collapse;"  v-show="ifShowPart">
                    <input v-model="item1.position" style="width:99px;" :disabled="!permitLevel(item1)"  @change="updateTaskPosition(item1)">
                  </td>
                  <td style="width:170px;height: 25px;border-collapse: collapse;text-align: left;position:relative;" >
                    <button @click="collapseChildTask(item1)" v-show="hasChildTask(item1)&&(!item1.expanded)"
                      style="position:absolute;left:2px;top:5px;">
                      <i class="el-icon-circle-plus-outline" ></i>
                    </button>
                    <button @click="collapseChildTask(item1)" v-show="hasChildTask(item1)&&item1.expanded" style="position:absolute;left:2px;top:5px;">
                      <i class="el-icon-remove-outline" ></i>
                    </button>
                    <input v-model="item1.name" style="width:152px; text-align: left;position:absolute;left:16px;top:2px;"
                      @click="selectTask(item1)" @keydown.enter="addBroTask(item1)" @change="updateTaskName(item1)"
                       class="tasknamecontainer" :disabled="!permitLevel(item1)">
                  </td>

                  <td style="width:50px;height: 25px;border-collapse: collapse;">
                    <input v-model="item1.unitotsk" style="width:49px;height:24px;" :disabled="!permitLevel(item1)" @change="updateTaskOfSql(item1)">
                  </td>
                  <td style="width:50px;height: 25px;border-collapse: collapse;">
                    <input v-model="item1.qtyotsk" style="width:49px;" :disabled="!permitLevel(item1)" @change="updateTaskOfSql(item1)">
                  </td>
                  <td style="width:50px;height: 25px;border-collapse: collapse;">
                    <input v-model="item1.duration" style="width:49px;"  @change="updateLasttime(item1)"
                      @focus="recordLasttimeBefore($event)" :disabled="!permitLevel(item1)">
                  </td>
                  <td style="width:75px;height: 25px;border-collapse: collapse;">
                    <input  :value="computedStarttime(item1)"  style="width:74px;" @change="updateStarttime($event,item1)"
                      @focus="recordStarttimeBefore($event)" :disabled="!permitLevel(item1)">
                  </td>
                  <td style="width:75px;height: 25px;border-collapse: collapse;">
                    <input :value="computedEndtime(item1)" style="width:74px;" @change="updateEndtime($event,item1)"
                      @focus="recordEndtimeBefore($event)" :disabled="!permitLevel(item1)">
                  </td>
                  <td style="width:60px;height: 25px;border-collapse: collapse;" v-show="ifShowPretask">
                    <input style="width:49px;" @change="updateTasktimeByPretaskIDStr($event,item1)"  
                      :value="computedPretaskIDStr(item1)" :disabled="!permitLevel(item1)">
                  </td>
                  <!-- <td style="width:50px;height: 25px;border-collapse: collapse;"  v-show="ifShowResorce">
                    <input v-model="item1.resources" style="width:99px;" :disabled="!permitLevel(item1)"
                     @change="updateTaskOfSql(item1)" class="tasknamecontainer">
                     <button>
                      <i class="el-icon-more"></i>
                     </button>
                  </td> -->
                  <td style="width:50px;height: 25px;border-collapse: collapse;"  v-show="ifShowFinishQuanty">
                    <input v-model="item1.qtyotsked" style="width:49px;" :disabled="!permitLevel(item1)"  @change="updateTaskOfSql(item1)">
                  </td>
                  <td style="width:20px;border-right:none;"  v-show="ifShowFinishRate">
                    <input type="checkbox" v-model="item1.done"  style="display:inline;margin-right:2px;" 
                      @change="toggleTaskDone($event,item1)" :disabled="!permitLevel(item1)">
                  </td>
                  <td style="border-left:none;width: 250px; position:relative; text-align:left;"  v-show="ifShowFinishRate"
                    @mouseup.stop="showTaskDetailMenu($event,item1)"  @mousedown.stop="handleMousedownTask($event,item1)">
                    <label  class="tasknamelabel" style="width:180px;position:absolute; left:10px;z-index: 9;font-weight: bold; text-overflow: ellipsis;overflow: hidden;" 
                      v-show="!item1.done">
                      {{ item1.name }}
                    </label>
                    <el-progress :percentage="computedPercentage(item1)" :color="customColorMethod(item1)" style="display:inline;"></el-progress>    
                  </td>
                </tr>
              </table>
              <div  v-for="(item2,index2) in tasksDataOfLevelN(2,item1)" :key="index2" style=" border-collapse: collapse;" 
                v-show="item1.expanded">
                <table style=" border-collapse: collapse;">
                  <tr style=" border-collapse: collapse;"  v-show="selectedTaskByDay(item2)" @contextmenu.prevent="showTaskEditMenu($event,item2)">
                    <td style="width:25px; border-collapse: collapse;">
                      <button @mouseup.stop="showTaskDetailMenu($event,item2)"  @mousedown.stop="handleMousedownTask($event,item2)">
                        <i class="el-icon-more"></i>
                      </button>
                    </td>
                    <td style="width:34px;height: 25px;border-collapse: collapse;">{{ item2.ID }}</td>
                    <td style="width:60px;text-align: left; border-collapse: collapse;" v-show="ifShowSequence">{{`${index1+1}.${index2+1}`}}</td>
                    <td style="width:100px; border-collapse: collapse;" v-show="ifShowPart">
                      <input v-model="item2.position" style="width:99px; border-collapse: collapse;" :disabled="!permitLevel(item2)"  @change="updateTaskPosition(item2)">
                    </td>
                    <td style="width:170px; border-collapse: collapse;text-align: left;position:relative;">
                      <button @click="collapseChildTask(item2)" v-show="hasChildTask(item2)&&(!item2.expanded)" style="position:absolute;left:6px;top:5px;">
                        <i class="el-icon-circle-plus-outline" ></i>
                      </button>
                      <button @click="collapseChildTask(item2)" v-show="hasChildTask(item2)&&item2.expanded"  style="position:absolute;left:6px;top:5px;">
                        <i class="el-icon-remove-outline" ></i>
                      </button>
                      <input  class="tasknamecontainer" v-model="item2.name" style="width:147px; text-align: left;border-collapse: collapse; 
                        position:absolute;left:20px;top:2px;" @click="selectTask(item2)" @keydown.enter="addBroTask(item2)"
                        @change="updateTaskName(item2)" :disabled="!permitLevel(item2)">
                    </td>

                    <td style="width:50px; border-collapse: collapse;">
                      <input v-model="item2.unitotsk" style="width:49px; border-collapse: collapse;" :disabled="!permitLevel(item2)"
                        @change="updateTaskOfSql(item2)">
                    </td>
                    <td style="width:50px; border-collapse: collapse;">
                      <input v-model="item2.qtyotsk" style="width:49px;" :disabled="!permitLevel(item2)" @change="updateTaskOfSql(item2)">
                    </td>
                    <td style="width:50px; border-collapse: collapse;">
                      <input v-model="item2.duration" style="width:49px; border-collapse: collapse;"  @change="updateLasttime(item2)"
                        @focus="recordLasttimeBefore($event)" :disabled="!permitLevel(item2)">
                    </td>
                    <td style="width:75px; border-collapse: collapse;">
                      <input :value="computedStarttime(item2)"  style="width:74px; border-collapse: collapse;"
                       @change="updateStarttime($event,item2)"  @focus="recordStarttimeBefore($event)" :disabled="!permitLevel(item2)">
                    </td>
                    <td style="width:75px; border-collapse: collapse;">
                      <input :value="computedEndtime(item2)" style="width:74px; border-collapse: collapse;"
                       @change="updateEndtime($event,item2)"  @focus="recordEndtimeBefore($event)" :disabled="!permitLevel(item2)">
                    </td>
                    <td style="width:60px; border-collapse: collapse;" v-show="ifShowPretask">
                      <input  style="width:49px; border-collapse: collapse;" 
                        @change="updateTasktimeByPretaskIDStr($event,item2)"   :value="computedPretaskIDStr(item2)" :disabled="!permitLevel(item2)">
                      <!-- <label>{{ computedPretaskIDStr(item2) }}</label> -->
                    </td>
                    <!-- <td style="width:50px; border-collapse: collapse;"  v-show="ifShowResorce">
                      <input v-model="item2.resources" style="width:99px;" :disabled="!permitLevel(item2)"
                       @change="updateTaskOfSql(item2)" class="tasknamecontainer">
                    </td> -->
                    <td style="width:50px; border-collapse: collapse;"  v-show="ifShowFinishQuanty">
                      <input v-model="item2.qtyotsked" style="width:49px;" :disabled="!permitLevel(item2)" @change="updateTaskOfSql(item2)">
                    </td>
                    <td style="width:20px;border-right:none;"  v-show="ifShowFinishRate">
                      <input type="checkbox" v-model="item2.done"  style="display:inline;margin-right:2px;" 
                        @change="toggleTaskDone($event,item2)" :disabled="!permitLevel(item2)">
                    </td>
                    <td style="border-left:none;width: 250px; position:relative; text-align:left;"  v-show="ifShowFinishRate"
                      @mouseup.stop="showTaskDetailMenu($event,item2)"  @mousedown.stop="handleMousedownTask($event,item2)">
                      <label  class="tasknamelabel" style="width:180px;position:absolute; left:10px;z-index: 9;font-weight: bold; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;" 
                        v-show="!item2.done">
                        {{ item2.name }}
                      </label>
                      <el-progress :percentage="computedPercentage(item2)" :color="customColorMethod(item2)" style="display:inline;"></el-progress>    
                    </td>
                  </tr>
                </table>
                <div v-for="(item3,index3) in tasksDataOfLevelN(3,item2)" :key="index3"   v-show="item2.expanded">
                  <table style="border-collapse: collapse;">
                    <tr style="border-collapse: collapse;"  v-show="selectedTaskByDay(item3)" @contextmenu.prevent="showTaskEditMenu($event,item3)">
                      <td style="width:25px;">
                        <button @mouseup.stop="showTaskDetailMenu($event,item3)"  @mousedown.stop="handleMousedownTask($event,item3)">
                          <i class="el-icon-more"></i>
                        </button>
                      </td>
                      <td style="width:34px;height: 25px;border-collapse: collapse;">{{ item3.ID }}</td>
                      <td style="width:60px;text-align: left;" v-show="ifShowSequence">{{`${index1+1}.${index2+1}.${index3+1}`}}</td>
                      <td style="width:100px;" v-show="ifShowPart">
                        <input v-model="item3.position" style="width:99px;" :disabled="!permitLevel(item3)"  @change="updateTaskPosition(item3)">
                      </td>
                      <td style="width:170px;text-align: left; left; position:relative;">
                        <button @click="collapseChildTask(item3)" v-show="hasChildTask(item3)&&(!item3.expanded)" style="position:absolute;left:10px;top:5px;">
                          <i class="el-icon-circle-plus-outline" ></i>
                        </button>
                        <button @click="collapseChildTask(item3)" v-show="hasChildTask(item3)&&item3.expanded" style="position:absolute;left:10px;top:5px;">
                          <i class="el-icon-remove-outline" ></i>
                        </button>
                        <input  class="tasknamecontainer" v-model="item3.name" style="width:142px; text-align: left; position:absolute;left:24px;top:2px;"
                          @click="selectTask(item3)" @keydown.enter="addBroTask(item3)" @change="updateTaskName(item3)"
                          :disabled="!permitLevel(item3)">
                      </td>

                      <td style="width:50px;">
                        <input v-model="item3.unitotsk" style="width:49px;" :disabled="!permitLevel(item3)" @change="updateTaskOfSql(item3)">
                      </td>
                      <td style="width:50px;">
                        <input v-model="item3.qtyotsk" style="width:49px;" :disabled="!permitLevel(item3)" @change="updateTaskOfSql(item3)">
                      </td>
                      <td style="width:50px;">
                        <input v-model="item3.duration" style="width:49px;"  @change="updateLasttime(item3)"
                          @focus="recordLasttimeBefore($event)" :disabled="!permitLevel(item3)">
                      </td>
                      <td style="width:75px;">
                        <input :value="computedStarttime(item3)"  style="width:74px;" @change="updateStarttime($event,item3)"
                          @focus="recordStarttimeBefore($event)" :disabled="!permitLevel(item3)">
                      </td>
                      <td style="width:75px;">
                        <input :value="computedEndtime(item3)" style="width:74px;" @change="updateEndtime($event,item3)"
                          @focus="recordEndtimeBefore($event)" :disabled="!permitLevel(item3)">
                      </td>
                      <td style="width:60px;" v-show="ifShowPretask">
                        <input style="width:49px;" @change="updateTasktimeByPretaskIDStr($event,item3)"
                          :value="computedPretaskIDStr(item3)" :disabled="!permitLevel(item3)">
                      </td>
                      <!-- <td style="width:50px;" v-show="ifShowResorce">
                        <input v-model="item3.resources" style="width:99px;" :disabled="!permitLevel(item3)"
                         @change="updateTaskOfSql(item3)" class="tasknamecontainer">
                      </td> -->
                      <td style="width:50px;"  v-show="ifShowFinishQuanty">
                        <input v-model="item3.qtyotsked" style="width:49px;" :disabled="!permitLevel(item3)" @change="updateTaskOfSql(item3)">
                      </td>
                      <td style="width:20px;border-right:none;"  v-show="ifShowFinishRate">
                        <input type="checkbox" v-model="item3.done"  style="display:inline;margin-right:2px;" 
                          @change="toggleTaskDone($event,item3)" :disabled="!permitLevel(item3)">
                      </td>
                      <td style="border-left:none;width: 250px; position:relative; text-align:left;"  v-show="ifShowFinishRate"
                        @mouseup.stop="showTaskDetailMenu($event,item3)"  @mousedown.stop="handleMousedownTask($event,item3)">
                        <label  class="tasknamelabel" style="width:180px;position:absolute; left:10px;z-index: 9;font-weight: bold; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;" 
                          v-show="!item3.done">
                          {{ item3.name }}
                        </label>
                        <el-progress :percentage="computedPercentage(item3)" :color="customColorMethod(item3)" style="display:inline;"></el-progress>    
                      </td>
                    </tr>
                  </table>
                  <div   v-for="(item4,index4) in tasksDataOfLevelN(4,item3)" :key="index4"   v-show="item3.expanded">
                    <table>
                      <tr v-show="selectedTaskByDay(item4)" @contextmenu.prevent="showTaskEditMenu($event,item4)">
                        <td style="width:25px;">
                          <button @mouseup.stop="showTaskDetailMenu($event,item4)"  @mousedown.stop="handleMousedownTask($event,item4)">
                            <i class="el-icon-more"></i>
                          </button>
                        </td>
                        <td style="width:34px;height: 25px;border-collapse: collapse;">{{ item4.ID }}</td>
                        <td style="width:60px;text-align: left;" v-show="ifShowSequence">{{`${index1+1}.${index2+1}.${index3+1}.${index4+1}`}}</td>
                        <td style="width:100px;" v-show="ifShowPart">
                          <input v-model="item4.position" style="width:99px;" :disabled="!permitLevel(item4)"  @change="updateTaskPosition(item4)">
                        </td>
                        <td style="width:170px;text-align: left;padding-left:8px; left;position:relative;">
                          <button @click="collapseChildTask(item4)" v-show="hasChildTask(item4)&&(!item4.expanded)"
                          style="position:absolute;left:14px;top:5px;">
                            <i class="el-icon-circle-plus-outline" ></i>
                          </button>
                          <button @click="collapseChildTask(item4)" v-show="hasChildTask(item4)&&item4.expanded" 
                          style="position:absolute;left:14px;top:5px;"> 
                            <i class="el-icon-remove-outline" ></i>
                          </button>
                          <input  class="tasknamecontainer" v-model="item4.name" style="width:137px; text-align: left;position:absolute;left:28px;top:2px;"
                            @click="selectTask(item4)" @keydown.enter="addBroTask(item4)" @change="updateTaskName(item4)"
                            :disabled="!permitLevel(item4)">
                        </td>

                        <td style="width:50px;">
                          <input v-model="item4.unitotsk" style="width:49px;" :disabled="!permitLevel(item4)" @change="updateTaskOfSql(item4)">
                        </td>
                        <td style="width:50px;">
                          <input v-model="item4.qtyotsk" style="width:49px;" :disabled="!permitLevel(item4)" @change="updateTaskOfSql(item4)">
                        </td>
                        <td style="width:50px;">
                          <input v-model="item4.duration" style="width:49px;"  @change="updateLasttime(item4)"
                            @focus="recordLasttimeBefore($event)" :disabled="!permitLevel(item4)">
                        </td>
                        <td style="width:75px;">
                          <input  :value="computedStarttime(item4)"  style="width:74px;" @change="updateStarttime($event,item4)"  
                            @focus="recordStarttimeBefore($event)" :disabled="!permitLevel(item4)">
                        </td>
                        <td style="width:75px;">
                          <input :value="computedEndtime(item4)" style="width:74px;" @change="updateEndtime($event,item4)"  
                            @focus="recordEndtimeBefore($event)" :disabled="!permitLevel(item4)">
                        </td>
                        <td style="width:60px;" v-show="ifShowPretask">
                          <input style="width:49px;" @change="updateTasktimeByPretaskIDStr($event,item4)"  
                            :value="computedPretaskIDStr(item4)" :disabled="!permitLevel(item4)">
                        </td>
                        <!-- <td style="width:50px;" v-show="ifShowResorce">
                          <input v-model="item4.resources" style="width:99px;" :disabled="!permitLevel(item4)"
                            @change="updateTaskOfSql(item4)" class="tasknamecontainer">
                        </td> -->
                        <td style="width:50px;"  v-show="ifShowFinishQuanty">
                          <input v-model="item4.qtyotsked" style="width:49px;" :disabled="!permitLevel(item4)" @change="updateTaskOfSql(item4)">
                        </td>
                        <td style="width:20px;border-right:none;"  v-show="ifShowFinishRate">
                          <input type="checkbox" v-model="item4.done"  style="display:inline;margin-right:2px;" 
                            @change="toggleTaskDone($event,item4)" :disabled="!permitLevel(item4)">
                        </td>
                        <td style="border-left:none;width: 250px; position:relative; text-align:left;"  v-show="ifShowFinishRate"
                         @mouseup.stop="showTaskDetailMenu($event,item4)"  @mousedown.stop="handleMousedownTask($event,item4)">
                          <label  class="tasknamelabel" style="width:180px;white-space: nowrap;position:absolute; left:10px;z-index: 9;font-weight: bold;  text-overflow: ellipsis;overflow: hidden;white-space: nowrap;" 
                            v-show="!item4.done">
                            {{ item4.name }}
                          </label>
                          <el-progress :percentage="computedPercentage(item4)" :color="customColorMethod(item4)" style="display:inline;"></el-progress>    
                        </td>
                      </tr>
                    </table>
                    <div   v-for="(item5,index5) in tasksDataOfLevelN(5,item4)" :key="index5"   v-show="item4.expanded">
                      <table>
                        <tr v-show="selectedTaskByDay(item5)" @contextmenu.prevent="showTaskEditMenu($event,item5)">
                          <td style="width:25px;">
                            <button @mouseup.stop="showTaskDetailMenu($event,item5)"  @mousedown.stop="handleMousedownTask($event,item5)">
                              <i class="el-icon-more"></i>
                            </button>
                          </td>
                          <td style="width:34px;height: 25px;border-collapse: collapse;">{{ item5.ID }}</td>
                          <td style="width:60px;text-align: left;" v-show="ifShowSequence">{{`${index1+1}.${index2+1}.${index3+1}.${index4+1}.${index5+1}`}}</td>
                          <td style="width:100px;" v-show="ifShowPart">
                            <input v-model="item5.position" style="width:99px;" :disabled="!permitLevel(item5)"  @change="updateTaskPosition(item5)">
                          </td>
                          <td style="width:170px;text-align: left; left;position:relative;">
                            <button @click="collapseChildTask(item5)" v-show="hasChildTask(item5)&&(!item5.expanded)" style="position:absolute;left:18px;top:5px;">
                              <i class="el-icon-circle-plus-outline" ></i>
                            </button>
                            <button @click="collapseChildTask(item5)" v-show="hasChildTask(item5)&&item5.expanded" style="position:absolute;left:18px;top:5px;">
                              <i class="el-icon-remove-outline" ></i>
                            </button>
                            <input  class="tasknamecontainer" v-model="item5.name" style="width:132px; text-align: left;position:absolute;left:32px;top:2px;"
                              @click="selectTask(item5)" @keydown.enter="addBroTask(item5)" @change="updateTaskName(item5)"
                              :disabled="!permitLevel(item5)">
                          </td>

                          <td style="width:50px;">
                            <input v-model="item5.unitotsk" style="width:49px;" :disabled="!permitLevel(item5)" @change="updateTaskOfSql(item5)">
                          </td>
                          <td style="width:50px;">
                            <input v-model="item5.qtyotsk" style="width:49px;" :disabled="!permitLevel(item5)" @change="updateTaskOfSql(item5)">
                          </td>
                          <td style="width:50px;">
                            <input v-model="item5.duration" style="width:49px;"  @change="updateLasttime(item5)"  
                              @focus="recordLasttimeBefore($event)" :disabled="!permitLevel(item5)">
                          </td>
                          <td style="width:75px;">
                            <input  :value="computedStarttime(item5)"  style="width:74px;" @change="updateStarttime($event,item5)"  
                              @focus="recordStarttimeBefore($event)" :disabled="!permitLevel(item5)">
                          </td>
                          <td style="width:75px;">
                            <input :value="computedEndtime(item5)" style="width:74px;" @change="updateEndtime($event,item5)" 
                             @focus="recordEndtimeBefore($event)" :disabled="!permitLevel(item5)">
                          </td>
                          <td style="width:60px;" v-show="ifShowPretask" >
                            <input  style="width:49px;" @change="updateTasktimeByPretaskIDStr($event,item5)" 
                              :value="computedPretaskIDStr(item5)" :disabled="!permitLevel(item5)">
                          </td>
                          <!-- <td style="width:50px;" v-show="ifShowResorce">
                            <input v-model="item5.resources" style="width:99px;" :disabled="!permitLevel(item5)" 
                              @change="updateTaskOfSql(item5)" class="tasknamecontainer">
                          </td> -->
                          <td style="width:50px;"  v-show="ifShowFinishQuanty">
                            <input v-model="item5.qtyotsked" style="width:49px;" :disabled="!permitLevel(item5)" @change="updateTaskOfSql(item5)">
                          </td>
                          <td style="width:20px;border-right:none;"  v-show="ifShowFinishRate">
                            <input type="checkbox" v-model="item5.done"  style="display:inline;margin-right:2px;" 
                              @change="toggleTaskDone($event,item5)" :disabled="!permitLevel(item5)">
                          </td>
                          <td style="border-left:none;width: 250px; position:relative; text-align:left;"  v-show="ifShowFinishRate"
                           @mouseup.stop="showTaskDetailMenu($event,item5)"  @mousedown.stop="handleMousedownTask($event,item5)">
                            <label  class="tasknamelabel" style="width:180px;position:absolute; left:10px;z-index: 9;font-weight: bold; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;" 
                              v-show="!item5.done">
                              {{ item5.name }}
                            </label>
                            <el-progress :percentage="computedPercentage(item5)" :color="customColorMethod(item5)" style="display:inline;"></el-progress>    
                          </td>
                        </tr>
                      </table>
                      <div v-for="(item6,index6) in tasksDataOfLevelN(6,item5)" :key="index6"   v-show="item5.expanded">
                        <table>
                          <tr v-show="selectedTaskByDay(item6)" @contextmenu.prevent="showTaskEditMenu($event,item6)">
                            <td style="width:25px;">
                              <button @mouseup.stop="showTaskDetailMenu($event,item6)"  @mousedown.stop="handleMousedownTask($event,item6)">
                                <i class="el-icon-more"></i>
                              </button>
                            </td>
                            <td style="width:34px;height: 25px;border-collapse: collapse;">{{ item6.ID }}</td>
                            <td style="width:60px;text-align: left;" v-show="ifShowSequence">{{`${index1+1}.${index2+1}.${index3+1}.${index4+1}.${index5+1}.${index6+1}`}}</td>
                            <td style="width:100px;" v-show="ifShowPart">
                              <input v-model="item6.position" style="width:99px;" :disabled="!permitLevel(item6)"  @change="updateTaskPosition(item6)">
                            </td>
                            <td style="width:170px;text-align: left; left;position:relative;">
                              <input  class="tasknamecontainer" v-model="item6.name" style="width:127px; text-align: left;position:absolute;left:36px;top:2px;"
                                @click="selectTask(item6)" @keydown.enter="addBroTask(item6)" 
                                @change="updateTaskName(item6)" :disabled="!permitLevel(item6)">
                            </td>

                            <td style="width:50px;">
                              <input v-model="item6.unitotsk" style="width:49px;" @change="updateTaskOfSql(item6)">
                            </td>
                            <td style="width:50px;">
                              <input v-model="item6.qtyotsk" style="width:49px;" :disabled="!permitLevel(item6)" @change="updateTaskOfSql(item6)">
                            </td>
                            <td style="width:50px;">
                              <input v-model="item6.duration" style="width:49px;"  @change="updateLasttime(item6)" 
                                @focus="recordLasttimeBefore($event)" :disabled="!permitLevel(item6)">
                            </td>
                            <td style="width:75px;">
                              <input :value="computedStarttime(item6)"  style="width:74px;" @change="updateStarttime($event,item6)"   
                                @focus="recordStarttimeBefore($event)" :disabled="!permitLevel(item6)">
                            </td>
                            <td style="width:75px;">
                              <input :value="computedEndtime(item6)" style="width:74px;" @change="updateEndtime($event,item6)"
                                @focus="recordEndtimeBefore($event)" :disabled="!permitLevel(item6)">
                            </td>
                            <td style="width:60px;" v-show="ifShowPretask" >
                              <input style="width:49px;" @change="updateTasktimeByPretaskIDStr($event,item6)" :value="computedPretaskIDStr(item6)">
                            </td>
                            <!-- <td style="width:50px;" v-show="ifShowResorce">
                              <input v-model="item6.resources" style="width:99px;" :disabled="!permitLevel(item6)" 
                                class="tasknamecontainer" @change="updateTaskOfSql(item6)">
                            </td> -->
                            <td style="width:50px;"  v-show="ifShowFinishQuanty">
                              <input v-model="item6.qtyotsked" style="width:49px;" :disabled="!permitLevel(item6)" @change="updateTaskOfSql(item6)">
                            </td>
                            <td style="width:20px;border-right:none;"  v-show="ifShowFinishRate">
                              <input type="checkbox" v-model="item6.done"  style="display:inline;margin-right:2px;" 
                                @change="toggleTaskDone($event,item6)" :disabled="!permitLevel(item6)">
                            </td>
                            <td style="border-left:none; width: 250px; position:relative;text-align:left;"  v-show="ifShowFinishRate"
                             @mouseup.stop="showTaskDetailMenu($event,item6)"  @mousedown.stop="handleMousedownTask($event,item6)">
                              <label class="tasknamelabel" style="width:180px;position:absolute; left:10px;z-index: 9;font-weight: bold; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"
                                v-show="!item6.done">
                                {{ item6.name }}
                              </label>
                              <el-progress :percentage="computedPercentage(item6)" :color="customColorMethod(item6)" style="display:inline;"></el-progress>    
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </el-col>
      </div>
      <div class="resize-divider" ref="divider" @mousedown="startResize"></div>
      <div class="resize-right" ref="rightCol">
        <!-- <el-col  style="width:calc(100% - 3px); overflow-x: auto;  overflow-y: hidden; height:100%; text-align: left; border-right:1px solid gray;"> -->
        <el-col  style="width:calc(100% - 3px);height:100%; text-align: left; border-right:1px solid gray;overflow:auto;">
          <div style="width: 800px; text-align: left;"  v-show="ViewOption==2">
            <!-- <el-col style="width:calc(90% - 400px);margin-left:10px;">
            </el-col>
            <el-col style="border:none;text-align: center; width:300px;">
              <div style="margin-top:50px;">
                <div style="display: inline-block;">
                  <el-progress type="circle" :percentage="crOfTasks" :width="elprogressWith"></el-progress>
                  <div>截止今天</div>
                </div>
                <div style="display: inline-block; margin-left:50px">
                  <el-progress type="circle" :percentage="crOfTasks" :width="elprogressWith"></el-progress>
                  <div>本周完成率</div>
                </div>
                <br><br>
                <div style="display: inline-block;">
                  <el-progress type="circle" :percentage="crOfTasks" :width="elprogressWith"></el-progress>
                  <div>本月完成率</div>
                </div>
                <div style="display: inline-block; margin-left:50px">
                  <el-progress type="circle" :percentage="crOfTasks" :width="elprogressWith"></el-progress>
                  <div>全项目完成率</div>
                </div>
              </div>
            </el-col> -->
          </div>
          <div style="width: 5000px; text-align: left; border:1px solid gray;"  v-show="ViewOption==1">
            <div  class="text">
              <table style="overflow: auto;" v-show="ViewOption==1">  
                <thead @contextmenu.prevent="timeRulerEditMenu($event)">
                <tr  style="height: 25px;">
                  <!-- //class="table-header-colname" -->
                  <td v-for="item in monthArray" :key="item.month" :colspan="item.days" 
                    style="min-width: 80px;border:gray solid 1px; padding:0 2px;height:25px; font-weight:bold;">
                     {{ item.month }}
                  </td>
                </tr>
                <tr  style="height: 25px;">
                  <td v-for="item in dayArray" :key="item.month" @click="inputDate(item)" 
                    style="border-bottom:none; padding:0 2px;height:25px;min-width:20px;">
                    {{ item.day }}
                  </td>
                </tr>
                </thead>
                <EditProjectMenu 
                v-if="showTimeRulerEditMenu"
                :projeditMenuitems="timeRulerEditMenuitems" 
                :event="event" 
                :parentNode="parentNode"
                @projeditMenuitemClick="timeRulereditMenuitemClicked">
                </EditProjectMenu>
                <EditTimeRuler
                  v-if="showEditTimeRuler"
                  @cancelEditPro="cancelEditPro"
                  @closeEditProj="closeEditProj">
                </EditTimeRuler>
              </table>
            </div>
            <div v-for="(data1,index1) in tasksDataOfLevel1()" :key="index1" class="text">
              <table>
                <tr v-show="selectedTaskByDay(data1)" style="position:relative;">
                  <td :style="{position: 'absolute', left: ganttLeft(data1) + 'px',border:'none',top: '8px'}">
                    <div style="position:relative;border:none;">
                      <div :style="{ 'min-width': ganttLenth(data1)  + 'px',
                        backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data1)  + 'px',height:'5px', }">
                      </div>
                      <div :style="{ 'min-width': completedLength(data1)  + 'px', 'margin-top': '3px',
                        backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data1)  + 'px',height:'3px', }">
                      </div>
                    </div>
                  </td>
                  <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                    border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                  </td>
                </tr>
              </table>
              <div   v-for="(data2,index2) in tasksDataOfLevelN(2,data1)" :key="index2" v-show="data1.expanded">
                <table>
                  <tr v-show="selectedTaskByDay(data2)" style="position:relative;">
                    <td :style="{position: 'absolute', left: ganttLeft(data2) + 'px',border:'none',top: '10px'}">
                      <div style="position:relative;border:none;">
                        <div :style="{ 'min-width': ganttLenth(data2)  + 'px',
                          backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data2)  + 'px',height:'5px', }">
                        </div>
                        <div :style="{ 'min-width': completedLength(data2)  + 'px', 'margin-top': '3px',
                          backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data2)  + 'px',height:'3px', }">
                        </div>
                      </div>
                    </td>
                      <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                        border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                      </td>
                  </tr>
                </table>
                <div   v-for="(data3,index3) in tasksDataOfLevelN(3,data2)" :key="index3"  v-show="data2.expanded">
                  <table>
                    <tr v-show="selectedTaskByDay(data3)" style="position:relative;">
                      <td :style="{position: 'absolute', left: ganttLeft(data3) + 'px',border:'none',top: '10px'}">
                        <div style="position:relative;border:none;">
                          <div :style="{ 'min-width': ganttLenth(data3)  + 'px',
                            backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data3)  + 'px',height:'5px', }">
                          </div>
                          <div :style="{ 'min-width': completedLength(data3)  + 'px', 'margin-top': '3px',
                            backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data3)  + 'px',height:'3px', }">
                          </div>
                        </div>
                      </td>
                        <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                          border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                          <!-- <div style="position: relative;">
                          </div> -->
                        </td>
                    </tr>
                  </table>
                  <div   v-for="(data4,index4) in tasksDataOfLevelN(4,data3)" :key="index4"  v-show="data3.expanded">
                    <table>
                      <tr v-show="selectedTaskByDay(data4)" style="position:relative;">
                        <td :style="{position: 'absolute', left: ganttLeft(data4) + 'px',border:'none',top: '10px'}">
                          <div style="position:relative;border:none;">
                            <div :style="{ 'min-width': ganttLenth(data4)  + 'px',
                              backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data4)  + 'px',height:'5px', }">
                            </div>
                            <div :style="{ 'min-width': completedLength(data4)  + 'px', 'margin-top': '3px',
                              backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data4)  + 'px',height:'3px', }">
                            </div>
                          </div>
                        </td>
                          <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                            border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                            <!-- <div style="position: relative;">
                            </div> -->
                          </td>
                      </tr>
                    </table>
                    <div   v-for="(data5,index5) in tasksDataOfLevelN(5,data4)" :key="index5"  v-show="data4.expanded">
                      <table>
                        <tr v-show="selectedTaskByDay(data5)" style="position:relative;">
                          <td :style="{position: 'absolute', left: ganttLeft(data5) + 'px',border:'none',top: '10px'}">
                            <div style="position:relative;border:none;">
                              <div :style="{ 'min-width': ganttLenth(data5)  + 'px',
                                backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data5)  + 'px',height:'5px', }">
                              </div>
                              <div :style="{ 'min-width': completedLength(data5)  + 'px', 'margin-top': '3px',
                                backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data5)  + 'px',height:'3px', }">
                              </div>
                            </div>
                          </td>
                            <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                              border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                              <!-- <div style="position: relative;">
                              </div> -->
                            </td>
                        </tr>
                      </table>
                      <div   v-for="(data6,index6) in tasksDataOfLevelN(6,data5)" :key="index6"  v-show="data5.expanded">
                        <table>
                          <tr v-show="selectedTaskByDay(data6)" style="position:relative;">
                            <td :style="{position: 'absolute', left: ganttLeft(data6) + 'px',border:'none',top: '10px'}">
                              <div style="position:relative;border:none;">
                                <div :style="{ 'min-width': ganttLenth(data6)  + 'px',
                                  backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data6)  + 'px',height:'5px', }">
                                </div>
                                <div :style="{ 'min-width': completedLength(data6)  + 'px', 'margin-top': '3px',
                                  backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data6)  + 'px',height:'3px', }">
                                </div>
                              </div>
                            </td>
                              <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                                border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                              </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
            <!-- <div v-for="(data1,index1) in tasksDataOfLevel1()" :key="index1" class="text" v-show="searchTaskName!==''">
              <table>
                <tr v-show="selectedTaskByDay(data1)" style="position:relative;">
                  <td :style="{position: 'absolute', left: ganttLeft(data1) + 'px',border:'none',top: '8px'}">
                    <div style="position:relative;border:none;">
                      <div :style="{ 'min-width': ganttLenth(data1)  + 'px',
                        backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data1)  + 'px',height:'5px', }">
                      </div>
                      <div :style="{ 'min-width': completedLength(data1)  + 'px', 'margin-top': '3px',
                        backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data1)  + 'px',height:'3px', }">
                      </div>
                    </div>
                  </td>
                  <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                    border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                  </td>
                </tr>
              </table>
              <div   v-for="(data2,index2) in tasksDataOfLevelN(2,data1)" :key="index2" v-show="data1.expanded">
                <table>
                  <tr v-show="selectedTaskByDay(data2)" style="position:relative;">
                    <td :style="{position: 'absolute', left: ganttLeft(data2) + 'px',border:'none',top: '10px'}">
                      <div style="position:relative;border:none;">
                        <div :style="{ 'min-width': ganttLenth(data2)  + 'px',
                          backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data2)  + 'px',height:'5px', }">
                        </div>
                        <div :style="{ 'min-width': completedLength(data2)  + 'px', 'margin-top': '3px',
                          backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data2)  + 'px',height:'3px', }">
                        </div>
                      </div>
                    </td>
                      <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                        border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                      </td>
                  </tr>
                </table>
                <div   v-for="(data3,index3) in tasksDataOfLevelN(3,data2)" :key="index3"  v-show="data2.expanded">
                  <table>
                    <tr v-show="selectedTaskByDay(data3)" style="position:relative;">
                      <td :style="{position: 'absolute', left: ganttLeft(data3) + 'px',border:'none',top: '10px'}">
                        <div style="position:relative;border:none;">
                          <div :style="{ 'min-width': ganttLenth(data3)  + 'px',
                            backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data3)  + 'px',height:'5px', }">
                          </div>
                          <div :style="{ 'min-width': completedLength(data3)  + 'px', 'margin-top': '3px',
                            backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data3)  + 'px',height:'3px', }">
                          </div>
                        </div>
                      </td>
                        <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                          border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                        </td>
                    </tr>
                  </table>
                  <div   v-for="(data4,index4) in tasksDataOfLevelN(4,data3)" :key="index4"  v-show="data3.expanded">
                    <table>
                      <tr v-show="selectedTaskByDay(data4)" style="position:relative;">
                        <td :style="{position: 'absolute', left: ganttLeft(data4) + 'px',border:'none',top: '10px'}">
                          <div style="position:relative;border:none;">
                            <div :style="{ 'min-width': ganttLenth(data4)  + 'px',
                              backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data4)  + 'px',height:'5px', }">
                            </div>
                            <div :style="{ 'min-width': completedLength(data4)  + 'px', 'margin-top': '3px',
                              backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data4)  + 'px',height:'3px', }">
                            </div>
                          </div>
                        </td>
                          <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                            border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                          </td>
                      </tr>
                    </table>
                    <div   v-for="(data5,index5) in tasksDataOfLevelN(5,data4)" :key="index5"  v-show="data4.expanded">
                      <table>
                        <tr v-show="selectedTaskByDay(data5)" style="position:relative;">
                          <td :style="{position: 'absolute', left: ganttLeft(data5) + 'px',border:'none',top: '10px'}">
                            <div style="position:relative;border:none;">
                              <div :style="{ 'min-width': ganttLenth(data5)  + 'px',
                                backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data5)  + 'px',height:'5px', }">
                              </div>
                              <div :style="{ 'min-width': completedLength(data5)  + 'px', 'margin-top': '3px',
                                backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data5)  + 'px',height:'3px', }">
                              </div>
                            </div>
                          </td>
                            <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                              border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                            </td>
                        </tr>
                      </table>
                      <div   v-for="(data6,index6) in tasksDataOfLevelN(6,data5)" :key="index6"  v-show="data5.expanded">
                        <table>
                          <tr v-show="selectedTaskByDay(data6)" style="position:relative;">
                            <td :style="{position: 'absolute', left: ganttLeft(data6) + 'px',border:'none',top: '10px'}">
                              <div style="position:relative;border:none;">
                                <div :style="{ 'min-width': ganttLenth(data6)  + 'px',
                                  backgroundColor: 'rgb(0, 122, 204)',positon:'absolute','max-width': ganttLenth(data6)  + 'px',height:'5px', }">
                                </div>
                                <div :style="{ 'min-width': completedLength(data6)  + 'px', 'margin-top': '3px',
                                  backgroundColor: 'rgb(145, 170, 252)',positon:'absolute','max-width': completedLength(data6)  + 'px',height:'3px', }">
                                </div>
                              </div>
                            </td>
                              <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                                border-bottom:none; padding:0 2px;height:25px; color: transparent;">
                              </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div> -->

          </div>

        </el-col>
      </div>
    </div>
  </div>
</template>

<script>
import EditProjectMenu from '../menu/task/EditProjectMenu.vue'
import EditTaskMenu from '../menu/help/EditTaskMenu.vue'
import { mapState , mapMutations,mapActions} from 'vuex'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { eventBus } from '../../main.js'
import EditTimeRuler from '../menu/help/EditTimeRuler.vue'
import LoadProjectMenu from '../menu/help/LoadProjectMenu.vue'
import Detail from '../menu/task/Detail.vue'
import AddProjectMenu from '../menu/task/AddProjectMenu.vue'
import { rgb } from 'd3'
import io from 'socket.io-client'
export default {
  components: {
    EditProjectMenu,
    EditTimeRuler,
    AddProjectMenu,
    Detail,
    LoadProjectMenu,
    EditTaskMenu
  },
  data() {
    return {
      selectedTask:{ ID: 0, position: '', name: '', qtyotsk: 0 ,unit: '', resources: '', duration: '' , expanded:1,
          starttime: '', endtime: '', pretask: 0 ,qtyotsked: 0,done:0,level:1,parent:'roottask'},
      selectedOption:'全部',
      ifNotFinished:false,
      taskIsDelay:false,
      projectName:'',
      newTasks:[
      { ID: 1,  position: '', name: '新的开始', qtyotsk: 0 ,unitotsk: '', resources: '', starttimeatl:'2999/12/31',endtimeatl:'2999/12/31',
          duration: 1 , expanded:1, starttime: new Date(), endtime: new Date(), pretask: '' ,qtyotsked: 0,done:0,level:1,
          parent:'roottask', permit_level:'charger',
          childtimearray:[],
          pretaskIDstrArray:[]
        },
      ],
      tasksData: [],
      allMyLoadedProjectTasks:[],

      tasklistall:[],
      myProjectAclList:[],



      resorceArray:[
        {ID:1, resname:'钢筋工',resunit:'人'},
        {ID:2, resname:'木工',resunit:'人'},
        {ID:3, resname:'混凝土工',resunit:'人'},
        {ID:4, resname:'杂工',resunit:'人'},
        {ID:5, resname:'挖机PC60',resunit:'台班'},
        {ID:6, resname:'土方车',resunit:'台班'},
      ],
      selectedTasks:[],
      days:[],
      event: null,
      startDate: new Date(),
      dynamicCellCount: 5,
      monthArray:[],
      dayArray:[],
      showTimeRulerEditMenu:false,
      timeRulerEditMenuitems:[],
      parentNode: null,
      showEditTimeRuler:false,
      ViewOption:1,
      resViewOption:1,
      elprogressWith:80,
      dayInterval:2,


      endtimeBefore:'',
      starttimeBefore:'',
      lasttimeBefore:0,


      //以下是拖动边界框的数据
      isResizing: false,
      startX: 0,
      leftWidth: 1200,
      //以上是拖动边界框的数据


      //以下是创建/加载项目的数据
      loadedProject:{},   //用户保存加载的项目数据
      isShowAddProjMenu:false,
      items: [],

      isShowLoadProjectMenu:false,
      

      //以下是任务完成情况统计的数据
      crOfTasks:50,

      customColorMethods: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
      ],


      //操作表头相关的数据
      ifShowFinishRate:true,
      ifShowFinishQuanty:true,
      ifShowResorce:true,
      ifShowPretask:true,
      ifShowSequence:true,
      ifShowPart:true,
      ifShowTableCol:
        {ifShowFinishRate:true,
         ifShowFinishQuanty:true,
         ifShowResorce:true,
         ifShowPretask:true,
         ifShowSequence:true,
         ifShowPart:true,
         leftWidth:1200},

      allTaskResources:[],

      ifShowAllTaskResource:true,
      ifShowSelectedTaskResource:false,
      resResourceTtile:'资源需求计划表',

      //以下是任务工序关系相关的数据
      ifShowTaskProcessAcl:false,


      //以下是任务编辑菜单相关数据
      showTaskEdit:false,
      myTaskAcllist:[],

      //与资源配置相关的数据

      resPlanlist:[],
      showTaskDetail:false,


      //以下是与任务Detail相关的数据
      selectedTaskResPlans:[],
      taskContents:[],
      selectedProjectAcls:[],
      socket: null,

      searchTaskName:'',
      filteredTasks:[],

      //以下是与任务审批相关的数据
      taskProcessMsgs:[],
      selectedTaskFiles:[],



      


    };
  },

  computed: {

    //计算某个任务按完成比例应该到哪一天了，假设任务完成率与时间应该成正比
    computedIfFinished(){
      return (task, item) => {
        // 将开始时间和结束时间转换为日期对象
        const startDate = new Date(task.starttime);
        const endDate = new Date(task.endtime);
        // 计算任务的总时长
        const totalDuration = task.duration;
        
        // 计算任务已经完成的时长
        const completedDuration = totalDuration * task.qtyotsked / task.qtyotsk;
        // 计算任务完成的日期
        const completionDate = new Date(startDate.getTime() + completedDuration * 24 * 60 * 60 * 1000); // 将天数转换为毫秒


        const thisEndDay = new Date(item.date);
        const thisStartDay = new Date(item.date);
        const interval = this.dayArray[1].day - this.dayArray[0].day;
        
        thisStartDay.setDate(thisStartDay.getDate() - interval);


        if (completionDate.getTime() > thisStartDay.getTime()) {
            return true;
        } else {
            return false;
        }
      }
    },

    completedLength(){
      return (task) => {
        // 将开始时间和结束时间转换为日期对象
        // const startDate = new Date(task.starttime);
        // const endDate = new Date(task.endtime);
        // 计算任务的总时长
        const totalDuration = task.duration;
        
        // 计算任务已经完成的时长
        const completedDuration = totalDuration * task.qtyotsked / task.qtyotsk;
        // 计算任务完成的日期
        // const completionDate = new Date(startDate.getTime() + completedDuration * 24 * 60 * 60 * 1000); // 将天数转换为毫秒


        // const thisEndDay = new Date(item.date);
        // const thisStartDay = new Date(item.date);
        const interval = this.dayArray[1].day - this.dayArray[0].day;
        const completedL=completedDuration*20/interval
        
        // thisStartDay.setDate(thisStartDay.getDate() - interval);


        // if (completionDate.getTime() > thisStartDay.getTime()) {
        //     return true;
        // } else {
        //     return false;
        // }
        return completedL
      }
    },


    // 融合 isWorkDay 和 computedIfFinished 计算属性
    mergeProperties(){
      return(data, item)=>{
        const isWorkDay = (data, item) => {

            const starttime = new Date(data.starttime);
            const endtime = new Date(data.endtime);
            const thisDay = new Date(item.date);
            if (thisDay >= starttime && thisDay <= endtime) {
                return true;
            } else {
                return false;
            }
        };
    
        const computedIfFinished = (task, item) => {
            // 将开始时间和结束时间转换为日期对象
            const startDate = new Date(task.starttime);
            const endDate = new Date(task.endtime);
            // 计算任务的总时长
            const totalDuration = task.duration;
            // 计算任务已经完成的时长
            const completedDuration = totalDuration * task.qtyotsked / task.qtyotsk;
            // 计算任务完成的日期
            const completionDate = new Date(startDate.getTime() + completedDuration * 24 * 60 * 60 * 1000); // 将天数转换为毫秒
            const xx = new Date(item.date);
            if (completionDate.getTime() >= xx.getTime()) {
                return true;
            } else {
                return false;
            }
        };
    
        return (data, item) => {
            const isWorkDayResult = isWorkDay(data, item);
            const computedIfFinishedResult = computedIfFinished(data, item);
    
            // 只有当两个属性都为true时才返回true
            if (isWorkDayResult && computedIfFinishedResult) {
                return true;
            } else {
                return false;
            }
        };

      }

    },

    //计算我对一个项目的权限是什么
    permitLevel(){
      return (task)=>{
        if(task.permit_level=='charger') {
          return true
        } else {
          const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)
          const isCharger=(taskAcl.length==0)?false:(taskAcl[0].permit_level=='charger'?true:false)
          return isCharger
        }
      }
    },

    computedPretaskIDStr(){
      return (task)=>{
      const preTaskidStr=task.pretask
      let preTaskIDStr=''
      if(preTaskidStr){
        const preTaskids=preTaskidStr.split(';')
        if(preTaskids.length>0){
          preTaskids.map((str)=>{
            // var pattern = /^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)?/;
            var pattern =/^(\d+)$|^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)$/
            var pattern1 =/^(\d+)/
            // var matches = pattern.exec(str);
            var matches = str.match(pattern1);
            var firstNumber = matches[1];
            const task=this.tasksData.find(item=>item.id==firstNumber)
            const preTaskID=this.replaceFirstNumberOfPretaskstr(str,task.ID)
            preTaskIDStr=`${preTaskID};${preTaskIDStr}`
          })
        }
      }
      preTaskIDStr = preTaskIDStr.replace(/;$/, '');
  
      return preTaskIDStr
      }
    },

    computedStarttime(){
      return (task)=>{
        const showStarttime=task.starttime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        return showStarttime
      }
    },

    computedEndtime(){
      return (task)=>{
        const showEndtime=task.endtime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        return showEndtime
      }
    },

    computedStartAndEndTime(){
      return(task)=>{

        //以下是根据前置任务信息数组计算任务的最早开始时间和最早结束时间，从而自动计算任务的开始时间和结束时间
        let earlieststarttimeArray=[]
        let earliestendtimeArray=[]
        let startAndEndTimeObj={}
        startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
        return startAndEndTimeObj
        
        // if(task.pretaskIDstrArray.length>0){
        //   let pretaskIDStrs=task.pretaskIDstrArray
        //   pretaskIDStrs.forEach((pretaskIDObj)=>{
        //     this.calculateEarliestStartAndEndTime(task,pretaskIDObj.pretaskIDStr,earlieststarttimeArray,earliestendtimeArray)
        //   })
        //   let earliestStarttime=(earlieststarttimeArray.length>0)?new Date(Math.max.apply(null, earlieststarttimeArray)):''
        //   let earliestEndtime=(earliestendtimeArray.length>0)?new Date(Math.max.apply(null, earliestendtimeArray)):''

        //   if(earliestStarttime&&earliestEndtime){
        //     var newEndtime=new Date(earliestStarttime.setDate(earliestStarttime.getDate() + parseInt(task.duration)-1))
        //     if(newEndtime>=earliestEndtime){
        //       task.starttime=earliestStarttime
        //       task.endtime=newEndtime
        //       startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
        //       return startAndEndTimeObj
        //     } else {
        //       task.endtime=earliestEndtime
        //       var newStarttime=new Date(earliestEndtime.setDate(earliestEndtime.getDate() - parseInt(task.duration)+1))
        //       task.starttime=newStarttime
        //       startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
        //       return startAndEndTimeObj
        //     }
        //   } else if(earliestStarttime&&earliestEndtime=='') {
        //     task.starttime=earliestStarttime
        //     task.endtime=new Date(earliestStarttime.setDate(earliestStarttime.getDate() + parseInt(task.duration)-1))
        //     startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
        //     return startAndEndTimeObj
        //   } else if(earliestStarttime==''&&earliestEndtime) {
        //     task.endtime=earliestEndtime
        //     var newStarttime=new Date(earliestEndtime.setDate(earliestEndtime.getDate() - parseInt(task.duration)+1))
        //     task.starttime=newStarttime
        //     startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
        //     return startAndEndTimeObj
        //   } else {
        //     startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
        //     return startAndEndTimeObj
        //     alert('不应该有此提醒！')
        //   }
        // } else {
        //   startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
        //   return startAndEndTimeObj
        // }

        // if(task.childtimearray.length<1){
        //   let starttime=task.starttime==''?'':new Date(task.starttime)
        //   starttime=starttime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        //   let endtime=task.endtime==''?'':new Date(task.endtime)
        //   endtime=endtime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        //   return {starttime:starttime,endtime:endtime}
        // } else {
        //   let starttimeArray=[]
        //   let endtimeArray=[]
        //   task.childtimearray.forEach((item)=>{
        //     let starttime=new Date(item.childstarttime)
        //     starttimeArray.push(starttime)
        //     let endtime=new Date(item.childendtime)
        //     endtimeArray.push(endtime)
        //   })
        //   let minstarttime=new Date(Math.min.apply(null, starttimeArray))
        //   minstarttime=minstarttime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        //   let maxendtime=new Date(Math.max.apply(null, endtimeArray))
        //   maxendtime=maxendtime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        //   return {starttime:minstarttime,endtime:maxendtime}
        // }
      }
    },

    computedPercentage(){
      return(task)=>{
        let percentage
        if(task.done){
          percentage=100
          return percentage
        } else if(task.qtyotsk>0) {
          percentage= parseInt((task.qtyotsked/task.qtyotsk)*100)
          if (!isNaN(percentage)) {
            return percentage
          } else {
            return 0
          }
          
        } else {
          return 0
        }
      }
    },



    hasChildTask:function() {
      return function(task) {
        const doneChilds = this.tasksData.filter(i => i.parent === task.idft);
        if (doneChilds.length < 1) {
          return 0;
        } else {
          return 1;
        }
      }
    },

    tasksDataOfLevelN(){
      return (n,task)=>{
        // var tasksData=[]
        // const today=new Date().getTime()
        // const starttime=new Date(task.starttime).getTime()
        // // 获取当前日期


        // // 获取当前日期
        // var currentDate = new Date();
        
        // // 获取本周的第一天，即本周的星期一
        // var firstDay = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1));
        
        // // 将本周的第一天转换为时间戳（单位：毫秒）
        // var firstDayTimestamp = firstDay.getTime();


        // if(this.selectedOption=='全部'){
        //   tasksData=this.tasksData.filter(item=>item.level==n&&item.parent==task.idft)
        // }else if(this.selectedOption=='今天'){
        //   tasksData=this.tasksData.filter(item=>item.level==n&&item.parent==task.idft&&starttime<=today)
        // }else if(this.selectedOption=='本周'){
        //   tasksData=this.tasksData.filter(item=>item.level==n&&item.parent==task.idft&&starttime<=firstDayTimestamp)
        // }

        const tasksData=this.tasksData.filter(item=>item.level==n&&item.parent==task.idft)
        
        return tasksData
      }
    },

    tasksDataOfLevel1(){
      return (task)=>{
        if (this.tasksData && this.tasksData.length > 0) {
          const tasksData = this.tasksData.filter(item => item.level === 1);
          return tasksData;
        } else {
          return [];
        }
      }
    },

    // tasksDataOfLevel1: function() {
    //   const tasksData = this.tasksData.filter(item => item.level === 1);
    //   return tasksData;
    // },

    selectedTaskByDay() {
      return (item) => {
    
        let percentage
        if(item.done){
            percentage=100
          } else if (item.qtyotsk>0) {
            percentage=(item.qtyotsked/item.qtyotsk)*100
          } else {
            percentage=0
          }
    
          
        const today=new Date()
        const startTime = new Date(item.starttime)
        const endTime = new Date(item.endtime)
        const totalDays = Math.ceil((endTime.getTime() - startTime.getTime()) / (1000 * 3600 * 24)) + 1
        const passedDays = Math.ceil((today.getTime() - startTime.getTime()) / (1000 * 3600 * 24))
        const timePassedPercentage = passedDays / totalDays * 100
    
        let ifShow
        let delayOrNot
        if (percentage < timePassedPercentage&&item.done==0&&percentage<100){
          delayOrNot=true
        }else {
          delayOrNot=false
        }
    
        if (this.selectedOption == '今天') {
          // 今天的日期对象
          const today = new Date();
          const starttime = item.starttime == '' ? '1970/01/01' : item.starttime;
          const endtime = item.endtime == '' ? '2999/12/31' : item.endtime;
          const starttimeTimestamp = new Date(starttime).getTime();
          const endtimeTimestamp = new Date(endtime).getTime();
          const todayTimestamp = today.getTime();
          ifShow = (starttimeTimestamp <= todayTimestamp) ? true : false;
          if (((item.qtyotsked >= item.qtyotsk && !(item.qtyotsk == null)) || item.done == 1) && this.ifNotFinished) {
            ifShow = false;
          }
          return ifShow;
        } else if (this.selectedOption == '本周') {
                   // 获取给定日期的日期对象
             const givenDate = new Date();
         
             // 获取给定日期是本周的第几天（0 表示星期日，1 表示星期一，依此类推）
             const dayOfWeek = givenDate.getDay();
         
             // 计算偏移量，使周一成为一周的第一天
             const offset = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
         
             // 获取给定日期所在周的第一天的日期对象
             const firstDay = new Date(givenDate);
             firstDay.setDate(givenDate.getDate() - offset);
         
             // 获取给定日期所在周的最后一天的日期对象（本周的周日）
             const lastDay = new Date(firstDay);
             lastDay.setDate(firstDay.getDate() + 6);
         
             // 将日期对象转换为时间戳（单位为毫秒）
             const lastDayTimestamp = lastDay.getTime();
             const starttime = item.starttime == '' ? 0 : item.starttime;
             const starttimeTimestamp = new Date(starttime).getTime();
             ifShow = (starttimeTimestamp <= lastDayTimestamp) ? true : false;
             if (((item.qtyotsked >= item.qtyotsk && !(item.qtyotsk == null)) || item.done == 1) && this.ifNotFinished) {
                 ifShow = false;
             }
             return ifShow;
        } else if (this.selectedOption == '本月') {
                    // 获取今天的日期对象
              const today = new Date();
              
              // 获取本月的第一天的日期对象
              const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
              
              // 获取下个月的第一天的日期对象，然后减去一天，即可得到本月的最后一天的日期对象
              const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
              lastDay.setDate(lastDay.getDate() - 1);
              
              // 将日期对象转换为时间戳（单位为毫秒）
              const firstDayTimestamp = firstDay.getTime();
              const lastDayTimestamp = lastDay.getTime();
              const starttime=item.starttime==''?0:item.starttime
              const endtime=item.endtime==''?0:item.endtime
              const starttimeTimestamp=new Date((starttime)).getTime()
              const endtimeTimestamp=new Date(endtime).getTime()
              // var ifShow=(starttimeTimestamp>lastDayTimestamp||(endtimeTimestamp<firstDayTimestamp))?false:true
              ifShow=(starttimeTimestamp<=lastDayTimestamp)?true:false
    
              if(((item.qtyotsked>=item.qtyotsk&&!(item.qtyotsk==null))||item.done==1)&&this.ifNotFinished) {
                ifShow=false
              }
              return ifShow
        } else if (this.selectedOption == '上周') {
          const today = new Date();
          const dayOfWeek = today.getDay();
          const offset = dayOfWeek === 0 ? 13 : 7 + dayOfWeek;
          const firstDay = new Date(today);
          firstDay.setDate(today.getDate() - offset);
          const lastDay = new Date(firstDay);
          lastDay.setDate(firstDay.getDate() + 6);
          const lastDayTimestamp = lastDay.getTime();
          const starttime = item.starttime == '' ? 0 : item.starttime;
          const starttimeTimestamp = new Date(starttime).getTime();
          ifShow = (starttimeTimestamp <= lastDayTimestamp) ? true : false;
          if (((item.qtyotsked >= item.qtyotsk && !(item.qtyotsk == null)) || item.done == 1) && this.ifNotFinished) {
            ifShow = false;
          }
          return ifShow;
        } else if (this.selectedOption == '下周') {
          const today = new Date();
          const dayOfWeek = today.getDay();
          const offset = dayOfWeek === 0 ? 7 : 7 - dayOfWeek;
          const firstDay = new Date(today);
          firstDay.setDate(today.getDate() + offset);
          const lastDay = new Date(firstDay);
          lastDay.setDate(firstDay.getDate() + 6);
          const lastDayTimestamp = lastDay.getTime();
          const starttime = item.starttime == '' ? 0 : item.starttime;
          const starttimeTimestamp = new Date(starttime).getTime();
          ifShow = (starttimeTimestamp <= lastDayTimestamp) ? true : false;
          if (((item.qtyotsked >= item.qtyotsk && !(item.qtyotsk == null)) || item.done == 1) && this.ifNotFinished) {
            ifShow = false;
          }
          return ifShow;
        } else if (this.selectedOption == '上月') {
          const today = new Date();
          const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
          const firstDayTimestamp = firstDay.getTime();
          const lastDayTimestamp = lastDay.getTime();
          const starttime = item.starttime == '' ? 0 : item.starttime;
          const starttimeTimestamp = new Date(starttime).getTime();
          ifShow = (starttimeTimestamp <= lastDayTimestamp) ? true : false;
          if (((item.qtyotsked >= item.qtyotsk && !(item.qtyotsk == null)) || item.done == 1) && this.ifNotFinished) {
            ifShow = false;
          }
    
          if(this.ifDelayed&&!delayOrNot){
            ifShow = false
          } 
    
    
          return ifShow;
        } else if (this.selectedOption == '下月') {
          const today = new Date();
          const firstDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
          const lastDay = new Date(today.getFullYear(), today.getMonth() + 2, 0);
          const firstDayTimestamp = firstDay.getTime();
          const lastDayTimestamp = lastDay.getTime();
          const starttime = item.starttime == '' ? 0 : item.starttime;
          const starttimeTimestamp = new Date(starttime).getTime();
          ifShow = (starttimeTimestamp <= lastDayTimestamp) ? true : false;
          if (((item.qtyotsked >= item.qtyotsk && !(item.qtyotsk == null)) || item.done == 1) && this.ifNotFinished) {
            ifShow = false;
          }
  
          return ifShow;
        } else {
          if (((item.qtyotsked >= item.qtyotsk && !(item.qtyotsk == null)) || item.done == 1) && this.ifNotFinished) {
            ifShow=false;
          } else {
            ifShow=true;
          }
        }
    
        if(this.ifDelayed&&!delayOrNot){
          ifShow = false
        } 
        return ifShow
      }
    },

    // selectedTaskByDay() {
    //   return (item)=>{
    //     if(this.selectedOption=='今天') {
    //       const starttime=item.starttime==''?'1970/01/0':item.starttime
    //       const endtime=item.endtime==''?'2999/12/31':item.endtime
    //       const starttimeTimestamp=new Date((starttime)).getTime()
    //       const endtimeTimestamp=new Date(endtime).getTime()
    //       const today=new Date().getTime()
    //       var ifShow=(starttimeTimestamp<=today)?true:false
    //       if(((item.qtyotsked>=item.qtyotsk&&!(item.qtyotsk==null))||item.done==1)&&this.ifNotFinished) {
    //         ifShow=false
    //       }
    //       return ifShow
    //     } else if(this.selectedOption=='本周') {

    //      // 获取给定日期的日期对象
    //      const givenDate = new Date();
     
    //      // 获取给定日期是本周的第几天（0 表示星期日，1 表示星期一，依此类推）
    //      const dayOfWeek = givenDate.getDay();
     
    //      // 计算偏移量，使周一成为一周的第一天
    //      const offset = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
     
    //      // 获取给定日期所在周的第一天的日期对象
    //      const firstDay = new Date(givenDate);
    //      firstDay.setDate(givenDate.getDate() - offset);
     
    //      // 获取给定日期所在周的最后一天的日期对象（本周的周日）
    //      const lastDay = new Date(firstDay);
    //      lastDay.setDate(firstDay.getDate() + 6);
     
    //      // 将日期对象转换为时间戳（单位为毫秒）
    //      const lastDayTimestamp = lastDay.getTime();
    //      const starttime = item.starttime == '' ? 0 : item.starttime;
    //      const starttimeTimestamp = new Date(starttime).getTime();
    //      var ifShow = (starttimeTimestamp <= lastDayTimestamp) ? true : false;
    //      if (((item.qtyotsked >= item.qtyotsk && !(item.qtyotsk == null)) || item.done == 1) && this.ifNotFinished) {
    //          ifShow = false;
    //      }
    //      return ifShow;
    //     } else if(this.selectedOption=='本月'){
    //       // 获取今天的日期对象
    //       const today = new Date();
          
    //       // 获取本月的第一天的日期对象
    //       const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
          
    //       // 获取下个月的第一天的日期对象，然后减去一天，即可得到本月的最后一天的日期对象
    //       const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    //       lastDay.setDate(lastDay.getDate() - 1);
          
    //       // 将日期对象转换为时间戳（单位为毫秒）
    //       const firstDayTimestamp = firstDay.getTime();
    //       const lastDayTimestamp = lastDay.getTime();
    //       const starttime=item.starttime==''?0:item.starttime
    //       const endtime=item.endtime==''?0:item.endtime
    //       const starttimeTimestamp=new Date((starttime)).getTime()
    //       const endtimeTimestamp=new Date(endtime).getTime()
    //       // var ifShow=(starttimeTimestamp>lastDayTimestamp||(endtimeTimestamp<firstDayTimestamp))?false:true
    //       var ifShow=(starttimeTimestamp<=lastDayTimestamp)?true:false

    //       if(((item.qtyotsked>=item.qtyotsk&&!(item.qtyotsk==null))||item.done==1)&&this.ifNotFinished) {
    //         ifShow=false
    //       }
    //       return ifShow
    //     } else {
    //       if((((item.qtyotsked>=item.qtyotsk&&!(item.qtyotsk==null))||item.done==1)&&this.ifNotFinished)) {
    //         return false
    //       } else {
    //         return true
    //       }

    //     }
    //   }
    // },
    

    isWorkDay() {
      return (data,item)=>{
        const interval=this.dayArray[1].day-this.dayArray[0].day
        const starttime=new Date(data.starttime)
        const endtime=new Date(data.endtime)
        const thisEndDay=new Date(item.date)
        const thisStartDay=new Date(item.date)
        thisStartDay.setDate(thisStartDay.getDate() - interval);
        if((starttime<=thisEndDay)&&(endtime>thisStartDay)) {
          return true
        } else {
          return false
        }
      }
    },

    ganttLenth(){
      return (task)=>{
        const interval=this.dayArray[1].day-this.dayArray[0].day
        return (task.duration/interval)*20
      }
    },

    ganttLeft(){
      return (task)=>{
        const interval=this.dayArray[1].day-this.dayArray[0].day

        const startDate = new Date(task.starttime);
        const chartStartDate = new Date(this.dayArray[0].date);
    
        const differenceInTime = startDate.getTime() - chartStartDate.getTime()
        const differenceInDays = differenceInTime / (1000 * 3600 * 24)
        const leftmargin=(differenceInDays/interval)*20+20-(20/interval)
        return leftmargin
      }
    },


    computedResorceNum1(){
      return(res,item)=>{
        // if(this.selectedTask.resources==''){
        //   return ''
        // } else {
        //   return res.num
        // }
        let data=this.selectedTask
        const starttime=new Date(this.selectedTask.starttime)
        const endtime=new Date(this.selectedTask.endtime)
        const thisDay=new Date(item.date)
        if((thisDay>=starttime)&&(thisDay<=endtime)) {
          const num=this.selectedTask.resources==''?'':res.num
          return num
        } else {
          return ''
        }

      }
    },


    computedSelectedResorceNum(){
      return (res,item)=>{
        let foundObject = item.resourcearray.find(item => item.resname === res.resname);
        if (foundObject==undefined){
          return ''
        }else {
          return foundObject
        }
      }
    },

    computedAllResorceNum(){
      return (item)=>{
        let workerNum=0
        let equipmentNum=0
        item.resourcearray.forEach(res=>{
          if(res.resunit=='人'){
            workerNum += res.num;
          } else if(res.resunit=='台') {
            equipmentNum += res.num
          } else {

          }
        })

        return {workernum:workerNum,equipmentnum:equipmentNum}
    }
    },


    isWorkMonth(){
      return (data,item)=>{
        const starttime=new Date(data.starttime)
        const endtime=new Date(data.endtime)
        const thisDay=new Date(item.date)
        // 获取给定日期所在月份的第一天
        const firstDayOfMonth = new Date(thisDay.getFullYear(), thisDay.getMonth(), 1);
        // 获取给定日期所在月份的下一个月的第一天，然后减去一天得到该月份的最后一天
        const lastDayOfMonth = new Date(thisDay.getFullYear(), thisDay.getMonth() + 1, 0);
        if((firstDayOfMonth>endtime)||(lastDayOfMonth<starttime)) {
          return false
        } else {
          return true
        }
      }

    },

    isResorceDay(){
      return (data,item)=>{
        if(this.selectedTask.starttime==undefined){
          return true
        } else {
          const starttime=new Date(data.starttime)
          const endtime=new Date(data.endtime)
          const thisDay=new Date(item.date)
          if((thisDay>=starttime)&&(thisDay<=endtime)) {
            return true
          } else {
            return false
          }
        }
      }
    },

    generateArray() {
      return (num, interval)=>{
        const result = [];
        for (let i = 1; i <= num; i += interval) {
          const item={day:i}
          result.push(item);
        }
        return result;
      }
    },

    //根据起始时间得到这两个时间之间的月的序列
    getMonthArray(){
      return (startDate, endDate)=>{
        var currentDate = new Date(startDate);
        var end = new Date(endDate);
        // 如果 currentDate 的日期大于 25，则取下一个月的 5 号
        // if (currentDate.getDate() > 25) {
        //   currentDate.setMonth(currentDate.getMonth() + 1);
        //   currentDate.setDate(5);
        // }
        
        // // 如果 end 的日期小于 5，则取上个月的 25 号
        // if (end.getDate() < 5) {
        //   end.setMonth(end.getMonth() - 1);
        //   end.setDate(25);
        // }
        var monthArray = [];
        var current = new Date(currentDate);
        while (current <= end) {
          var year = current.getFullYear();
          var month = current.getMonth() + 1; // 月份从0开始，需要加1
          monthArray.push(year + '年' + month + '月');
          current.setMonth(current.getMonth() + 1); // 增加一个月
        }
        return monthArray;
      }
    },
  },

  methods :{

  

    // 递归查找最高级别任务
    findTopLevelTask(task, allTasks,filteredTasks) {
      filteredTasks.push(task)
      if (task.parent!=='roottask') {
        const parentTask = allTasks.find((t) => t.idft === task.parent);
        if (parentTask) {
          this.findTopLevelTask(parentTask, allTasks,filteredTasks);
        }
      }
    },

    methodStartAndEndTime(task){
        
        //以下是根据前置任务信息数组计算任务的最早开始时间和最早结束时间，从而自动计算任务的开始时间和结束时间
        let earlieststarttimeArray=[]
        let earliestendtimeArray=[]
        let startAndEndTimeObj={}
        
        if(task.pretaskIDstrArray.length>0){
          let pretaskIDStrs=task.pretaskIDstrArray
          
          pretaskIDStrs.forEach((pretaskIDObj)=>{
            // const pretask1=this.tasksData.find(task1=>task1.idft==pretaskIDObj.task_idft)
            this.calculateEarliestStartAndEndTime(task,pretaskIDObj.pretaskIDStr,earlieststarttimeArray,earliestendtimeArray)
            
          })
          let earliestStarttime=(earlieststarttimeArray.length>0)?new Date(Math.max.apply(null, earlieststarttimeArray)):''
          
          let earliestEndtime=(earliestendtimeArray.length>0)?new Date(Math.max.apply(null, earliestendtimeArray)):''
          
 

          if(earliestStarttime&&earliestEndtime){
            var newEndtime=new Date(earliestStarttime.setDate(earliestStarttime.getDate() + parseInt(task.duration)-1))
            if(newEndtime>=earliestEndtime){
              task.starttime=new Date(earliestStarttime)
              task.endtime=new Date(newEndtime)
              startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
              return startAndEndTimeObj
            } else {
              task.endtime=new Date(earliestEndtime)
              var newStarttime=new Date(earliestEndtime.setDate(earliestEndtime.getDate() - parseInt(task.duration)+1))
              task.starttime=new Date(newStarttime)
              startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
              return startAndEndTimeObj
            }
          } else if(earliestStarttime&&earliestEndtime=='') {
            task.starttime=new Date(earliestStarttime)
            var xx=earliestStarttime
            task.endtime=new Date(xx.setDate(xx.getDate() + parseInt(task.duration)-1))

            
            startAndEndTimeObj=this.calculStartAndEndTimeBy(task)

            return startAndEndTimeObj
          } else if(earliestStarttime==''&&earliestEndtime) {
            
            task.endtime=new Date(earliestEndtime)
            var newStarttime=new Date(earliestEndtime.setDate(earliestEndtime.getDate() - parseInt(task.duration)+1))
            task.starttime=new Date(newStarttime)
            startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
            return startAndEndTimeObj
          } else {
    
            startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
            return startAndEndTimeObj
            alert('不应该有此提醒！')
          }
        } else {
          startAndEndTimeObj=this.calculStartAndEndTimeBy(task)
          return startAndEndTimeObj
        }
    },
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
    }),

    ...mapActions({
      addTaskToSql:'addTaskToSql',
      getMyTaskAclList:'getMyTaskAclList',
      getTaskAclsByTaskIdft:'getTaskAclsByTaskIdft',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      // getTaskinfoByIdft: 'getTaskinfoByIdft',
      // getUserInfoBySearchText:'getUserInfoBySearchText',
      showPic:'showPic',
      getTasksByIdfp:'getTasksByIdfp',
      // showTimePub:'showTimePub',
      addTaskAclToSql:'addTaskAclToSql',
      getMyProjectAclList:'getMyProjectAclList',
      deleteTaskAclbyIdft:'deleteTaskAclbyIdft',
      // getTaskAclsByTaskIdft:'getTaskAclsByTaskIdft',
      getUserInfoByUsername:'getUserInfoByUsername',
      delChildTask:'delChildTask',
      updateTaskOrderidOfSql:'updateTaskOrderidOfSql',
      getMsgsByReceiver:'getMsgsByReceiver',
      getTaskMsgsByReceiver:'getTaskMsgsByReceiver',
      getResPlanlistByTask:'getResPlanlistByTask',
      // updateTaskOrderidOfSql:'updateTaskOrderidOfSql'
      
    }),

    //以下与编辑任务工序关系相关的函数
    editTaskProcessAcl(){
      this.ifShowTaskProcessAcl=true
      this.switchMenuMask()
      eventBus.$on('closeContexMenu', () => {
        this.ifShowTaskProcessAcl=false
      })
  
    },

    selectTask(task){
      this.selectedTask=task
    },

    //把标准时间转换成普通的字符串时间
    convertDate(targetDate){
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      
      const formattedDate = `${year}/${month}/${day}`;
      return formattedDate
      
    },

    //向数据库添加某个任务
    async addTaskSql(newTask) {
      try {
        const token = localStorage.getItem('token');
        const idft = uuidv4();
        newTask.idft = newTask.idft === undefined ? idft : newTask.idft;
        newTask.starttime = this.convertDate(newTask.starttime);
        newTask.endtime = this.convertDate(newTask.endtime);
        const response = await axios({
          method: 'post',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newTask
        });
        const newTaskData = response.data; // 从响应中获取返回的新数据

        return newTaskData
      } catch (error) {
        alert(error?.response?.data?.message || 'An error occurredtask.');
      }
    },

    addBroTask(item){
      const username=localStorage.getItem('username')
      item.starttime=this.convertUtcDateToChinaDate(item.starttime)
      item.endtime=this.convertUtcDateToChinaDate(item.endtime)
      let index1 = this.tasksData.indexOf(item);
      const newTask={ 
        position: '', 
        name: '', 
        qtyotsk: 100 ,
        unitotsk: 'per', 
        resources: '', 
        duration: item.duration , 
        expanded:1, 
        starttime: item.starttime, 
        endtime: item.endtime, 
        starttimeatl: '2999-12-31', 
        endtimeatl: '2999-12-31',
        pretask: '' ,
        qtyotsked: 0,
        done:0,
        delete:0,
        record:'',
        orderid:0,
        level:item.level,
        project:item.project,
        parent: item.parent,
        permit_level:'charger',
        resources:'',
        childtimearray:[],
        pretaskIDstrArray:[],
        sharedrange:'project',
        isshared:1,
        creater: username
      }
      this.addTaskSql(newTask)
        .then((result)=>{
          newTask.id=result.id
          newTask.idft=result.idft
          this.tasksData.splice(index1+1, 0, newTask)
          const newOrderidTasks=this.tasksData.filter(item=>item.level==newTask.level)
          for(let i=0;i<newOrderidTasks.length;i++){
            newOrderidTasks[i].orderid=i
            this.updateTaskOrderidOfSql(newOrderidTasks[i])
          }
          this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)
          this.showUploadProjMenu1(newTask)
          const username=localStorage.getItem('username')
          const newTaskAcl={
            username:username,
            permit_level:'charger',
            task_idft:result.idft,
            taclcreater:username,
          }
          this.addTaskAclToSql(newTaskAcl)
            .then((res)=>{
              this.myTaskAcllist.push(res)
            })
          })
    },

    addChildTask(item){
      const username=localStorage.getItem('username')
      if(item.level<6){
        const newTask={ 
        position: '', 
        name: '', 
        qtyotsk: 100 ,
        unitotsk: 'per', 
        resources: '', 
        duration: 1 , 
        expanded:1, 
        starttime: item.starttime, 
        endtime: item.starttime, 
        starttimeatl:'2999-12-31',
        endtimeatl:'2999-12-31',
        pretask: '' ,
        qtyotsked: 0,
        done:0,
        delete:0,
        record:'',
        orderid:0,
        level:item.level+1,
        project:item.project,
        parent: item.idft,
        permit_level:'charger',
        resources:'',
        childtimearray:[],
        pretaskIDstrArray:[],
        isshared:1,
        sharedrange:'project',
        creater:username
      }
      this.addTaskSql(newTask)
        .then((result)=>{
          newTask.idft=result.idft
          newTask.id=result.id
          this.tasksData.unshift(newTask)
          this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)
          this.showUploadProjMenu1(newTask)

          const username=localStorage.getItem('username')
          const newTaskAcl={
            username:username,
            permit_level:'charger',
            task_idft:result.idft,
            taclcreater:username
          }
          this.addTaskAclToSql(newTaskAcl)
            .then((res)=>{
              this.myTaskAcllist.push(res)
            })
        })
      }
    },

    showTaskEditMenu(event,task){
      const username=localStorage.getItem('username')
      this.usernogroup=`${username}nogroup`
      this.selectedTask=task
     
      this.showTaskEdit=true
      this.switchMenuMask()
      const top=(window.innerHeight-(event.clientY+10))<150?(event.clientY-180):(event.clientY)
      const left=(window.innerWidth-event.clientX)<112?(event.clientX-112):(event.clientX)
      // const top=event.clientY
      // const left=event.clientX
      this.parentNode ={clientY:top-100,clientX:left-70}
      eventBus.$on('closeContexMenu', () => {
      this.showTaskEdit=false
      })
    },

    updateTaskName(task){

      if(this.selectedTask.name==task.name) {
        this.updateTaskOfSql(task)
      }
      
    },

    updateTaskRecord(task){
      const token = localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatetaskrecord',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: task
          })
          .then((response)=>{
            // console.log('updateTaskRecord',response)
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },

    updateTaskPosition(task){

      this.updateTaskOfSql(task)
    },

    switchView(n){
      this.ViewOption=n
      if(n==2){
        const rightCol=this.$refs.rightCol
        const contentcontainer=this.$refs.contentcontainer
        // const contentcontainerWidth=contentcontainer.style.width
        rightCol.style.width = `${1000}px`;
      }

      if(n==4) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const twoYearsLater = new Date();
        twoYearsLater.setHours(0, 0, 0, 0);
        twoYearsLater.setFullYear(today.getFullYear() + 2);
        this.monthArray=this.getMonthDaysArrayWithInterval(today,twoYearsLater,2)
        this.dayArray=this.getDaysArrayWithInterval(today,twoYearsLater,2)
      }

      if(n==5){
      }
    },

    switchResourceView(n){
      this.resViewOption=n


      if(this.resViewOption==2){
        this.resResourceTtile='资源需求曲线图'

      }

      if(this.resViewOption==1){
        this.resResourceTtile='资源需求计划表'
        this.drawChart()
      }


      
    },

    //操作表头相关的函数
    showFinishRate(){  
      this.ifShowFinishRate=!this.ifShowFinishRate
      this.ifShowTableCol.ifShowFinishRate=this.ifShowFinishRate
      if(!this.ifShowFinishRate){
        // 获取原始宽度
        const originalWidth = this.$refs.tablecontainer.offsetWidth;
        
        // 减少宽度
        this.$refs.tablecontainer.style.width = (originalWidth - 272) + "px";
        this.leftWidth=this.leftWidth-272
        this.ifShowTableCol.leftWidth=this.leftWidth
      } else {
        const originalWidth = this.$refs.tablecontainer.offsetWidth;
        this.$refs.tablecontainer.style.width = 1070 + "px";
        this.leftWidth=1200
        this.ifShowTableCol.leftWidth=this.leftWidth
      }
    },


    showFinishQuanty(){
      this.ifShowFinishQuanty=!this.ifShowFinishQuanty
      this.ifShowTableCol.ifShowFinishQuanty=this.ifShowFinishQuanty
      if(!this.ifShowFinishQuanty){
        // 获取原始宽度
        const originalWidth = this.$refs.tablecontainer.offsetWidth;
        
        // 减少宽度
        this.$refs.tablecontainer.style.width = (originalWidth - 50) + "px";
        this.leftWidth=this.leftWidth-50
        this.ifShowTableCol.leftWidth=this.leftWidth
      } else {
        const originalWidth = this.$refs.tablecontainer.offsetWidth;
        this.$refs.tablecontainer.style.width = 1070 + "px";
        this.leftWidth=1200
        this.ifShowTableCol.leftWidth=this.leftWidth
      }

    },

    showResorce(){
      this.ifShowResorce=!this.ifShowResorce
      this.ifShowTableCol.ifShowResorce=this.ifShowResorce
      if(!this.ifShowResorce){
        // 获取原始宽度
        const originalWidth = this.$refs.tablecontainer.offsetWidth;
        
        // 减少宽度
        this.$refs.tablecontainer.style.width = (originalWidth - 52) + "px";
        this.leftWidth=this.leftWidth-52
        this.ifShowTableCol.leftWidth=this.leftWidth
      } else {
        this.$refs.tablecontainer.style.width = 1070 + "px";
        this.leftWidth=1200
        this.ifShowTableCol.leftWidth=this.leftWidth
      }

    },

    showPretask(){
      this.ifShowPretask=!this.ifShowPretask
      this.ifShowTableCol.ifShowPretask=this.ifShowPretask
      if(!this.ifShowPretask){
        // 获取原始宽度
        const originalWidth = this.$refs.tablecontainer.offsetWidth;
        
        // 减少宽度
        this.$refs.tablecontainer.style.width = (originalWidth - 50) + "px";
        this.leftWidth=this.leftWidth-50
        this.ifShowTableCol.leftWidth=this.leftWidth
      } else {
        this.$refs.tablecontainer.style.width = 1070 + "px";
        this.leftWidth=1200
        this.ifShowTableCol.leftWidth=this.leftWidth
      }

    },

    showSequence(){
      this.ifShowSequence=!this.ifShowSequence
      this.ifShowTableCol.ifShowSequence=this.ifShowSequence
      if(!this.ifShowSequence){
        // 获取原始宽度
        const originalWidth = this.$refs.tablecontainer.offsetWidth;
        
        // 减少宽度
        this.$refs.tablecontainer.style.width = (originalWidth - 62) + "px";
        this.leftWidth=this.leftWidth-62
        this.ifShowTableCol.leftWidth=this.leftWidth
      } else {
        this.$refs.tablecontainer.style.width = 1070 + "px";
        this.leftWidth=1200
        this.ifShowTableCol.leftWidth=this.leftWidth
      }

    },

    showPart(){
      this.ifShowPart=!this.ifShowPart
      this.ifShowTableCol.ifShowPart=this.ifShowPart
      if(!this.ifShowPart){
        // 获取原始宽度
        const originalWidth = this.$refs.tablecontainer.offsetWidth;
        
        // 减少宽度
        this.$refs.tablecontainer.style.width = (originalWidth - 102) + "px";
        this.leftWidth=this.leftWidth-102
        this.ifShowTableCol.leftWidth=this.leftWidth
      } else {
        this.$refs.tablecontainer.style.width = 1070 + "px";
        this.leftWidth=1200
        this.ifShowTableCol.leftWidth=this.leftWidth
      }

    },

    //以下是任务统计相关的函数
    // customColorMethod(item){
    //   let percentage
    //   if(item.done){
    //     percentage=100
    //   } else if (item.qtyotsk>0) {
    //     percentage=(item.qtyotsked/item.qtyotsk)*100
    //   } else {
    //     percentage=0
    //   }

    //   const today=new Date()
    //   const starttime=new Date(item.starttime)
    //   const timeDiff = today.getTime() - starttime.getTime();
    //   const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))+1; // 将毫秒转换为天数

     
    //   if (percentage >= 100) {
    //     return 'rgb(111, 122, 211)';
    //   } else if (percentage >= 85) {  
    //     return 'rgb(103, 194, 58)';
    //   } else if (percentage >= 60){
    //     return 'rgb(255, 216, 36)';
    //   } else {
    //     return 'rgb(255, 1, 11)'
    //   }
    // },


    customColorMethod(item){
      let percentage
      const today=new Date()
      const starttime = new Date(item.starttime)
      const endTime = new Date(item.endtime)
      const totalDays = Math.ceil((endTime.getTime() - starttime.getTime()) / (1000 * 3600 * 24)) + 1
      const passedDays = Math.ceil((today.getTime() - starttime.getTime()) / (1000 * 3600 * 24))
      const timePassedPercentage = passedDays / totalDays * 100
      if(item.done){
        percentage=100
      } else if (item.qtyotsk>0) {
        percentage=(item.qtyotsked/item.qtyotsk)*100
      } else {
        percentage=0
      }

      if (percentage >= timePassedPercentage||percentage >=100) {
        return 'rgb(111, 122, 211)';
      } else if (percentage >= 0.85*timePassedPercentage) {  
        return 'rgb(103, 194, 58)';
      } else if (percentage >= 0.60*timePassedPercentage){
        return 'rgb(255, 216, 36)';
      } else {
        return 'rgb(255, 1, 11)'
      }
    },

    //资源相关的函数

    computedAllResorceNum1(item){
        let workerNum=0
        let equipmentNum=0
        item.resourcearray.forEach(res=>{
          if(res.resunit=='人'){
            workerNum += res.num;
          } else if(res.resunit=='台') {
            equipmentNum += res.num
          } else {
          }
        })
        return {workernum:workerNum,equipmentnum:equipmentNum}
    },
    
    convertStringToResourceArray(task) {
      let str=task.resources
      if(!str==''){
        let items = str.split(";").filter(item => item.length > 0);
        let result = items.map(item => {
          let parts = item.split("-");
          let str1 = parts[0];
          let matchResult = str1.match(/^(\d+)(.*)$/);
          let numPart = matchResult[1];
          let otherPart = matchResult[2];
          let num = parseInt(numPart);
          // let unit = numUnit[1];
          let resname = parts[1];
          return {num:num, resunit:otherPart, resname:resname};
        });
        return result;
      } else {
        return []
      }
    },

    mergeObjectsByProperty(arr, prop) {

      //要保留其他属性
      let resultMap = {};

       arr.forEach(item => {
         if (resultMap[item[prop]]) {
           resultMap[item[prop]].num += item.num;
           // 保留其他属性
           for (let key in item) {
             if (key !== prop && key !== 'num') {
               resultMap[item[prop]][key] = item[key];
             }
           }
         } else {
           resultMap[item[prop]] = { [prop]: item[prop], num: item.num, ...item }; // 保留其他属性
         }
       });
     
       let resultArray = Object.values(resultMap);
       return resultArray;
    },

    //遍历所有任务，把需要的资源，合并同类项，得出整个资源库
    computedallTaskResources(taskArray){
      let allTaskResArray=[]
      if(taskArray&&taskArray.length>0){
        taskArray.forEach(item=>{
        const resArray=this.convertStringToResourceArray(item)
        if(resArray.length>0){
          allTaskResArray= allTaskResArray.concat(resArray)
        }
      })
      const allTaskResArray1 = this.mergeObjectsByProperty(allTaskResArray,'resname')

      return allTaskResArray1

      }else {
        return []
      }


    },

    //不能单纯把所有任务的资源需求同类相加，而应该具体到某一天
    computedallTaskResourcesOfCertainDay(taskArray,date){
      let allTaskResArray=[]
      const thisDay=new Date(date)
      taskArray.forEach(item=>{
        const resArray=this.convertStringToResourceArray(item)
        
        const taskStarttime=new Date(item.starttime)
        const taskEndtime=new Date(item.endtime)
        if(resArray.length>0&&(thisDay>=taskStarttime)&&(thisDay<=taskEndtime)){
          allTaskResArray= allTaskResArray.concat(resArray)
        }
      })
      return this.mergeObjectsByProperty(allTaskResArray,'resname')
      // return allTaskResArray
    },

    //以下是调整父子任务时间关系相关的函数
    adjustTaskTime(tasklist, taskId) {

      let task = tasklist.find(t => t.ID === taskId);
      let children = tasklist.filter(t => t.parent === taskId);
      if (children.length === 0) {
        return
      } else {
        const taskstarttime=task.starttime?task.starttime:'1970/01/01'
        const taskendtime=task.endtime?task.endtime:'1970/01/01'
        let startTimes=[]
        let endTimes=[]
        startTimes.push(Date.parse(taskstarttime))
        endTimes.push(Date.parse(taskendtime))
        for(let child of children) {
          const starttime=child.starttime?child.starttime:'1970/01/01'
          const endtime=child.endtime?child.endtime:'2999/12/31'
          startTimes.push(Date.parse(starttime))
          endTimes.push(Date.parse(endtime))
        }
        let earliestStart = Math.min(...startTimes); // 获取数组中的最大值
        let lastestEnd = Math.max(...endTimes); // 获取数组中的最小值
        task.duration=(lastestEnd-earliestStart)/(3600*24*1000)+1

        task.starttime = new Date(earliestStart).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
        task.endtime = new Date(lastestEnd).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
    },

    adjustAllTaskTime(tasklist){
      let length=tasklist.length

      for(let i=0; i<length;i++) {
        let children = tasklist.filter(t => t.parent ===tasklist[i].ID);
        if (children.length === 0) {
          //如果没有子任务则更新其父任务的开始时间和完成时间
          let parentTask = tasklist.find(t => t.ID === tasklist[i].parent)

          if(!parentTask==undefined){
            const childStarttime= Date.parse(tasklist[i].starttime?tasklist[i].starttime:'1970/01/01')
            const childEndtime=  Date.parse(tasklist[i].endtime?tasklist[i].endtime:'2999/12/31')
            const parentStarttime= Date.parse(parentTask.starttime?parentTask.starttime:'1970/01/01')
            const parentEndtime= Date.parse(parentTask.endtime?parentTask.endtime:'2999/12/31')
            let earliestStart=parentStarttime>childStarttime?childStarttime:parentStarttime
            let lastestEnd=parentEndtime>childEndtime?parentEndtime:childEndtime
            parentTask.starttime=new Date(earliestStart).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
            parentTask.endtime=new Date(lastestEnd).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
            parentTask.duration=(lastestEnd-earliestStart)/(3600*24*1000)+1
            i++
          }else {
            i++
          }

        } else {
          this.adjustAllTaskTime(children)
        }
      }
    },

    adjustTaskParentTime(tasklist,childTask){

      const parentTask=tasklist.find(item=>item.ID==childTask.parent)
      if(parentTask){
        let earliestStart
        earliestStart=0
        if(!(childTask.starttime=='')){
          
          
          if(parentTask.starttime==''){
            parentTask.starttime=childTask.starttime
          } else {
            const childStarttime=Date.parse(childTask.starttime)
            const parentStarttime= Date.parse(parentTask.starttime)
            earliestStart=parentStarttime>childStarttime?childStarttime:parentStarttime
          }
        }
  
        if(earliestStart){
          parentTask.starttime=new Date(earliestStart).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
  
  
        let lastestEnd
        lastestEnd=0
        if(!(childTask.endtime=='')){
          if(parentTask.endtime==''){
            parentTask.endtime=childTask.endtime
          } else {
            const childEndtime=Date.parse(childTask.endtime)
            const parentEndtime= Date.parse(parentTask.endtime)
            lastestEnd=parentEndtime>childEndtime?parentEndtime:childEndtime
          }
        }
  
        if(lastestEnd){
          parentTask.endtime=new Date(lastestEnd).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }

        if(parentTask.endtime&&parentTask.endtime) {
          const endtimeTimestamp=Date.parse(parentTask.endtime)
          const starttimeTimestamp=Date.parse(parentTask.starttime)
          parentTask.duration=(endtimeTimestamp-starttimeTimestamp)/(3600*1000*24)+1
        } else {
          parentTask.duration=''
        }



      }
      

    },

    adjustAllTaskTimeOfLevelN(tasklist,n){
      const tasksLevelN=tasklist.filter(i=>i.level==n)
      tasksLevelN.forEach(item=>{
        this.adjustTaskParentTime(tasklist,item)
      })

      const tasksLeft=tasklist.filter(item=>item.level<n)
      if(n-1>0){
        this.adjustAllTaskTimeOfLevelN(tasksLeft,n-1)
      }
      
    },

    //以下是折叠任务相关的函数
    collapseChildTask(item){
      item.expanded=!item.expanded
    },

    //以下是下载项目的函数
    showDownloadProjMenu(){
      this.isShowLoadProjectMenu=true

      // this.switchMenuMask()
    },

    showUploadProjMenu(){
      for(let i=0;i<this.tasksData.length;i++){
        this.tasksData[i].orderid=i
        this.updateTaskOfSql(this.tasksData[i])
      }
      alert('更新项目成功')
    },

    //静默更新任务的orderid
    showUploadProjMenu1(newTask){
      for(let i=0;i<this.tasksData.length;i++){
        this.tasksData[i].orderid=i
        // this.updateTaskOfSql(this.tasksData[i])
        this.updateTaskOrderidOfSql(this.tasksData[i])
      }
    },

      //更新任务信息
    updateTaskOfSql(task) {

      task.starttime=task.starttime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
      task.endtime=task.endtime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
      task.starttimeatl=new Date(task.starttimeatl).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
      task.endtimeatl=new Date(task.endtimeatl).toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
      if(task.qtyotsk<=task.qtyotsked){
        task.done=1
      } else {
        task.done=0
      }
      
      const token = localStorage.getItem('token')
      axios({
        method: 'put',
        url: 'https://api2.itaskid.com' + '/my/tasklist',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: task
      })
        .then((response) => {
  
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurredupdate.')
        })
    },

    closeLoadProjectMenu(){
      this.isShowLoadProjectMenu=false
      this.switchMenuMask()
    },

    loadProject(project){
      this.loadedProject=project
      this.getTasksByIdfp(project.project_idfp)
        .then((result)=>{
          // result.map((item)=>{
          //   item.starttime=this.convertUtcDateToChinaDate(item.starttime)
          //   item.endtime=this.convertUtcDateToChinaDate(item.endtime)
          //   item.permit_level=project.permit_level
            
          //   const index=result.indexOf(item)
          //   item.ID=index+1
          // })

          result.forEach((item,index)=>{
            item.starttime=this.convertUtcDateToChinaDate(item.starttime)
            item.endtime=this.convertUtcDateToChinaDate(item.endtime)
            item.permit_level=project.permit_level
            
            // const index=result.indexOf(item)
            item.ID=index+1
          })
          // 使用sort方法对数组进行排序
          
          result.sort((a, b) => a.orderid - b.orderid)
          this.allMyLoadedProjectTasks=result
          this.tasksData=result  
          
          this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)

        })
      
      this.projectName=project.name
      this.isShowLoadProjectMenu=false
      localStorage.setItem('myLocalProject', JSON.stringify(project))
    },



    //以下是创建项目的函数
    showAddProjMenu(){
      this.isShowAddProjMenu=!this.isShowAddProjMenu
      this.switchMenuMask()
      
    },
    shutAddProjMenu(){
      this.isShowAddProjMenu=false
    },

    updateLocalItems(newItem) {  
      this.items.push(newItem)
    },

    addProjectWithAclToSql(newProjectAcl,newProj){
      this.addProjectAclToSql(newProjectAcl)
      this.tasksData=this.initialAutoComputedTasksTime(this.newTasks)
      this.tasksData.forEach((task)=>{
        task.starttime=task.starttime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        task.endtime=task.endtime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        task.project=newProj.idfp
        this.addTaskToSql(task)
      })

      this.isShowAddProjMenu=false
      this.projectName=newProj.name

      //将添加的项目缓存到本地
      newProjectAcl.name=newProj.name
      localStorage.setItem('myLocalProject', JSON.stringify(newProjectAcl))

    },

    //任务右键菜单相关函数
    taskeditMenuitemClicked(item){
      switch (item.id){
        case 1: {
          this.addChildTask(this.selectedTask)
          break
        }
        case 2: {

          break
        }
        case 3: {

          break
        }
        case 4: {

        break
        }       
        case 5: {

        break
        }        
        case 6: {
          this.showTaskEdit=false
          if(this.selectedTask.permit_level==='charger') {
            this.tasksData=this.tasksData.filter(item=>item.idft!==this.selectedTask.idft)
            this.tasklistall=this.tasklistall.filter(item=>item.idft!==this.selectedTask.idft)
            this.deleteTaskbyIdft(this.selectedTask.idft)
            this.delChildTask(this.tasksData,this.selectedTask)
          } else {
            alert('您不具有删除权限！')
          }
          break
        }}
        
    },

    //以下是与任务相关的公共函数
    delChildTask(tasks, parentTask) {
      let hasChildTasks = false;
      tasks.forEach((task) => {
        if (task.parent === parentTask.idft) {
          this.deleteTaskbyIdft(task.idft);
          const leftChildTasks = tasks.filter((item) => item.parent === task.idft);
          if (leftChildTasks.length > 0) {
            hasChildTasks = true;
            this.delChildTask(tasks, task);
          }
        }
      });
      if (!hasChildTasks) {
        return;
      }
    },

    //根据idft删除数据库中的数据
    deleteTaskbyIdft(idft){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/tasklist/deletebyidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {idft:idft}
        })
        .then((response)=>{
          console.log('删除任务成功！')
          this.deleteTaskAclbyIdft(idft)
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
      this.switchMenuMask()
    },

    // 向服务器添加项目关系
    addProjectAclToSql(newProjectAcl){
      const token = localStorage.getItem('token')
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/addprojectacl',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newProjectAcl
        })
        .then((response)=>{
          
          console.log('添加项目关系成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurredprojectacl.')
        })
    },



    //以下是有关前置任务相关的函数

    //把task.pretaskid转换成task.pretaskID，并显示在前端
    computedPretaskIDStrofMethod(task){
      const preTaskidStr=task.pretask
      let preTaskIDStr=''
      if(preTaskidStr){
        const preTaskids=preTaskidStr.split(';')
        if(preTaskids.length>0){
          preTaskids.map((str)=>{
            // var pattern = /^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)?/;
            var pattern =/^(\d+)|^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)$/
            var matches = pattern.exec(str);
            var firstNumber = matches[1];
            const preTask=this.tasksData.find(item=>item.id==firstNumber)

            if(preTask){
     
              const preTaskID = this.replaceFirstNumberOfPretaskstr(str, preTask.ID);
              preTaskIDStr = `${preTaskID};${preTaskIDStr}`;
            }
          })
        }
      }
      preTaskIDStr = preTaskIDStr.replace(/;$/, '');
      return preTaskIDStr
    },

    //把前端显示的pretskIDStr转化成task.pretask储存在task中
    computedPretaskidStrofMethod(task,pretaskIDStr){
      
      let preTaskidStr=''
      if(pretaskIDStr){
        const preTaskIDs=pretaskIDStr.split(';')
        if(preTaskIDs.length>0){
          preTaskIDs.map((str)=>{
            // var pattern = /^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)?/;
            var pattern =/^(\d+)|^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)$/
            var matches = pattern.exec(str);
            var firstNumber = matches[1];

            const pretask=this.tasksData.find(item=>item.ID==firstNumber)
            const preTaskid=this.replaceFirstNumberOfPretaskstr(str,pretask.id)
            preTaskidStr=`${preTaskid};${preTaskidStr}`

          })
        }
      }
      preTaskidStr = preTaskidStr.replace(/;$/, '');

  
      return preTaskidStr
    },



    replaceFirstNumberOfPretaskstr(inputStr, newNumber) {
      // 定义正则表达式
      var pattern = /^(\d+)|^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)$/
      // 使用正则表达式替换函数
      var outputStr = inputStr.replace(pattern, newNumber + '$2');
      return outputStr;
    },

    //根据前置任务字符串计算最早开始时间和最早结束时间
    calculateEarliestStartAndEndTime(task,IDStr,earlieststarttimeArray,earliestendtimeArray){
        //进入这个函数就说明IDSt是有效的
      var pattern0 = /^(\d+)|^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)$/
      var pattern1=  /^(\d+)$|^(\d+)([Ff][Ss])$/;     //7或7fs
      var pattern2=  /^(\d+)([Ff][Ss])(\+)(\d+)$/ ;    //7或7fs+8
      var pattern3=  /^(\d+)([Ff][Ss])(-)(\d+)$/;     //7fs-8
      var pattern4=  /^(\d+)([Ff][Ff])$/  ;           //7ff
      var pattern5=  /^(\d+)([Ff][Ff])(\+)(\d+)$/  ;   //7ff+8
      var pattern6=  /^(\d+)([Ff][Ff])(-)(\d+)$/;     //7ff-8
      var pattern7=  /^(\d+)([Ss][Ff])$/      ;       //7sf
      var pattern8=  /^(\d+)([Ss][Ff])(\+)(\d+)$/;     //7sf+8
      var pattern9=  /^(\d+)([Ss][Ff])(-)(\d+)$/ ;    //sf-8
      var pattern10=  /^(\d+)([Ss][Ss])$/    ;          //7ss
      var pattern11=  /^(\d+)([Ss][Ss])(\+)(\d+)$/ ;    //7ss+8
      var pattern12=  /^(\d+)([Ss][Ss])(-)(\d+)$/ ;    //ssf-8
      let dependentTaskIDs = IDStr.split(";");
 
      dependentTaskIDs.forEach((taskID)=>{
        var matchResult = taskID.match(pattern0);
        var ID = matchResult[1];
        // const preTask = this.tasksData.find((task) => task.ID == ID);
    
        const preTask = this.tasksData.find((task) => task.ID == ID);
        let preTaskStartime=new Date(preTask.starttime);
        let preTaskEndtime=new Date(preTask.endtime);
        if(pattern1.test(taskID)){
          preTaskEndtime=preTaskEndtime.setDate(preTaskEndtime.getDate() + 1);
          preTaskEndtime=new Date(preTaskEndtime);
          earlieststarttimeArray.push(preTaskEndtime)
        } else if(pattern2.test(taskID)){
          var matchResult=taskID.match(pattern2)
          let interval=parseInt(matchResult[4])+1
          preTaskEndtime=preTaskEndtime.setDate(preTaskEndtime.getDate() + interval);
          preTaskEndtime=new Date(preTaskEndtime);
          earlieststarttimeArray.push(preTaskEndtime)
        }else if(pattern3.test(taskID)){
          var matchResult=taskID.match(pattern3)
          let interval=parseInt(matchResult[4])-1
          preTaskEndtime=preTaskEndtime.setDate(preTaskEndtime.getDate() - interval);
          preTaskEndtime=new Date(preTaskEndtime);
          earlieststarttimeArray.push(preTaskEndtime)
        }else if(pattern4.test(taskID)){
          earliestendtimeArray.push(preTaskEndtime)
        }else if(pattern5.test(taskID)){
          var matchResult=taskID.match(pattern5)
          let interval=parseInt(matchResult[4])
          preTaskEndtime=preTaskEndtime.setDate(preTaskEndtime.getDate() + interval);
          preTaskEndtime=new Date(preTaskEndtime);
          earliestendtimeArray.push(preTaskEndtime)
        }else if(pattern6.test(taskID)){
          var matchResult=taskID.match(pattern6)
          let interval=parseInt(matchResult[4])
          preTaskEndtime=preTaskEndtime.setDate(preTaskEndtime.getDate() - interval);
          preTaskEndtime=new Date(preTaskEndtime);
          earliestendtimeArray.push(preTaskEndtime)
        }else if(pattern7.test(taskID)){  
          earliestendtimeArray.push(preTaskStartime)
        }else if(pattern8.test(taskID)){
          var matchResult=taskID.match(pattern8)
          let interval=parseInt(matchResult[4])
          preTaskStartime=preTaskStartime.setDate(preTaskStartime.getDate() + interval);
          preTaskStartime=new Date(preTaskStartime);
          earliestendtimeArray.push(preTaskStartime)
        }else if(pattern9.test(taskID)){
          var matchResult=taskID.match(pattern9)
          let interval=parseInt(matchResult[4])
          preTaskStartime=preTaskStartime.setDate(preTaskStartime.getDate() - interval);
          preTaskStartime=new Date(preTaskStartime);
          earliestendtimeArray.push(preTaskStartime)
        }else if(pattern10.test(taskID)){
          earlieststarttimeArray.push(preTaskStartime)
        }else if(pattern11.test(taskID)){
          var matchResult=taskID.match(pattern11)
          let interval=parseInt(matchResult[4])
          preTaskStartime=preTaskStartime.setDate(preTaskStartime.getDate() + interval);
          preTaskStartime=new Date(preTaskStartime);
          earlieststarttimeArray.push(preTaskStartime)
        }else if(pattern12.test(taskID)){
          var matchResult=taskID.match(pattern12)
          let interval=parseInt(matchResult[4])
          preTaskStartime=preTaskStartime.setDate(preTaskStartime.getDate() - interval);
          preTaskStartime=new Date(preTaskStartime);
          earlieststarttimeArray.push(preTaskStartime)
        }else {
          alert("前置任务信息不符合要求！");
        }
      })
    },

    //更新自身前置任务序列和子任务前置任务序列的函数,其中preTaskStr是task最新的前置任务信息，由此更新task所有子任务的preTaskStr
    updateChildPreTaskStr(task,pretaskIDStrObj){
      this.tasksData.forEach((item)=>{
        if(item.parent==task.idft){
          const xxArray=item.pretaskIDstrArray.filter(i=>!(i.task_idft==pretaskIDStrObj.task_idft))
          xxArray.push(pretaskIDStrObj)
          item.pretaskIDstrArray=xxArray
          this.updateChildPreTaskStr(item,pretaskIDStrObj,this.tasksData)
        }
      })

    },
  

    //自动计算所有任务的时间的函数，用于初始化
    initialAutoComputedTasksTime(tasks){
  
      for(let i=0;i<tasks.length;i++) {
        tasks[i].ID=i+1
        tasks[i].orderid=i
        tasks[i].pretaskIDstrArray=[]
        tasks[i].childtimearray=[]
      }//以上是给所有任务添加ID、pretaskIDstrArray、childtimearray属性
      
      tasks.forEach((task)=>{
        if(!task.pretask==''){
          const pretaskIDStr=this.computedPretaskIDStrofMethod(task)
          const pretaskIDStrObj={task_idft:task.idft, pretaskIDStr:pretaskIDStr}
          var xxArray=task.pretaskIDstrArray
          xxArray.push(pretaskIDStrObj)
          task.pretaskIDstrArray=xxArray
          this.updateChildPreTaskStr(task,pretaskIDStrObj)
        }
      })   
      //把每个任务的pretaskIDStr传递给其子、孙任务

      tasks.forEach((task)=>{
        const childTasks=tasks.filter(item=>item.parent==task.idft)
        if(childTasks.length>0){
          var childtimearray=[]
          childTasks.forEach((i)=>{
            childtimearray.push({task_idft:i.idft,childstarttime:'',childendtime:''})
          })
          task.childtimearray=childtimearray
        }
      })
      //以上是把每个子任务转换成子任务时间序列存放在父任务的属性中
  

      

      //1.找到前置任务为空且没有子任务的任务，更新其时间，如果有前置任务或者有子任务，则把该任务的开始和完成时间清空
      let timeokTaskArray=[]
      tasks.forEach((item)=>{
        const childtasks=tasks.filter(task=>task.parent==item.idft)
        if(childtasks.length==0&&item.pretaskIDstrArray.length==0){
          item.starttime=new Date(item.starttime)
          var startDate=new Date(item.starttime)
          var newEndtime=new Date(startDate.setDate(startDate.getDate() + parseInt(item.duration)-1))
          item.endtime=newEndtime
          timeokTaskArray.push(item)
        }else {    
          item.starttime=''
          item.endtime=''
        }
      })

      this.updatejiaozi(tasks,timeokTaskArray)

      return tasks
    },

    //循环更新任务
    updatejiaozi(tasks,timeokTaskArray){
      const leftTasks=tasks.filter(item=> !timeokTaskArray.includes(item))
      if(leftTasks.length>0){
        leftTasks.forEach((i)=>{
          //判断childtimearray是不是空：1. 空，则直接OK；2. 不是空，（1）每个时间都不是空则OK；（2）有子任务时间为空则不OK。
          var isOks=[]
          if(i.childtimearray.length>0){
            i.childtimearray.forEach((t)=>{
              const targetTask=tasks.find(item=>item.idft==t.task_idft)
              if(targetTask.starttime==''||targetTask.endtime==''){
                isOks.push(0)
              }else {
                t.childstarttime=new Date(targetTask.starttime)
                t.childendtime=new Date(targetTask.endtime)
                isOks.push(1)
              }
            })
          }else {
            //如果task.childtimearray为空，则该任务没有子任务
            isOks.push(1)
          }

  
          var pattern1 = /^(\d+)|^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)$/

          if(i.pretaskIDstrArray.length>0){
            i.pretaskIDstrArray.forEach((IDstrObj)=>{
              var IDstr=IDstrObj.pretaskIDStr
              var matchResult=IDstr.match(pattern1)
              var ID= matchResult[1]
              
              const targetTask=tasks.find(item=>item.ID==ID)
              if(targetTask.starttime==''||targetTask.endtime==''){
                isOks.push(0)
              }else {
                IDstrObj.prestarttime=new Date(targetTask.starttime)
                IDstrObj.preendtime=new Date(targetTask.endtime)
                isOks.push(1)
              }
            })
          } else {
            isOks.push(1)
          }
  

  
          const allOnes = isOks.every(item => item === 1);
          if(allOnes){
            let earlieststarttimeArray=[]
            let earliestendtimeArray=[]
            i.pretaskIDstrArray.forEach((IDstrObj)=>{
              this.calculateEarliestStartAndEndTime(i,IDstrObj.pretaskIDStr,earlieststarttimeArray,earliestendtimeArray)
            })
            let earliestStarttime=(earlieststarttimeArray.length>0)?new Date(Math.max.apply(null, earlieststarttimeArray)):''
            let earliestEndtime=(earliestendtimeArray.length>0)?new Date(Math.max.apply(null, earliestendtimeArray)):''
            //以上根据所有前置任务计算出该任务的最早开始时间和最晚结束时间
            
  
            //以下根据子任务数组计算出该任务的另外一个最晚开始和最
            let starttimeArray=[]
            let endtimeArray=[]
            if(i.childtimearray.length<1){
              //没有子任务，则直接是前置任务影响开始和结束时间
              if(earliestEndtime==''){
                i.starttime=earliestStarttime
                i.endtime=new Date(new Date(earliestStarttime).setDate(new Date(earliestStarttime).getDate() + parseInt(i.duration)-1))
              } else if(earliestStarttime==''){
                i.endtime=earliestEndtime
                // 将 earliestStarttime 转换为日期对象
                let startDate = new Date(earliestEndtime);
                // let startDate=earliestEndtime
                let durationInDays = parseInt(i.duration) - 1;
                // 使用 setDate 和 getDate 方法来计算新的开始日期
                startDate.setDate(startDate.getDate() - durationInDays);
                i.starttime = startDate;
              } else {
                
                var computedEndtime=new Date(earliestStarttime)
                var computedEndtime=new Date(computedEndtime.setDate(computedEndtime.getDate() - parseInt(i.duration)+1))
                if(computedEndtime>=earliestEndtime){
                  i.starttime=earliestStarttime
                  i.endtime=computedEndtime

                } else {

                  i.endtime=earliestEndtime
                  var computedStarttime=new Date(earliestEndtime)
                  var computedStarttime=new Date(computedStarttime.setDate(computedStarttime.getDate() - parseInt(i.duration)+1))
                  i.starttime=computedStarttime

                }
              }
  
            } else {
              //有子任务，那子任务已经考虑了前置任务的影响
              i.childtimearray.forEach((ti)=>{
                starttimeArray.push(ti.childstarttime)
                endtimeArray.push(ti.childendtime)
              })
              let childstarttime=(starttimeArray.length>0)?new Date(Math.min.apply(null, starttimeArray)):''
              let childendtime=(endtimeArray.length>0)?new Date(Math.max.apply(null, endtimeArray)):''
              i.starttime=childstarttime
              i.endtime=childendtime
              var lastime=(childendtime.getTime()-childstarttime.getTime())/(24*3600*1000)+1

              i.duration=lastime
            }
  
            timeokTaskArray.push(i)

          }
        })
        if(timeokTaskArray.length<tasks.length){
          this.updatejiaozi(tasks,timeokTaskArray)
        } else {
          return
        }
        
      }else {
        return
      }

    },


    //不考虑前置任务影响，只考虑父子任务，计算任务的开始和结束时间
    calculStartAndEndTimeBy(task){
      if(task.childtimearray.length<1){
        let starttime=task.starttime==''?'':new Date(task.starttime)
        starttime=starttime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        let endtime=task.endtime==''?'':new Date(task.endtime)
        endtime=endtime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        return {starttime:starttime,endtime:endtime}
      } else {
        let starttimeArray=[]
        let endtimeArray=[]
        task.childtimearray.forEach((item)=>{
          let starttime=new Date(item.childstarttime)
          starttimeArray.push(starttime)
          let endtime=new Date(item.childendtime)
          endtimeArray.push(endtime)
        })
        let minstarttime=new Date(Math.min.apply(null, starttimeArray))
        minstarttime=minstarttime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        let maxendtime=new Date(Math.max.apply(null, endtimeArray))
        maxendtime=maxendtime.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' })
        return {starttime:minstarttime,endtime:maxendtime}
      }
    },
    //到此为止


   //还需要验证ID是否等于本身？是否大于所有任务的ID？是否是子任务？


    async updateTasktimeByPretaskIDStr(e,task){     //task.pretaskIDstrArray是以ID为序列
      const pretaskIDStr=e.target.value
      let pretaskIDStrs=task.pretaskIDstrArray.filter(item=>!(item.task_idft==task.idft))
      let pretaskIDStrObj={task_idft:task.idft,pretaskIDStr:pretaskIDStr}
      if(pretaskIDStr==''){
        task.pretask=''
        this.updateTaskOfSql(task)
        // this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)
        this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)
        
        //暂时不要试试
        // task.pretaskIDstrArray=pretaskIDStrs
      }else {
        var pattern = /^(\d+)$|^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)$/
        var pattern1 = /^(\d+)|^(\d+)([FSfs]{2}|[FSfs]{2}[+-]\d+)$/
        let dependentTaskIds = pretaskIDStr.split(";");   //这一步是正确的
        if (dependentTaskIds.some(item => !pattern.test(item))) {
          // e.target.value = this.computedPretaskIDStrofMethod(task);
          e.target.value = this.computedPretaskIDStrofMethod(task);
          alert("前置任务信息不符合要求！");
          //如果输入的前置任务信息不符合要求则不改变任务的任务前置任务信息
        } else {
          //验证输入的前置任务ID在所有任务信息中是否存在，这里还要验证，不能是自己的子任务
          var ID
          if (dependentTaskIds.every((IDStr) => {
            var matchResult = IDStr.match(pattern1);
            ID = matchResult[1];
            return ID <= this.tasksData.length;
          })) {
            // dependentTaskIds中的所有元素都满足条件
            //等全部符合要求了，再更新本任务的所有前置任务信息
            //把前端显示的前置任务信息转化成数据库可储存的pretask
            task.pretask=this.computedPretaskidStrofMethod(task,pretaskIDStr)
            this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)
            this.updateTaskOfSql(task)



          } else {
            const str = `ID为${ID}的任务不存在！`;
            e.target.value = this.computedPretaskIDStrofMethod(task);
            alert(str);
          }
        }
      }
      // this.updateChildPreTaskArray(task,pretaskIDStrObj)
    },

    async getTaskFilesByIdft(task_idft) {
      try {
        const token = localStorage.getItem('token');
        const data = { task_idft: task_idft };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/gettaskfilesbyidft',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
  
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    async getTaskSummitMsgs(task) {
      try {
        const token = localStorage.getItem('token');
        const data = { task_idft: task.idft };
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/gettasksummitmsgsbyidft',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
  
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          // alert('账号未认证，无法获取数据qia！');
          // window.location.href = "https://www.itaskid.com" + "/login";
          return []
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },



    showTaskDetailMenu(e,task){
      this.showTaskDetail=true
      this.selectedTask=task

      this.getTaskFilesByIdft(task.idft)
        .then((result)=>{
          this.selectedTaskFiles=result
        })

      this.getTaskSummitMsgs(task)
        .then((result)=>{
          result.sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
          });
          this.taskProcessMsgs = result;
        })



      this.switchMenuMask()
      eventBus.$on('closeContexMenu', () => {
        this.showTaskDetail=false
      })

    },

    async processTaskMsgs(result) {
      for (const msg of result) {
        if (msg.type === 'img') {
          const imgUrl = await this.showPic1(msg.file_name, 'showmsgimg');
          msg.imgUrl = imgUrl;
        }
      }
      return result;  // Optionally return the modified result
    },

    async getProjectPartersFromTask(task) {
      try {
        
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        const selectedProjectAcls = await this.getProjectAclListByIdfp(task.project);
        
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar);
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
        });
        await Promise.all(promises);
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },


    async getSelectedTaskAcls() {
      let selectedTaskAcls = [];
    
      // 构建任务关系数组
      this.selectedProjectAcls.forEach((item) => {
        const taskAcl = {
          username: item.username,
          nickname: item.nickname,
          permit_level: item.permit_level,
          project_idfp: item.project_idfp,
          avatarUrl: item.avatarUrl,
          task_idft: this.selectedTask.idft
        };
        selectedTaskAcls.push(taskAcl);
      });

    
      try {
        // 获取指定任务ID的任务关系
        const results = await this.getTaskAclsByTaskIdft(this.selectedTask.idft);
    

    
        // 根据结果更新任务关系数组
        results.forEach((result) => {
          // 查找是否存在相同的 username
          const existingAclIndex = selectedTaskAcls.findIndex((acl) => acl.username === result.username);
          if (existingAclIndex !== -1) {
            // 如果存在相同的 username，则用 result 替换该元素
            result.avatarUrl = selectedTaskAcls[existingAclIndex].avatarUrl;
            selectedTaskAcls[existingAclIndex] = result;
          }
        });
    
        this.selectedTaskAcls = selectedTaskAcls;

      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },

    handleMousedownTask(e,task){
      e.stopPropagation()
      this.selectedTask=task

      // task.idft=task.task_idft
      this.getSelectedTaskAcls()
      this.getProjectPartersFromTask(task)


      this.getMsgsByReceiver(task.idft)
        .then((result)=>{
          this.taskContents=result
          
        })
      this.getTaskMsgsByReceiver(task.idft)
        .then(async (result)=>{
          const processedResult = await this.processTaskMsgs(result)
          this.taskContents=processedResult
        })

      this.getResPlanlistByTask(task)
        .then((result)=>{
          this.selectedTaskResPlans=result
        })
    },

    upDateStarttime(value){
      const starttime=this.convertDate(value)
      this.selectedTask.starttime=starttime
      this.updateTaskOfSql(this.selectedTask)
    },

    upDateEndtime(value){
      const endtime=this.convertDate(value)
      this.selectedTask.endtime=endtime
      this.updateTaskOfSql(this.selectedTask)
    },

    toggleTaskDone(e,task){
      if(e.target.checked) {
        this.doneTask(task)
      } else {
        task.done=0
        task.qtyotsked=0
        this.updateTaskOfSql(task)
      }
    },

    doneTask(task){
      task.done=1
      task.qtyotsked=task.qtyotsk
      this.updateTaskOfSql(task)
      if(task.level<6){
        this.tasksData.forEach((item)=>{
          if(item.parent===task.idft){
            this.doneTask(item)
          }else {
            return
          }
        })
      }
    },



    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      var ks=result.getTime()
      return ks;
    },

    convertChineseTimeToDate(dateTime){
      const date = new Date(dateTime);
      const year = date.getFullYear(); // 获取年份
      const month = date.getMonth() + 1; // 获取月份（需要加1，因为月份是从 0 开始计数的）
      const day = date.getDate(); // 获取日期
      
      // 将年、月、日拼接为指定格式的日期字符串
      const formattedDate = `${year}/${month}/${day}`;
      return formattedDate
    },

    convertUtcDateToChinaDate(dateTime){
      const utcstartDate = new Date(dateTime);
      if (isNaN(utcstartDate.getTime())) {  
          return '2999/12/31'; // 返回空字符串，表示无效日期  
      } 
      const chstartDate = new Date(utcstartDate.getTime() + 8 * 60 * 60 * 1000).toISOString().split('T')[0].replace(/-/g, '/')
      return chstartDate
    },

    //以上是有关前置任务相关的函数



    //以下是拖动中间边界框的函数

    startResize(e) {
      this.isResizing = true;
      this.startX = e.clientX;
      document.addEventListener('mousemove', this.resize);
      document.addEventListener('mouseup', this.stopResize);
    },
    resize(e) {
      if (this.isResizing) {
        const offset = e.clientX - this.startX;
        this.leftWidth += offset;
        this.startX = e.clientX;
      }
    },
    stopResize() {
      this.isResizing = false;
      document.removeEventListener('mousemove', this.resize);
      document.removeEventListener('mouseup', this.stopResize);
    },
    //以上是拖动中间边界框的函数


    updateLasttime(item){ 
      if (item.childtimearray.length>0){
        if(item.duration==this.lasttimeBefore){

        }else {
          alert('请修改最低一级子任务工期！')
          item.duration=this.lasttimeBefore
        }
      } else {
        this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)
        this.updateTaskOfSql(item)
      }

    },



    recordEndtimeBefore(e){
      this.endtimeBefore=e.target.value
    },

    recordStarttimeBefore(e){
      this.starttimeBefore=e.target.value
    },

    recordLasttimeBefore(e){
      this.lasttimeBefore=e.target.value
    },

    //更新任务task父任务的开始时间和完成时间，以及childtimearray
    updateParentTaskTime(task){
      const parentTask=this.tasksData.find(item=>item.idft==task.parent)
      if(parentTask){
        parentTask.childtimearray.forEach((child)=>{
          if(child.task_idft==task.idft){
            child.childstarttime=task.starttime
            child.childendtime=task.endtime
          }
        })
        let starttimeArray=[]
        let endtimeArray=[]
        parentTask.childtimearray.forEach((item)=>{
          let starttime=new Date(item.childstarttime)
          starttimeArray.push(starttime)
          let endtime=new Date(item.childendtime)
          endtimeArray.push(endtime)
        })
        let minstarttime=new Date(Math.min.apply(null, starttimeArray))
        let maxendtime=new Date(Math.max.apply(null, endtimeArray))
        parentTask.starttime=minstarttime
        parentTask.endtime=maxendtime
        this.updateParentTaskTime(parentTask)
      }else {
        return
      }
    },

    updateStarttime(e,task){
      if(task.childtimearray.length<1){
        //需要把e.target.value和前置任务做对比
        // task.starttime=new Date(e.target.value)
        // var newStarttime= new Date(e.target.value)
        // var newEndtime=newStarttime.setDate(newStarttime.getDate()+parseInt(task.duration)-1)
        // newEndtime=new Date(newEndtime)
        // task.endtime=newEndtime
        // this.updateParentTaskTime(task)
        var inputValue=e.target.value==''?new Date():e.target.value
        task.starttime=new Date(inputValue)
        this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)
        this.updateTaskOfSql(task)
      
      } else {
        if(task.starttime==e.target.value){
        } else {
          alert('请调整最低一级子任务开始时间！')
          e.target.value=this.starttimeBefore
        }

      }
    },

    updateEndtime(e,task){
      if(task.childtimearray.length<1){
        var inputValue=e.target.value==''?new Date():e.target.value
        task.endtime=new Date(inputValue)
        var computedStarttime=new Date(inputValue)
        computedStarttime=new Date(computedStarttime.setDate(computedStarttime.getDate() - parseInt(task.duration)+1))
        task.starttime=computedStarttime
        this.tasksData=this.initialAutoComputedTasksTime(this.tasksData)
        this.updateTaskOfSql(task)

      } else {
        if(task.endtime==e.target.value){
        } else {
          alert('请调整最低一级子任务完成时间！')
          e.target.value=this.endtimeBefore
        }
      }
    },

    addLocalTaskFileData(file){
      this.selectedTaskFiles.push(file)
    },

    addLocalTaskSummitMsg(msg){
      this.taskProcessMsgs.push(msg)
    },

    sendTaskMessage(newTaskMessage){
      newFileMessage.sockettype='objchat'
      this.socket.emit('message', newTaskMessage, (response) => {

        })
    },

    updateChildtimeArray(){

    },
        //以下是编辑项目的处理函数
    cancelEditPro(){

      this.showEditTimeRuler=false
      this.switchMenuMask()
    },
    closeEditProj(startDay,endDay,interval){
      

      this.monthArray=this.getMonthDaysArrayWithInterval(startDay,endDay,interval)
      this.dayArray=this.getDaysArrayWithInterval(startDay,endDay,interval)
      this.showEditTimeRuler=false
      this.switchMenuMask()
    },

    timeRulerEditMenu(event){
      this.timeRulerEditMenuitems = [
        { ID:1,label: '时间刻度' }, 
        { ID:2,label: '开始时间' },
        { ID:3,label: '结束时间' },
      ]
      this.showTimeRulerEditMenu=true
      this.switchMenuMask()
      this.event={...event}  
      
      const top=(window.innerHeight-event.clientY)<189?(event.clientY-189):event.clientY
      const left=(window.innerWidth-event.clientX)<76?(event.clientX-76):event.clientX
      this.parentNode ={clientY:top+10,clientX:left+10}
      eventBus.$on('closeContexMenu', () => {
        this.showTimeRulerEditMenu=false
      })
      
    },

  
    timeRulereditMenuitemClicked(item) {

      //在这里处理右键菜单对应的选项
      this.showTimeRulerEditMenu=false
      //以下是项目编辑右键菜单的处理分支
      switch (item.ID){
        case 1: {
          // this.showProjEdit=true
          this.showEditTimeRuler=true
          break
        }
        case 2: {
          this.switchMenuMask()
          break
        }
        case 3: {
          this.switchMenuMask()
          break
        }
      }
    },


    inputDate(item) {

    },

    inputTask(data,item) {

    },



    getMonthDaysArrayWithInterval(startDate, endDate, interval) {
      let result = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);
    
      while (currentDate <= end) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
        let days = 0;
    
        if (currentDate.getMonth() === end.getMonth() && currentDate.getFullYear() === end.getFullYear()) {
          const diffTime = end.getTime() - currentDate.getTime();
          days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        } else {
          // days = daysInMonth - currentDate.getDate()+1;
          days = daysInMonth - currentDate.getDate()
        }
    
        // result.push({ month: `${year}年${month}月`, days: Math.ceil(days / interval) });
        result.push({ month: `${year}年${month}月`, days: Math.floor(days / interval)+1 });
    
        const remainder=days % interval
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(interval-remainder);
      }
    
      return result;
    },

    getMonthDaysArray(startDate, endDate) {
      let result = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);
    
      while (currentDate <= end) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
        let days = 0;
    
        if (currentDate.getMonth() === end.getMonth() && currentDate.getFullYear() === end.getFullYear()) {
          days = end.getDate() - currentDate.getDate() + 1;
        } else {
          days = daysInMonth - currentDate.getDate() + 1;
        }
    
        result.push({ month: `${year}年${month}月`, days: days });
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1);
      }
    
      return result;
    },



    getDaysArray(startDate, endDate) {
      let result = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);

    
      while (currentDate <= end) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
    
        for (let day = 1; day <= daysInMonth; day++) {
          const date = new Date(year, month - 1, day);
          if (date <= end && date >= currentDate) {
            result.push({ date: `${year}/${month}/${day}`, day: day });
          }
        }
    
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1); // 设置为下个月的第一天，以便在下一次循环中正确判断月份
      }
    
      return result;
    },


    getDaysArrayWithInterval(startDate, endDate, interval) {
      let result = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);
      while (currentDate <= end) {
        const date = new Date(currentDate);
        const day = date.getDate();
        const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${day}`;
        const resourceArray=this.computedallTaskResourcesOfCertainDay(this.tasksData,currentDate)
        result.push({ date: formattedDate, day: day ,resourcearray:resourceArray});
        currentDate.setDate(currentDate.getDate() + interval);
      }
      return result;
    },


    //

    //以下是echarts相关的函数
    drawChart() {
      //2. 基于准备好的dom，初始化echarts实例
      
      var myChart = this.$echarts.init(document.getElementById('main'));

      myChart.on('click', function (params) {
          // 控制台打印数据的名称

      });

      //3. 指定图表的配置项和数据
      let dateArray=[]
      let sumArray=[]
      this.dayArray.forEach(item=>{
        dateArray.push(item.date)
        const daySum=this.computedAllResorceNum1(item)
        sumArray.push(daySum.workernum)
      })
      var option = {
        title: {
            text: '劳动力需求时间曲线图',
            left: 'center',
        },
        xAxis: {
          type: 'category',
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: dateArray,

        },
        yAxis: {
          type: 'value',

        },
        series: [
          {
            // data: [820, 932, 901, 934, 1290, 1330, 1320],
            // data: [1.5, 2.4, 2.8, 3.2, 5, 6.5, 9,5,12,12,55,20,15,20],
            data: sumArray,
            type: 'line',
            smooth: true
          }
        ]
      };
      //4.使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },


    //以下是获取初始化数据相关的函数
    async getTasksByProjectAcl() {
      const username=localStorage.getItem('username')
      var tasklistByProjectAcl=[]
      this.getMyProjectAclList(username)
        .then((result)=>{
          
          this.myProjectAclList=result
          const n=result.length
          var i
          var promises = []
          for(i=0;i<n;i++){
            var promise=this.getTasksByIdfp(result[i].project_idfp)
            promises.push(promise)
          }
          return Promise.all(promises)
        })
        .then((results) => {
          for(let i=0;i<results.length;i++) {
            results[i].forEach((task)=>{
              task.permit_level=this.myProjectAclList[i].permit_level
            })
            tasklistByProjectAcl=tasklistByProjectAcl.concat(results[i])
          }
          tasklistByProjectAcl.sort((a, b) => a.orderid - b.orderid)
          this.tasklistall=tasklistByProjectAcl
     
          return tasklistByProjectAcl
        })
    },








  },

  watch:{
    searchTaskName:{
      handler: function(newVal, oldVal) {
        if(newVal==''){
          this.filteredTasks=this.allMyLoadedProjectTasks
        }else if(newVal!='') {

          this.filteredTasks=this.allMyLoadedProjectTasks.filter((item)=>{
            if(item.name.includes(newVal)){
              return item
            }
          })



        }
      },
      deep: true // 使用deep选项深度监视数组

    },

    tasksData:  {
      handler: function(newVal, oldVal) {
        this.allTaskResources=this.computedallTaskResources(newVal)
        const today = new Date();
        const twoYearsLater = new Date(today);
        twoYearsLater.setFullYear(today.getFullYear() + 2);
        this.monthArray=this.getMonthDaysArrayWithInterval(today,twoYearsLater,2)
        this.dayArray=this.getDaysArrayWithInterval(today,twoYearsLater,2)
      },
      deep: true // 使用deep选项深度监视数组
    },

    resResourceTtile: function(newVal, oldVal){
      if(newVal=='资源需求计划表'){
        this.resViewOption=1
      } else {
        this.resViewOption=2
        this.drawChart()
      }
    },

    // selectedOption: function(newVal, oldVal){
    //   if(this.selectedOption=='今天') {
    //       const starttime=item.starttime==''?0:item.starttime
    //       const endtime=item.endtime==''?0:item.endtime
    //       const starttimeTimestamp=new Date((starttime)).getTime()
    //       const endtimeTimestamp=new Date(endtime).getTime()
    //       const today=new Date().getTime()
    //       var ifShow=(starttimeTimestamp<=today&&(today<=endtimeTimestamp))?true:false
    //       if((item.qtyotsk>=item.qtyotsk)&&this.ifNotFinished) {
    //         ifShow=false
    //       }
    //       return ifShow
    //     } else if(this.selectedOption=='本周') {

    //       // 获取今天的日期对象
    //       const today = new Date();
          
    //       // 获取今天是本周的第几天（0 表示星期日，1 表示星期一，依此类推）
    //       const dayOfWeek = today.getDay();
          
    //       // 获取本周的第一天的日期对象
    //       const firstDay = new Date(today);
    //       firstDay.setDate(today.getDate() - dayOfWeek);
          
    //       // 获取本周的最后一天的日期对象
    //       const lastDay = new Date(today);
    //       lastDay.setDate(today.getDate() + (6 - dayOfWeek));
          
    //       // 将日期对象转换为时间戳（单位为毫秒）
    //       const firstDayTimestamp = firstDay.getTime();
    //       const lastDayTimestamp = lastDay.getTime();

    //       const starttime=item.starttime==''?0:item.starttime
    //       const endtime=item.endtime==''?0:item.endtime
    //       const starttimeTimestamp=new Date((starttime)).getTime()
    //       const endtimeTimestamp=new Date(endtime).getTime()

    //       var ifShow=(starttimeTimestamp>lastDayTimestamp||(endtimeTimestamp<firstDayTimestamp))?false:true

    //       if((item.qtyotsk>=item.qtyotsk)&&this.ifNotFinished) {
    //         ifShow=false
    //       }

    //       return ifShow

    //     } else if(this.selectedOption=='本月'){
    //       // 获取今天的日期对象
    //       const today = new Date();
          
    //       // 获取本月的第一天的日期对象
    //       const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
          
    //       // 获取下个月的第一天的日期对象，然后减去一天，即可得到本月的最后一天的日期对象
    //       const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    //       lastDay.setDate(lastDay.getDate() - 1);
          
    //       // 将日期对象转换为时间戳（单位为毫秒）
    //       const firstDayTimestamp = firstDay.getTime();
    //       const lastDayTimestamp = lastDay.getTime();
    //       const starttime=item.starttime==''?0:item.starttime
    //       const endtime=item.endtime==''?0:item.endtime
    //       const starttimeTimestamp=new Date((starttime)).getTime()
    //       const endtimeTimestamp=new Date(endtime).getTime()
    //       var ifShow=(starttimeTimestamp>lastDayTimestamp||(endtimeTimestamp<firstDayTimestamp))?false:true

    //       if((item.qtyotsk>=item.qtyotsk)&&this.ifNotFinished) {
    //         ifShow=false
    //       }
    //       return ifShow
    //     } else {
    //       if(((item.qtyotsk>=item.qtyotsk)&&this.ifNotFinished)) {
    //         return false
    //       } else {
    //         return true
    //       }
          
    //     }
      
    // },
  },



  mounted(){
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const twoYearsLater = new Date();
    twoYearsLater.setHours(0, 0, 0, 0);
    twoYearsLater.setFullYear(today.getFullYear() + 2);
    this.monthArray=this.getMonthDaysArrayWithInterval(today,twoYearsLater,2)
    this.dayArray=this.getDaysArrayWithInterval(today,twoYearsLater,2)




    // this.tasksData=this.initialAutoComputedTasksTime(this.newTasks)
    this.tasksData=[]

    this.getTasksByProjectAcl()

    const username=localStorage.getItem('username')

    this.getMyTaskAclList(username)
      .then((result)=>{
        this.myTaskAcllist=result
        //获取缓存在本地的项目关系，通过项目关系得到这个项目所有的任务
        var myLocalProjectStr = localStorage.getItem('myLocalProject');
        // 将字符串转换为对象
        var myLocalProject = JSON.parse(myLocalProjectStr);

        if (myLocalProject && myLocalProject.project_idfp) {
          this.loadProject(myLocalProject);
        } else {
            console.error("Failed to load project: Project data is missing or invalid");
            // 可以在这里添加错误处理逻辑，例如显示错误消息或执行其他回退操作
        }


        // this.loadProject(myLocalProject)
      })
    
    




    this.allTaskResources=this.computedallTaskResources(this.tasksData)


    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
      
    });

    // 监听服务器发送的消息
    this.socket.on('message', (message) => {
      if(message.receiver==this.selectedTask.idft){
        this.taskContents.push(message)
      }
    }); 

    
    // const arr=[{resname:'钢筋工',num:1},{resname:'瓦工',num:1},{resname:'无纺布',num:100},{resname:'钢筋工',num:1},{resname:'瓦工',num:10}]

  },

  beforeDestroy() {

    // 将对象转换为 JSON 字符串并存储到 localStorage 中
    localStorage.setItem('ifShowTableCol', JSON.stringify(this.ifShowTableCol));
    

  },

  created(){

    // 从 localStorage 中检索存储的对象
    const ifShowTableCol = JSON.parse(localStorage.getItem('ifShowTableCol'));

    
    this.ifShowFinishRate=ifShowTableCol.ifShowFinishRate
    this.ifShowFinishQuanty=ifShowTableCol.ifShowFinishQuanty
    this.ifShowResorce=ifShowTableCol.ifShowResorce
    this.ifShowPretask=ifShowTableCol.ifShowPretask
    this.ifShowSequence=ifShowTableCol.ifShowSequence
    this.leftWidth=ifShowTableCol.leftWidth
    




   
    this.adjustAllTaskTimeOfLevelN(this.tasksData,6)

    


  },


}
</script>

<style  lang="less" scoped>
  div.text {
    font-size: 12px;
    border-collapse: collapse;
    width:auto;
    border:0;
    box-sizing: border-box;
    div {
      // border:0.5px solid gray;
      border-collapse: collapse;
      box-sizing: border-box;
    }
    table {
      border-collapse: collapse;
      border:0;
      border-spacing: 0px;
      padding:0;
      box-sizing: border-box;
      text-align: center;
    }

    tbody {
      // border-collapse: collapse;
      border:0;
      border-spacing: 0px;
      padding:0;
      box-sizing: border-box;
    }

    tr {
      // border-collapse: collapse;
      border:0;
      border-spacing: 0px;
      padding:0;
      box-sizing: border-box;
    }
    
    td {
      // border-collapse: collapse;
      border:gray solid 1px;
      border-spacing: 0px;
      padding:0;
      box-sizing: border-box;
      font-size: 12px;
      text-align: center;
      border-bottom:none;
      height:25px;


      input {
        outline:none;
        padding:0;
        border-collapse: collapse;
        border:0px;
        line-height: 23px;
        font-size: 12px;
        text-align: center;
        margin:0px;
      }

      button {
        background: none;
        border:none;
        padding:0;
        margin:0;
        display: inline;
        height:17px;
        border-collapse: collapse;
      }

      i {
        font-size: 10px;
        padding:0;
        margin:0;
        border-collapse: collapse;
      }

      h4 {
        margin: 10px 0 10px 10px;
      }

    }
  }
  .custom-table {
    display: inline;
    border-collapse: collapse;
    border-spacing: 0px;
    .table-header-colname {
      text-align: center;
      height:20px;
      th {
        text-align: center;
        font-size: 10px;
        padding: 0 2px;
        width: auto;
        font-weight: normal;
      }
    }

    .table-tr{
      border-collapse: collapse;
      text-align: center;
      input.task-done {
        margin:0;
        height: 60%;

      }
      td {
        width:auto;
        text-align: center;
        max-width:200px;
        font-size: 10px;
        height: 20px;

        input {
          padding:0;
          border:0;
          height: 100%;
          width: auto;
          border-radius: 0px;
          outline:none;
          text-align: center;
        }
        
      }

      td:focus {
        border:none;
        outline: none;
      }

      .td-timeruler {
        width: 300px;
        overflow: auto;
        
        
      }
    }
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #000;
    text-align: left;
  }

  .column-width-limited {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .blueBackground {
    // background-color: blue;
    // background: linear-gradient(to bottom, rgb(255, 255, 255) 20%,blue 60%, rgb(255, 255, 255) 20%);
    background: linear-gradient(to bottom, rgb(255, 255, 255, 0.8) 35%, blue 65%, rgb(255, 255, 255,0.8) 35%);
  }

  .ResorceBackground {
    background: rgb(198, 202, 231);
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    background: none;
  }


  .resize-divider {
    width: 3px;
    background: rgb(227, 227, 227);
    cursor: ew-resize;
  }

  .resize-right {
  width: cal(50% - 10px);
  flex: 1;
  overflow: auto;
}

.resize-left {
  width: 50%;
  border-right: 1px solid gray;
  overflow: auto;
}

.projecttitle-container {
  width:300px;
  text-align: center;
  height:25px;
  font-size: 14px;
  border:none;
  outline:none;
  font-weight: bold;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
  margin-bottom:5px;
  padding-left:10px;
}


.savebutton {
  margin-right: 5px;
  width: 70px;
  height: 25px;
  padding:0;
  border-radius: 5px;
  margin-top:10px;
  border:none;
  background-color: rgb(235, 235, 235);
}


.resourceviewbutton {
  margin-right: 5px;
  width: 30px;
  height: 25px;
  padding:0;
  border-radius: 50%;
  margin-top:10px;
  border:none;
  background-color: rgb(235, 235, 235);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* x偏移量, y偏移量, 模糊半径, 阴影颜色 */
}

.projectbutton {
  margin-right: 5px;
  width: 50px;
  padding:0;
  border-radius: 5px;
  border:none;
  background:none;

}

.projectbutton:hover {
  color:rgb(145, 170, 252);
}

.el-icon-more{
  float:right;
  
}

.el-icon-more:hover{
  color:rgb(145, 170, 252);
}

.tasknamecontainer {
  overflow: hidden;
  text-overflow: ellipsis;
}


.tasknamelabel label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left:0px;
  text-align: left;
  width: 180px;
  white-space: nowrap;
}

// .menu-container {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 99;
// }

.filterDayTask {
  width: 100px;
}

.filterDayTask:focus {
  outline: none;
}






</style>